@charset "utf-8";
@import '../../scss/common/mixin';

// start
.refund-policy-item-wrap {
  margin-top: 88px;
}

.refund-policy-item-expand {
  margin-top: 10px;
  background: #eeeeee;
  border-radius: 4px;
  padding: 11px 10px;
  font-size: 14px;
  position: relative;
}

.refund-policy-item-expand-actions {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.refund-policy-item-expand-actions > span {
  font-size: 13px;
}

@include new-mobile {
  .refund-policy-item-expand-actions > span {
    display: none;
  }
}

// for override
.terms-policy-container.product-detail {
  & {
    margin-top: 6px;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .terms-list {
    margin-top: 0;
    padding: 0;
  }

  & .terms-list .terms-con {
    margin-top: 0;
  }

  & .terms-list .terms-con .terms-subtitle {
    border-left: 0;
    padding-left: 0;

    font-size: 16px;
  }

  & .terms-list .terms-con .terms-text,
  & .terms-list .terms-con .terms-con-list > li {
    font-size: 11px;
    line-height: 14px;
  }

  & .terms-list .terms-con .terms-text-wrap {
    border-left: 0;
    padding-left: 0;
  }
}
