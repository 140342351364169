@import '../common/mixin';
@import '../common/variables';

.time-event-banner {
  max-width: 1128px;
  padding: {
    top: 32px;
    bottom: 40px;
  };
  text-align: center;
  border-radius: 20px;
  background-color: #FFE24B;
  cursor: pointer;

  @include new-mobile {
    padding-bottom: 34px;
  }

  .title {
    margin-bottom: 8px;
    @include font(24px, 700) {
      color: $black-color;
      line-height: 28px;
      letter-spacing: -1px;
    }
    white-space: break-spaces;

    @include new-mobile {
      margin-bottom: 12px;
      font-size: 20px;
    }
  }

  .sub-title {
    margin-bottom: 40px;
    @include font(16px, 700) {
      color: $dark-gray-1-color;
      line-height: 22px;
      letter-spacing: -1px;
    }

    @include new-mobile {
      font-size: 14px;
    }
  }

  .count-box-wrap {
    @include flex(center, center);

    .count-box {
      position: relative;
      @include size(107px, 100px);
      background-color: #FF9D0B;
      border-radius: 10px;

      @include new-mobile {
        @include size(53px, 50px);
        border-radius: 6px;
      }

      @include mediaQuery(360px) {
        @include size(48px, 45px);
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        @include font(64px, 700) {
          color: $white-color;
          line-height: 76px;
        }

        @include new-mobile {
          font-size: 22px;
          line-height: 26px;
        }

        @include mediaQuery(360px) {
          font-size: 18px;
        }
      }
    }

    .date-text {
      margin-left: 8px;

      @include font(32px, 700) {
        color: $dark-gray-2-color;
      }

      @include new-mobile {
        margin-left: 2px;
        font-size: 16px;
      }

      @include mediaQuery(360px) {
        font-size: 14px;
      }
    }

    .line {
      @include size(107px, 2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $light-gray-2-color;

      @include new-mobile {
        width: 53px;
      }

      @include mediaQuery(360px) {
        width: 48px;
      }
    }
  }

  .display-time-box {
    position: relative;
    width: 714px;
    margin: 0 auto;
    @include flex(space-between, center);

    @include new-mobile {
      width: 324px;
    }

    @include mediaQuery(360px) {
      width: 292px;
    }
  }

  .time-bear-lottie {
    position: absolute;
    bottom: 82px;
    @include size(107px, 106px);
    z-index: 9;

    @include new-mobile {
      bottom: 40px;
      @include size(53px, 53px);
    }

    @include mediaQuery(360px) {
      bottom: 35px;
      width: 48px;
    }
  }
}

.curation-content {
  .time-event-banner {
    margin-bottom: 64px;
  }
}