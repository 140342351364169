@import '../common/mixin';

.profile {
  &.mo-style {
    @include flex(flex-start, center);

    .profile-image-wrap {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    .img-edit-btn {
      height: 20px;
      @include font(10px, 400) {
        line-height: 20px;
        color: #ffffff;
      }
    }

    .profile-text {
      position: relative;
      flex-grow: 1;

      h6 {
        @include font(12px, 400) {
          line-height: 12px;
          color: #888888;
        }
      }

      p {
        @include font(18px, 700) {
          line-height: 18px;
        }
        margin-top: 6px;

        a::after {
          content: url('#{$img-root-url}/static/svg/common/right-arrow.svg');
          margin-left: 6px;
          vertical-align: 1px;
        }
      }

      > a {
        display: block;
        position: absolute;
        width: 75px;
        height: 20px;
        right: 0;
        bottom: 0;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        @include font(12px, 400) {
          line-height: 18px;
          color: #888888;
        }
        letter-spacing: -1px;
        text-align: center;
      }
    }
  }

  &.pc-style {
    @include flex(flex-start, flex-end);

    .profile-image-wrap {
      width: 72px;
      height: 72px;
      margin-right: 12px;
    }

    .img-edit-btn {
      height: 32px;
      @include font(12px, 400) {
        line-height: 32px;
        color: #ffffff;
      }
    }

    .profile-text {
      h6 {
        @include font(14px, 400) {
          line-height: 14px;
          color: #ffffff;
        }
        opacity: 0.7;
      }

      p {
        @include font(28px, 300) {
          line-height: 41px;
          color: #ffffff;
        }
      }

      span {
        display: block;
        font-weight: 700;
      }

      img {
        width: 24px;
        height: 24px;
        vertical-align: -2px;
        cursor: pointer;
        margin-left: 3px;
      }
    }
  }

  .profile-image-wrap {
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    .profile-img {
      width: 100%;
      height: 100%;
    }

    .img-edit-btn {
      position: absolute;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0;
      bottom: 0;
      z-index: 2;
      text-align: center;
      cursor: pointer;
    }
  }
}
