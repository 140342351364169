@import '../common/mixin';

.account-info-edit-wrap {
  @include wrapper(540px);

  .profile-image-edit-wrap {
    @include flex(space-between, center);
    margin-bottom: 50px;

    div {
      position: relative;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }

      .profile-img {
        @include size(50px, 50px);
        border-radius: 50%;
        filter: brightness(0.6);
        object-fit: cover;
      }

      .profile-edit-icon {
        @include size(25px, 25px);
        display: block;
        position: absolute;
        top: 12px;
        left: 13px;
      }
    }
  }

  .email-address {
    margin: {
      top: 18px;
      bottom: 50px;
    }
    @include font(14px, 400) {
      color: $black-color;
      line-height: 16px;
    }
  }

  .password-change-wrap {
    margin-bottom: 50px;
    @include flex(space-between, center);

    div {
      @include flex(flex-end, center);
      cursor: pointer;

      span {
        margin-right: 6px;
        @include font(14px, 400) {
          color: $black-color;
          line-height: 16px;
        }
      }
    }
  }

  .nickname-wrap {
    @include flex(space-between, flex-start);

    .input-wrap {
      width: 80%;
    }

    .confirm-btn {
      width: 100%;
      max-width: 93px;
      margin-left: 12px;
      padding: 12px 0;
      @include font(14px, 400) {
        color: $black-color;
        line-height: 16px;
      }
      border: 1px solid #cdcdcd;
      border-radius: 2px;
    }
  }

  .select-box-wrap {
    margin-bottom: 50px;
  }

  .address-wrap {
    .input-wrap {
      height: 48px;
      margin-bottom: 0;

      input {
        &:focus {
          border-color: #d8d8d8 !important;
        }
      }
    }

    .address-top-wrap {
      margin-top: 6px;
      @include flex(space-between, baseline);

      .input-wrap {
        width: 100%;
      }
    }

    .address-search-btn {
      width: 100%;
      max-width: 94px;
      margin-left: 12px;
      padding: 12px 0;
      @include font(14px, 400) {
        line-height: 16px;
        color: $black-color;
      }
      border: 1px solid #cdcdcd;
      border-radius: 2px;
    }

    .address-info {
      margin-top: 10px;
      @include flex(flex-start, center);

      .info-icon {
        @include size(16px, 16px);
      }

      p {
        margin-left: 5px;
        @include font(12px, 400) {
          color: #6b6b6b;
          line-height: 14px;
        }
      }

      .default-msg {
        margin-top: 0;
        margin-left: 10px;
        color: #ff2828;
      }
    }
  }

  .edit-btn-group {
    margin-top: 100px;
    @include flex(space-between, flex-end);

    @include new-mobile {
      display: block;
    }

    .btn-wrap {
      @include flex(flex-start, center);
      max-width: 328px;
    }

    .btn {
      width: 48%;
      padding: 12px 0;
      @include font(14px, 700) {
        color: $black-color;
        line-height: 16px;
      }
      text-align: center;
      border-radius: 2px;
    }

    .drop-out-btn {
      @include font(13px, 400) {
        color: #999999;
        line-height: 16px;
      }
      border-bottom: 1px solid #999999;

      @include new-mobile {
        display: inline-block;
        font-size: 14px;
      }
    }

    .btn-wrap {
      width: 100%;
      margin-top: 60px;
    }

    .cancel-btn {
      margin-right: 10px;
      border: 1px solid #cdcdcd;
      background-color: #ffffff;
    }

    .save-info-btn {
      color: #ffffff;
      background-color: $main-color-v2;
    }
  }
}
