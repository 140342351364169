/* media query */
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1128px;

/* media query v2 */
$breakpoint-new-desktop: 1920px;
$breakpoint-new-tablet: 1128px;
$breakpoint-new-mobile: 767px;

/* s3 url */
$img-root-url: 'https://s3.ap-northeast-2.amazonaws.com/dev-img.bear-u.com';

/* bear-u colors */
$main-color: #6236ff;
$main-color-v2: #4745FF;
$default-color: #333333;
$dark-theme-color: #1e1e1e;
$input-placeholder-color: #cccccc;
$point-color: #ff0067;
$coming-soon-color: #00c4c4;
$super-z-color: #005bff;
$hard-study-color: #FF2D69;

/* color variables */
$white-color: #ffffff;
$black-color: #242424;
$dark-gray-1-color: #595959;
$dark-gray-2-color: #727272;
$dark-gray-3-color: #999999;
$light-gray-1-color: #f4f4f4;
$light-gray-2-color: #e8e8e8;
$light-gray-3-color: #d8d8d8;