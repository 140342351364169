@charset "utf-8";
@import '../../scss/common/mixin';

.bgd-container {
  .content-container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 70px;

    @include new-mobile {
      padding-top: 30px;
      gap: 20px;
    }

    .recruit-page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .recruit-content-wrap {
        max-width: 700px;
        width: 100%;
      }

      .content-top-img {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .bgd-tab-nav {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .item {
      display: flex;
      width: 50%;
      text-align: center;
      font-size: 20px;
      transition: all 1000ms ease-in-out;

      &.active {
        background-color: #0a57ea;
        transition: all 1000ms ease-in-out;
        color: #fff;
      }

      a {
        width: 100%;
        padding: 24px 16px;
      }
    }
  }

  .bgd-main-title {
    h3 {
      font-size: 20px;
      .term {
        display: flex;
        flex-direction: column;
      }
      .description {
      }
    }
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;
    background-color: white;
  }

  input[type='checkbox']:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 9px;
    border: solid blue;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -60%) rotate(45deg);
    display: none;
  }

  input[type='checkbox']:checked {
    border-color: #0a57ea !important;
    &:before {
      display: block;
    }
  }

  input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 3px solid #f1f1ef;
    border-radius: 999px;
    position: relative;
    cursor: pointer;
    outline: none;
  }

  input[type='radio']:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 999px;
    background-color: #f1f1ef;
    transform: translate(-50%, -50%);
    display: none;
  }

  input[type='radio']:checked {
    border: 3px solid #0a57ea !important;
    &:before {
      display: block;
      background-color: #0a57ea;
    }
  }

  label {
    cursor: pointer;
  }

  .error-massage {
    font-size: 14px;
    color: #ff2828;
    margin-top: 14px;
  }

  .w-full {
    width: 100%;
  }

  .hidden {
    display: none;
  }

  .recruit-info-text {
    padding-top: 40px;
    line-height: 1.8;

    h4 {
      font-size: 24px;
      font-weight: bold;
    }

    .text-purple {
      color: #ad72a9;
      font-weight: bold;
    }
  }

  .recruit-content {
    .apply-btn {
      width: 100%;
      height: 100%;
      padding: 20px 0;
      background-color: #f1167c;
      color: white;
      border-radius: 20px;
      text-align: center;
      font-weight: semi-bold;
      font-size: 24px;
    }
  }

  .recruit-question-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    flex-grow: 2;
    grid-gap: 12px;
    gap: 32px;
    background-color: #f4f4f4;
    margin-bottom: 40px;
    border-radius: 8px;
    margin-top: 40px;

    input[type='text'],
    textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    & > .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 4px;
      > .input-wrap {
        width: 100%;
      }
      > div {
        margin-bottom: 8px;
      }

      .label-wrap {
        width: 100%;
        align-items: center;
        display: flex;
      }

      .row-reverse {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        > .label-wrap {
          margin-left: 8px;
          & > label {
            width: 100%;
            padding: 8px 0px;
          }
        }
        > .input-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > .label-wrap {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .bgd-container {
    .recruit-question-list {
      & > .item {
        width: 100%;
      }
    }
  }
}
