@charset "utf-8";
@import '../common/mixin';

.terms-policy-container {
  padding: 80px 16px 100px 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 64px;
  }

  .terms-wrap {
    width: 100%;
    max-width: 1024px;
  }
  .terms-list {
    width: 100%;
    margin: 64px auto;
    text-align: left;

    .terms-con {
      margin-top: 60px;

      .terms-subtitle {
        padding-left: 27px;
        font-size: 1.25rem;
        font-weight: 500;
        border-left: 3px solid $main-color;
      }

      .terms-text,
      .terms-con-list > li {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 300;
        color: #585858;
      }

      .terms-text-wrap {
        padding-top: 14px;
        padding-left: 27px;
        line-height: 1.5rem;
        font-size: 1rem;
        font-weight: 300;
        border-left: 1px solid #e1e1e1;

        .lv2 {
          padding-left: 14px;
        }

        .lv3 {
          padding-left: 28px;
        }

        .lv4 {
          padding-left: 42px;
        }

        table {
          width: 100%;
          margin: 14px 0;
          text-align: center;

          thead {
            background: #f1f1f1;

            th:first-child {
              width: 10%;
            }

            th {
              border: 1px solid #2b2b2b;
              font-weight: 400;
              padding: 8px;
              vertical-align: middle;
            }
          }

          tbody {
            td {
              word-break: keep-all;
              border: 1px solid #2b2b2b;
              padding: 8px;
              vertical-align: middle;
            }
          }

          &.privacy {
            th:first-child {
              width: 8%;
            }

            th {
              width: 30%;
            }
          }
        }
      }
    }
  }
  .react-select {
    max-width: 230px;
  }
}

@include tablet {
  .terms-policy-container {
    padding-top: 0;

    .terms-list {
      margin-top: 40px;

      .terms-con {
        margin-top: 40px;

        .terms-subtitle {
          font-size: 1rem;
          padding-left: 16px;
        }

        .terms-text-wrap {
          line-height: 1.25rem;
          font-size: 0.75rem;
          font-weight: 300;
          padding-left: 16px;
        }
      }
    }
  }
  .react-select {
    max-width: 100%;
  }
}
