@import '../common/mixin';

.pop-up-banner-wrap {
  @include wrapper(1128px);
  position: relative;
  filter: drop-shadow(-2px 4px 20px rgba(0, 0, 0, 0.32));
  cursor: pointer;
  // 채널톡 z-index +1
  z-index: 10000001;

  @include new-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include flex(center, center) {
      flex-direction: column;
    }
    overflow: hidden;
    background-color: #0000006b;
    filter: none;
  }

  .pop-up-banner {
    position: absolute;
    top: 144px;
    width: 368px;
    overflow: hidden;
    border-radius: 10px;

    @include new-mobile {
      width: 240px;
      position: static;
    }

    .thumbnail {
      height: 368px;
      background-color: pink;

      @include new-mobile {
        height: 240px;
      }
    }

    .button-wrap {
      padding-block: 8px;
      @include flex(center, cetner);
      @include font(16px, 700) {
        color: $dark-gray-3-color;
      }
      background-color: $white-color;

      @include new-mobile {
        font-size: 14px;
      }

      p {
        @include flex(center, center);
        @include size(184px, 56px);

        @include new-mobile {
          height: 44px;
        }

        &:first-child {
          border-right: 2px solid $light-gray-2-color;
        }
      }
    }
  }
}
