@import '../common/mixin';

.invoice-wrap {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;

  .loading-spinner-wrap {
    padding: 0;
    position: absolute;
    top: 3px;
    left: 2px;

    .loading-icon {
      width: 1px;
      height: 1px;
      border-width: 3px;
    }

    .anim-wrap {
      width: 100%;
    }
  }

  h4 {
    @include font(14px, 700) {
      line-height: 14px;
      color: #888888;
    }
    word-break: break-all;
  }

  .invoice-title-wrap {
    padding: 20px;
    border-bottom: 1px solid #eaeaea;
    border-radius: 4px 4px 0 0;
    position: relative;
  }

  p {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    text-align: left;
    word-break: keep-all;
    word-wrap: break-word;
  }
}

.invoice-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;

  img {
    width: 28px;
    height: 28px;

    &.enp_mobon_wish {
      filter: invert(50%) grayscale(50%);
    }
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #242424;
  }
}

.option-select-wrap {
  padding: 16px 16px 0px;

  .img-group {
    margin-top: 12px;
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 6px;

    img {
      width: auto;
      height: 32px;
      margin-right: 6px;
      margin-bottom: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .kit-option-select-wrap {
    margin-top: 16px;

    h4 {
      margin-bottom: 6px;
    }
  }
}

.purchase-list-wrap {
  padding: 16px;

  .selected-list {
    margin-top: 16px;

    li {
      @include flex(space-between, flex-start);
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      h5 {
        @include font(16px, 400) {
          line-height: 19px;
        }
        width: 70%;
        padding-right: 8px;
        word-break: break-all;
      }

      h6 {
        @include font(16px, 400) {
          line-height: 19px;
        }
        white-space: nowrap;
      }
    }

    span {
      @include font(14px, 400) {
        line-height: 16px;
        color: #aaaaaa;
        text-decoration: line-through;
      }
      margin-right: 4px;
    }
  }

  .kit-title {
    width: 77%;

    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
      margin-top: 4px;
      vertical-align: top;
    }

    h5 {
      width: calc(100% - 16px) !important;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .kit-subtitle {
    white-space: nowrap;
    text-align: right;
  }
}

.purchase-info {
  background-color: #f6f6f6;
  padding: 16px;

  li {
    @include flex(space-between, flex-start);
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    h5 {
      width: 65%;
      word-break: break-all;
      padding-right: 8px;
      @include font(14px, 700) {
        line-height: 19px;
      }
    }

    h6 {
      @include font(16px, 700) {
        line-height: 16px;
      }
      text-align: right;

      &.sale-price {
        color: #ff0067;
      }
    }
  }
}

.total-price {
  padding: 16px;

  .total-price-wrap {
    @include flex(space-between, flex-start);

    h6 {
      @include font(20px, 700) {
        line-height: 20px;
      }
      text-align: right;
    }
  }

  p {
    @include font(11px, 300) {
      line-height: 10px;
      color: #aaaaaa;
    }
    margin-top: 10px;
  }
}

.purchase-btn-wrap {
  padding: 0 16px 16px;

  button {
    font-size: 20px;
  }

  .pay-btn {
    margin-top: 12px;
    color: $black-color;
    border: 1px solid #d8d8d8;
    background-color: #ffffff;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border-color: #999999;
    }
  }
}

.coming-soon-btn-wrap {
  padding: 24px;
}

.coming-soon-btn {
  width: 100%;
  border-radius: 2px;
  background-color: #03c4c5;
  color: #ffffff;
  text-align: center;
  padding: 12px 0;
  @include font(16px, 700) {
    color: #ffffff;
    line-height: 24px;
  }
}

.is-mine-course-btn-wrap {
  padding: 24px;

  a {
    display: block;
  }
}

@include tablet {
  .fixed-content {
    .invoice-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 590px;
      max-height: 80%;
      padding-top: 16px;
      padding-bottom: 81px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    .invoice-content-wrap {
      height: calc(100% - 32px);
      overflow-y: auto;
      margin-top: 16px;
    }

    .purchase-btn-wrap {
      padding: 12px 16px;
      position: absolute;
      width: 100%;
      bottom: 16px;
      left: 0;
      border-top: 1px solid #eaeaea;
      background-color: #ffffff;
    }
  }
}
