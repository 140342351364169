@import '../common/mixin';

.mobile-course-card {
  margin-top: 12px;

  &.expired {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  a {
    @include flex(flex-start, center);
  }

  &.expiration {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;

    p {
      text-decoration: line-through;
    }
  }

  .course-img-wrap {
    flex-shrink: 0;
    width: 56px;
    height: 36px;
    overflow: hidden;
    border-radius: 2px;
    margin-right: 8px;

    div {
      width: 100%;
      height: 100%;
    }
  }

  p {
    @include font(14px, 400) {
      line-height: 1.29;
    }
    @include textEllipsis(36px, 2);
  }
}
