@import '../common/mixin';

.track-shipping-modal {
  .modal-header {
    background-color: #f6f6f6;
    height: 64px;
    padding: 0 24px;
    @include flex(flex-start, center);

    h3 {
      @include font(20px, 700) {
        line-height: 64px;
        color: #333333;
      }
      text-align: left;
      flex-shrink: 0;
    }

    p {
      @include font(14px, 700) {
        line-height: 64px;
        color: #888888;
      }
      margin-left: 8px;
      width: 76%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .modal-content {
    max-width: 392px;
    border-radius: 4px;
    overflow: hidden;
    max-height: inherit;
  }

  .shipping-progress {
    height: 50px;
    justify-content: center;
  }

  @include mobile {
    .modal-header {
      background-color: #ffffff;
      height: 48px;
      justify-content: center;

      h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 48px;
      }
    }
    .modal-content {
      width: 100%;
      max-width: inherit;
    }
    .confirm-btn-wrap {
      position: absolute;
      width: 100%;
      bottom: 61px;
      padding: 16px 24px;
    }
    .modal-section {
      border-top: none;
    }

    //.modal-section {
    //  position: absolute;
    //  top: 64px;
    //  bottom: 97px;
    //  overflow: scroll;
    //}
  }
}

.track-shipping-wrap {
  padding: 16px 24px;

  h6 {
    @include font(12px, 300) {
      line-height: 12px;
      color: #888888;
    }
  }

  h5 {
    @include font(14px, 500) {
      line-height: 14px;
      color: #888888;
    }
    margin-top: 28px;
  }

  p {
    @include font(14px, 400) {
      line-height: 14px;
    }
    margin-top: 12px;
  }
}

.shipping-info-list {
  background-color: #f9f9f9;
  padding: 16px 24px;
  max-height: 300px;
  overflow-y: auto;

  p {
    @include font(14px, 400) {
      line-height: 17px;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .shipping-info-item {
    margin-top: 7px;

    &:first-child {
      margin-top: 0;
    }
  }
}

@include mobile {
  .shipping-info-list {
    max-height: calc(100% - 370px);
  }
}

.modal-section {
  .mo-ship-title {
    @include font(16px, 700) {
      line-height: 64px;
      color: #333333;
    }
    height: 64px;
    background-color: #f6f6f6;
    padding: 0 16px;
  }
}

.shipping-info-item {
  @include flex(space-between, flex-start);

  h5 {
    flex-shrink: 0;
    width: 108px;
    @include font(14px, 400) {
      line-height: 1.43;
    }
  }

  p {
    width: 70%;
    @include font(14px, 700) {
      line-height: 1.43;
    }
    text-align: right;
    word-break: keep-all;
  }
}

.confirm-btn-wrap {
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
  padding: 24px;

  .confirm-btn {
    border-radius: 0;
  }
}
