@import '../common/mixin';

.my-course-item-wrap {
  @include flex(space-between, flex-start) {
    flex-wrap: wrap;
  }
}

.my-course-item {
  width: 49%;
  max-width: 496px;
  margin-top: 24px;
  padding: 22px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0px 5px 10px rgba(0, 0, 0, 0.05),
    0px -5px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow:
    0px 5px 10px rgba(0, 0, 0, 0.05),
    0px -5px 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow:
    0px 5px 10px rgba(0, 0, 0, 0.05),
    0px -5px 10px rgba(0, 0, 0, 0.05);
  box-shadow:
    0px 5px 10px rgba(0, 0, 0, 0.05),
    0px -5px 10px rgba(0, 0, 0, 0.05);
}

.button-group {
  margin-top: 16px;
  @include flex(space-between, flex-start);
}

.my-course-list-info {
  @include flex(space-between, flex-start);
}

.course-image-wrap {
  position: relative;
  width: 100%;
  max-width: 160px;
  overflow: hidden;
  margin-right: 16px;

  .certificate-badge {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 20px;
    background-color: #666666;
    @include font(11px, 300) {
      line-height: 20px;
      color: #ffffff;
    }
    text-align: center;
    border-top-left-radius: 12px;

    &.completed {
      background-color: $main-color;
    }
  }

  .course-image {
    padding: 31% 0;
    border-radius: 4px;
  }
}

.title-wrap {
  width: 100%;

  a {
    @include font(16px, 500) {
      line-height: 24px;
    }
    @include textEllipsis(48px, 2);
  }
}

.title-bottom {
  position: relative;
  margin-top: 13px;

  .certificate-down-btn {
    position: absolute;
    right: 0;
    top: -4px;
    padding: 3px 6px;
    border-radius: 12px;
    background-color: #f9f9f9;
    cursor: pointer;

    img {
      width: 10px;
      height: 12px;
      margin-right: 2px;
      vertical-align: -2px;
    }

    span {
      @include font(12px, 200) {
        line-height: 18px;
        color: #666666;
      }
    }
  }
}

.rental-expire-time {
  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    vertical-align: -2px;
  }

  span {
    @include font(16px, 200) {
      line-height: 16px;
      color: #aaaaaa;
    }
  }
}

.button-group {
  > * {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    text-align: center;
    @include font(14px, 700) {
      line-height: 38px;
      color: $main-color;
    }
  }

  .rental-extend-btn {
    max-width: 160px;
    margin-right: 16px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .course-note-down-btn {
    margin-right: 8px;
    pointer-events: none;
    cursor: default;
    opacity: 0.3;

    img {
      width: 13px;
      height: auto;
      margin-right: 4px;
      vertical-align: -3px;
    }

    &.active {
      pointer-events: inherit;
      cursor: pointer;
      opacity: 1;
    }
  }

  .course-view-btn {
    background-color: $main-color;
    color: #ffffff;
    pointer-events: none;
    cursor: default;
    opacity: 0.3;

    &.active {
      pointer-events: inherit;
      cursor: pointer;
      opacity: 1;
    }
  }

  .favorite-delete-btn,
  .purchase-date {
    max-width: 160px;
    margin-right: 16px;
    cursor: pointer;
    color: #888888;
    font-weight: 500;
  }

  .purchase-date {
    cursor: default;
  }

  .purchase-btn,
  .tracking-shipment-btn {
    background-color: $main-color;
    color: #ffffff;
  }

  .tracking-shipment-btn {
    cursor: pointer;
  }
}

.tracking-shipment-btn {
  &.mo-shipping {
    position: absolute;
    right: 0px;
    top: 3px;
    @include font(10px, 700) {
      line-height: 10px;
      color: $main-color;
    }

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      margin: 0 auto 4px;
      @include background(
        '#{$img-root-url}/static/svg/mypage/combined-shape.svg',
        center,
        center,
        contain
      );
    }
  }
}

.mo-mypage-container {
  .my-shipping-item-wrap {
    background-color: #f6f6f6;
    padding-bottom: 8px;
  }
}

.mo-shipping {
  padding-bottom: 30px !important;

  .shipping-progress {
    width: 100%;
    padding: 0 16px;
    justify-content: center;

    div {
      &.step-line {
        max-width: 400px;
      }
    }
  }
}
