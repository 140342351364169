@import '../common/mixin';

.base-card {
  max-width: 267px;
  min-width: 154px;
  height: 100%;
  position: relative;
  cursor: pointer;

  .thumbnail-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 62%;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: transform 0.3s ease;

    .thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:hover {
      transform: translateY(-4px);
    }

    .bookmark-wrap {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;

      .bookmark-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_bookmark_new_0.svg',
          center,
          center,
          cover
        );
      }

      &.active {
        .bookmark-icon {
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_bookmark_new_1.svg',
            center,
            center,
            cover
          );
        }
      }
    }
  }

  .text-content {
    margin-top: 12px;
    word-wrap: break-word;

    @include new-mobile {
      top: 94px;
      margin-top: 8px;
    }
  }

  .title {
    @include font(16px, 400) {
      color: $black-color;
      line-height: 130%;
    }
    display: block;
    word-break: keep-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;

    @include new-mobile {
      margin-top: 0;
      font-size: 14px;
      line-height: 130%;
    }
  }

  .card-master-desc {
    display: block;
    align-items: center;
    font-size: 12px;
    line-height: 12px;
    margin-top: 8px;
    color: #888;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include new-mobile {
      margin-top: 8px;
      font-size: 12px;
      line-height: 100%;
    }
  }

  .tag-set {
    @include font(12px, 400) {
      color: #727272;
      line-height: 17px;
    }
  }

  &.loading {
    width: 100%;
    cursor: default;

    @keyframes placeholderAnim {
      0% {
        background-position: -234px 0;
      }
      100% {
        background-position: 234px 0;
      }
    }

    box-shadow: 0 0 0;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderAnim;
    animation-timing-function: linear;
    background: rgb(238, 238, 238);
    background: linear-gradient(
      90deg,
      rgba(246, 246, 246, 1) 0%,
      rgba(237, 237, 237, 1) 50%,
      rgba(246, 246, 246, 1) 100%
    );
  }
}

.base-badge-wrap {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #ff2b65;
  padding: 4px 6px;
  border-radius: 4px;

  @include new-tablet {
    top: 8px;
    left: 8px;
  }

  p {
    @include font(11px, 700) {
      color: #ffffff;
    }
    @include new-mobile {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
