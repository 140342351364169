@import '../common/mixin';

// 데스크탑 뷰
.category-nav {
  margin-top: 12px;
  width: 100%;

  .category-item {
    display: inline-block;
    position: relative;
    max-width: 142px;
    margin-right: 32px;
    font-size: 15px;
    color: #242424;

    a {
      cursor: pointer;
      display: flex;
      flex-direction: column;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      font-weight: bold;
    }

    &.selected {
      font-weight: bold;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background-color: #4745ff;
      }
    }
  }

  span {
    @include font(12px, bold) {
      line-height: 12px;
      font-family: 'GmarketSansBold';
    }
    vertical-align: 2px;
    margin-top: 4px;

    b {
      color: $main-color;
    }
  }

  a {
    @include flex(center, center);
    width: 100%;
    height: 100%;
  }

  p {
    margin: 0 4px;
  }

  .subCategory-wrap {
    display: flex;
    position: absolute;
    width: max-content;
    margin-right: 20px;
    top: 190%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #242424;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;

    .sub-category-item {
      margin-right: 20px;
      height: 40px;
      font-size: 14px;
      color: #242424;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #4745ff;
      }

      &.selected {
        color: #4745ff;
      }
    }
  }
}

// 모바일 뷰
.m-category-nav {
  display: flex;
  justify-content: center;

  @include mediaQuery(440px) {
    justify-content: flex-start;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .m-category-item {
    @include font(15px, 400) {
      line-height: 18px;
    }
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      font-weight: bold;
    }

    &.selected {
      font-weight: bold;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: 0;
        background-color: #4745ff;
      }
    }
  }

  a {
    @include flex(center, center);
    width: 100%;
    height: 30px;
  }

  p {
    margin: 0 4px;
  }
}

.m-subCategory-wrap {
  display: flex;
  justify-content: center;
  margin: 16px auto;

  @include mediaQuery(490px) {
    justify-content: flex-start;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .m-sub-category-item {
    margin-right: 10px;
    height: 36px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 19px;
    @include font(13px, 400) {
      color: #242424;
    }
    cursor: pointer;

    p {
      margin: 11px 10px 9px 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #4745ff;
      border: 1px solid #4745ff;
    }

    &.selected {
      color: #4745ff;
      border: 1px solid #4745ff;
    }
  }
}
