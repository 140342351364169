@import '../common/mixin', '../common/mixin_animation';

.contact-btn-float {
  position: fixed;
  right: 48px;
  bottom: 48px;
  width: 52px;
  height: 52px;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;

  @include new-mobile {
    right: 16px;
    bottom: calc(85px + env(safe-area-inset-bottom));
    bottom: calc(85px + constant(safe-area-inset-bottom));
  }

  .inquiry-btn {
    -webkit-animation: contact-anim 0.35s 10s ease-in-out forwards;
    -ms-animation: contact-anim 0.35s 10s ease-in-out forwards;
    -o-animation: contact-anim 0.35s 10s ease-in-out forwards;
    -moz-animation: contact-anim 0.35s 10s ease-in-out forwards;
    animation: contact-anim 0.35s 10s ease-in-out forwards;
    display: block;
    width: 150px;
    position: absolute;
    top: 1px;
    right: 20px;
    border: none;
    border-radius: 24px 0 0 24px;
    background: #ffffff;
    color: #6236ff;
    font-weight: 600;
    height: 50px;
    padding: 0 38px 0 10px;
    line-height: 50px;
    -webkit-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    overflow: hidden;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }

  .contact-icon {
    position: absolute;
    right: 0;
    width: 100%;
    height: 52px;
    border-radius: 50%;
    background: #6236ff;
    background: -moz-linear-gradient(left, #6236ff 0%, #7948f9 100%);
    background: -webkit-linear-gradient(left, #6236ff 0%, #7948f9 100%);
    background: linear-gradient(to right, #6236ff 0%, #7948f9 100%);
  }

  img {
    width: 50%;
    margin-top: 13px;
  }
}

@-webkit-keyframes contact-anim {
  from {
    width: 150px;
  }
  to {
    width: 0;
    padding: 0;
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@-ms-keyframes contact-anim {
  from {
    width: 150px;
  }
  to {
    width: 0;
    padding: 0;
    opacity: 0;
    -ms-box-shadow: none;
    box-shadow: none;
  }
}

@-o-keyframes contact-anim {
  from {
    width: 150px;
  }
  to {
    width: 0;
    padding: 0;
    opacity: 0;
    -o-box-shadow: none;
    box-shadow: none;
  }
}

@-moz-keyframes contact-anim {
  from {
    width: 150px;
  }
  to {
    width: 0;
    padding: 0;
    opacity: 0;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@keyframes contact-anim {
  from {
    width: 150px;
  }
  to {
    width: 0;
    padding: 0;
    opacity: 0;
    box-shadow: none;
  }
}
