@import '../common/mixin';

/* header 안에 모달창 스타일 지정 */

.header-modal-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  top: -30px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
}

.modal-container {
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.modal-fixed-dimmed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
}

.modal-title {
  height: 48px;
  @include font(16px, 400) {
    line-height: 48px;
  }
  text-align: center;
}

.header-modal {
  width: 100%;
  max-width: 320px;
  background: #ffffff;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 64px;
  z-index: 9000;
  border: 1px solid #eaeaea;
  overscroll-behavior: contain;
}

.noti-modal {
  padding: 0 8px 14px;
  min-height: 496px;
  max-height: 496px;

  &.dark {
    background-color: #1e1e1e;
    border-color: #3a3a3a;
    color: #ffffff;

    .noti-label {
      border-color: #3a3a3a;
      background-color: #f9f9f9;
    }
  }

  li {
    width: 100%;
    height: 64px;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
    border-radius: 2px;

    &:first-child {
      margin-top: 0;
    }

    .noti-item {
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding: 8px;
      @include flex(flex-start, center);
    }

    &.is-read {
      opacity: 0.3;
    }
  }

  .noti-label {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    text-align: center;
    flex-shrink: 0;

    h4 {
      @include font(11px, normal) {
        line-height: 50px;
        color: $main-color;
      }

      &.event {
        color: $point-color;
      }

      &.coupon {
        color: #b620e0;
      }
    }

    &.d-day-count {
      background-color: $point-color;

      h4 {
        color: #ffffff;
      }
    }

    .blog,
    .etc {
      width: 19px;
      height: auto;
      margin-top: 15px;
    }

    .course-related {
      width: 24px;
      height: auto;
      margin-top: 11px;
    }
  }

  .noti-segment {
    margin-left: 8px;

    h4 {
      @include font(14px, 400) {
        line-height: 14px;
      }
      @include textEllipsis(14px, 1);
    }

    b {
      font-weight: 700;
      color: $point-color;
    }

    h6 {
      @include font(12px, 400) {
        color: #888888;
      }
      margin-top: 8px;
    }
  }
}

.my-profile-modal {
  width: 300px;
  max-height: 100vh;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &.dark {
    background-color: #1e1e1e;
    border-color: #3a3a3a;
    color: #ffffff;

    .profile-text > a,
    .log-out-btn {
      color: #ffffff !important;
      border-color: #aaaaaa !important;
    }

    .enrollment-btn {
      background-color: #0c041f !important;
      border-color: $main-color;
    }

    .mo-mypage-wrap,
    .my-courses-preview-wrap,
    .lately-view-course-wrap {
      border-color: #3a3a3a !important;
    }

    .sub-btn-wrap li {
      border-color: #3a3a3a !important;

      h6 {
        color: #ffffff;
      }

      span {
        color: $point-color;
      }
    }

    .sub-title {
      color: #ffffff;
    }
  }
}

@include new-mobile {
  .noti-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    max-width: initial;
    max-height: initial;
    min-height: initial;
  }
}
