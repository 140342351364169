@import '../common/mixin';

.course-certificate-modal {
  .modal-close-btn {
    position: initial;
    top: auto;
    right: auto;
    width: 64px;
    height: 40px;
    background-color: #666666;

    img {
      width: 11px;
      height: 11px;
    }
  }

  .loading-spinner-wrap {
    padding: 65.653% 0;
  }

  .modal-content {
    max-width: 476px;
    max-height: 674px;
    overflow: hidden;

    &.hover .btn-object-group {
      opacity: 1;
    }
  }
}

#certificate-canvas {
  width: 100%;
  height: 100%;
}

.btn-object-group {
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0;
  z-index: 50;
  @include flex(flex-start, center);

  > div {
    border-radius: 4px;
    @include flex(center, center);

    span {
      @include font(14px, 400) {
        line-height: 40px;
        color: #ffffff;
      }
    }

    img {
      margin-right: 4px;
      margin-top: 2px;
    }
  }
}

.save-certificate-btn {
  width: 160px;
  height: 40px;
  background-color: $main-color;
  margin-left: 8px;
  cursor: pointer;

  span {
    font-weight: 700 !important;
  }

  img {
    width: 10px;
    height: 12px;
  }
}

#certificate-img {
  width: 100%;
  height: 100%;
}

@include mediaQuery(476px) {
  .course-certificate-modal {
    .modal-content {
      max-height: inherit;
      height: auto;
      top: 0;
      left: 0;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}
