@import '../common/mixin';

.drop-out-container {
  .drop-out-btn {
    margin-top: 50px;
  }

  .content {
    p {
      margin-bottom: 80px;
      @include font(14px, 300) {
        line-height: 20px;
        color: #666666;
      }

      @include new-mobile {
        margin-top: 0;
        margin-bottom: 60px;
      }

      img {
        width: 14px;
        height: auto;
        opacity: 0.5;
        margin-right: 4px;
        vertical-align: -1px;
      }
    }
  }

  .text-input {
    label {
      @include font(14px, 700) {
        line-height: 22px;
      }
      color: $black-color;
    }

    input {
      margin-top: 0;
      padding: 18px 0 12px;
      @include font(14px, 400) {
        color: #999999;
        line-height: 17px;
      }
      border: none;
      border-bottom: 1px solid #d8d8d8;
    }

    span {
      position: absolute;
      @include font(10px, 300) {
        line-height: 16px;
        color: #ff0067;
      }
      bottom: -16px;
      right: 0;
    }
  }
}
