@import '../common/mixin';

.select-box {
  .css-yk16xz-control {
    border-color: #d2d2d2 !important;
    border-radius: 0px !important;
    padding: 0 0 0 16px;
    min-height: 48px !important;
    cursor: pointer !important;

    .is-disabled {
      color: red;
    }
  }

  .css-1fhf3k1-control {
    border-color: #d2d2d2 !important;
    border-radius: 0px !important;
    padding: 0 0 0 16px;
    min-height: 48px !important;
    opacity: 0.5;
  }

  .css-1pahdxg-control {
    border-color: #d2d2d2 !important;
    border-radius: 0px !important;
    min-height: 48px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 0 0 0 16px;
  }

  .css-1uccc91-singleValue {
    @include font(14px, 300) {
      line-height: 20px;
      color: #333333;
    }
  }

  .css-1hwfws3,
  .css-g1d714-ValueContainer {
    padding: 0 !important;

    .css-1wa3eu0-placeholder {
      margin-right: 0;
      margin-left: 0;
      @include font(14px, 300) {
        line-height: 20px;
        color: #cccccc;
      }
    }
  }

  .css-1okebmr-indicatorSeparator,
  .css-109onse-indicatorSeparator {
    display: none;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    color: $input-placeholder-color !important;
  }

  .css-26l3qy-menu {
    margin-top: 0px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #d2d2d2;
    border-top: 0px;
    border-radius: 0px !important;

    .css-1jqbu51-MenuList,
    div {
      padding: 0;
      overscroll-behavior: contain;

      > div {
        padding: 16px;
        cursor: pointer !important;
        @include font(14px, 300) {
          line-height: 20px;
          color: #666666;
        }
      }

      .css-9gakcf-option {
        background-color: #f9f9f9 !important;
        font-weight: 500;
      }

      .css-1en3sjf-option {
        background-color: #ffffff !important;
      }
    }
  }

  .css-26l3qy-menu {
    div {
      &.css-1en3sjf-option:hover,
      &.css-1en3sjf-option,
      &.css-1ce6wmp-option {
        background-color: #f9f9f9 !important;
        font-weight: 500 !important;
        color: $main-color !important;
      }
    }
  }
}
