@import '../common/mixin';

.main-slider {
  .td-wrapper-v2 {
    @include flex(flex-end, flex-start);
    position: relative;
    height: 196px;

    @include new-mobile {
      height: 147px;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .segment {
    width: 48%;
    left: calc(10%);
    top: 52px;
    position: absolute;
    z-index: 3;

    h2 {
      @include font(24px, 700) {
        line-height: 1.38;
        color: #ffffff;
      }
      white-space: pre-line;
    }

    h3 {
      @include font(14px, 400) {
        line-height: 38px;
        color: #ffffff;
      }
    }

    @include new-mobile {
      width: calc(50%);
      top: 40px;
    }

    @include mediaQuery(680px) {
      width: calc(70%);
      left: calc(10%);
    }

    @include mediaQuery(500px) {
      width: calc(80%);
      left: calc(8%);
    }
  }

  .image {
    position: absolute;
    width: 31%;
    left: calc(57%);
    background-size: 100%;
    height: 196px;

    @include new-mobile {
      width: 40%;
      height: 147px;
      left: calc(57%);
    }

    @include mediaQuery(520px) {
      width: 50%;
      height: 147px;
      left: calc(45%);
    }
  }
}

@include new-mobile {
  .segment {
    width: calc(100% - 32px);
    position: absolute;
    top: 38%;
    left: 40px;
    text-align: left;
    z-index: 2;

    h2 {
      @include font(20px, 700) {
        line-height: 1.3;
      }
      white-space: inherit;
    }
  }
}

@include new-mobile {
  .swiper-pagination {
    display: block;
    position: absolute;
    bottom: 20px;
    z-index: 2;
    right: 24px;
    @include flex(flex-start, center);

    span {
      width: 8px;
      height: 8px;
      background-color: #ffffff;
      opacity: 0.3;
      margin-right: 16px;
      border-radius: 50%;
      cursor: pointer;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
