@charset "utf-8";
@import '../common/mixin';
@import '../common/_mixin_animation.scss';

body {
  &.overflow {
    width: 100%;
    position: fixed;
    overflow: hidden !important;
  }
}

.oopy-wrap {
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 136px;
  transition: all 0.2s linear;

  @include new-mobile {
    top: 120px;
  }

  &.hide {
    top: 64px;

    @include new-mobile {
      top: 48px;
    }
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;

    @include new-mobile {
      height: calc(100% - 62px);
    }
  }
}
