@import '../common/mixin';

.sub-banner-wrap {
  width: 100%;
  height: 80px;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}
