@charset "utf-8";
@import '../../scss/common/mixin';

.bgd-container {
  width: 100%;
  max-width: 1128px;
  padding-top: 64px;
  padding-bottom: 100px;
  margin: 0 auto;
  &.withOopy {
    padding: 0px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    .bgd-container {
      padding: 64px 0 100px 0;

      .content-top-img {
        height: 360px;
      }
    }
  }

  .top-nav {
    position: sticky;
    top: 0px;
    padding: 20px 0px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: center;
    align-items: center;
    z-index: 3;

    @include new-mobile {
      padding: 0;
      gap: 5px;
    }

    .top-item {
      width: 400px;
      padding: 20px 50px;
      background-color: white;
      color: black;
      border-radius: 30px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;

      @include new-mobile {
        font-size: 10px;
      }

      &:hover {
        background-color: black;
        color: white;
        & + .top-item.active {
          background-color: white;
          color: black;
        }
      }

      &.active {
        background-color: black;
        color: white;
      }
    }
  }

  .content-container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 70px;

    @include new-mobile {
      padding-top: 30px;
      gap: 20px;
    }

    .content-top-img {
      border-radius: 20px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content-area {
      display: flex;
      width: 100%;
      gap: 30px;

      & > * {
        display: flex;
        gap: 60px;
      }

      .desc-area {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 70.3%;

        > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .desc-text {
          display: flex;
          gap: 10px;
          font-size: 16px;
          margin-bottom: 8px;
          line-height: 1.5;

          .apply-btn {
            margin-top: 20px;
            font-size: 20px;
            text-decoration: 1px underline;
            text-underline-position: under;
            color: #6e6d69;
          }
        }

        .desc-info {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          gap: 15px;

          tr {
            td {
              padding-bottom: 24px;
            }
            &:first-child {
              td {
                &:first-child {
                  padding-bottom: 0px;
                }
                color: #dfab01;
                font-weight: bold;
              }
            }
            p,
            span {
              font-size: 16px;
              font-weight: normal;
            }
            span {
              display: flex;
              span {
                text-decoration: line-through;
                margin: 0px;
                line-height: 1;
              }
            }

            .purchase-merit {
              display: block;
              color: #e03d3e;
              padding-top: 8px;
            }
            p {
              padding: 4px 8px;
              font-weight: 500;
            }
            span,
            strong {
              padding: 0px 8px;
            }
          }
          span {
            margin-top: 5px;
            display: block;
          }
        }

        .desc-html {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          line-height: 1.6;
          gap: 20px;

          img {
            max-width: 100%;
            width: fit-content;
          }
        }
      }

      .btn-area {
        display: flex;
        min-width: 27%;
        height: 100%;

        .apply-btn {
          width: 100%;
          height: 100%;
          padding: 20px 0;
          background-color: #f1167c;
          color: white;
          border-radius: 20px;
          text-align: center;
          font-weight: semi-bold;
          font-size: 24px;
        }
      }
    }
  }
}

.bgd-tab-nav {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .item {
    display: flex;
    width: 50%;
    text-align: center;
    font-size: 20px;
    transition: all 1000ms ease-in-out;

    &.active {
      background-color: #0a57ea;
      transition: all 1000ms ease-in-out;
      color: #fff;
    }

    a {
      width: 100%;
      padding: 24px 16px;
    }
  }
}

.bgd-main-title {
  h3 {
    font-size: 20px;
    .term {
      display: flex;
      flex-direction: column;
    }
    .description {
    }
  }
}

label {
  cursor: pointer;
}

.error-massage {
  font-size: 14px;
  color: #ff2828;
  margin-top: 14px;
}

.w-full {
  width: 100%;
}

.hidden {
  display: none;
}

.recruit-content {
  .apply-btn {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    background-color: #f1167c;
    color: white;
    border-radius: 20px;
    text-align: center;
    font-weight: semi-bold;
    font-size: 24px;
  }
}

.gray-card {
  width: 100%;
  border-radius: 12px;
  padding: 20px 16px;
  background-color: #f1f1ef;
  border-right: 4px;
}

.gray-line-card {
  border: 1px solid #f1f1ef;
  padding: 16px;
  border-radius: 12px;
}

.text-gray-03 {
  color: #37352f;
}

.text-yellow-04 {
  color: #dfab01;
}

.text-red-300 {
  color: #e03d3e;
}

.underline {
  text-decoration: 1px underline;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .bgd-container {
    padding: 64px 20px 100px 20px;

    .content-container {
      .content-area {
        display: flex;
        flex-direction: column;
        .desc-area {
          max-width: 100%;
        }
        .btn-area {
          width: 100%;
        }
      }
    }

    .top-nav {
      .top-item {
        padding: 3vw 7vw;
      }
    }
  }
}

.not-active-page {
  .desc-area {
    margin-top: 80px;
    @include new-mobile {
      margin-top: 40px;
    }
  }
  line-height: 1.7;
  color: #363636;
  .info-item {
    margin-bottom: 60px;
    @include new-mobile {
      margin-bottom: 30px;
    }
  }

  h3 {
    font-size: 30px;
    font-weight: 600;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 12px;
  }

  .mt-5 {
    margin-top: 20px;
  }

  .text-deco-l-line {
    border-left: 3px solid #363636;
    padding-left: 16px;
  }

  .text-deco-line-through {
    text-decoration: line-through;
  }

  .text-deco-disc {
    position: relative;
    margin: 0px;
    padding: 0px;
    margin-left: 13px;
    &:before {
      display: flex;
      content: '\2022';
      clear: both;
      position: absolute;
      font-size: 30px;
      top: 43%;
      left: -17px;
      transform: translateY(-50%);
      line-height: 0;
    }
  }

  .review-list {
    .item {
      display: flex;
      padding: 16px;
      margin-bottom: 12px;
      border: 1px solid #ededec;
      border-radius: 12px;
      &:before {
        display: flex;
        content: '🏆';
        clear: both;
        padding-right: 20px;
        top: 2px;
        position: relative;
      }
      .title {
        font-size: 16px;
        color: #0d6e99;
        border-bottom: 1px solid #ededec;
        padding-bottom: 6px;
        margin-bottom: 8px;
      }
    }
  }
  .m-0 {
    margin: 0px;
  }

  .bgd-run-guide {
    .item {
      background-color: #f1f1ef;
      padding: 20px 20px 20px 50px;
      border-radius: 12px;
      margin-bottom: 12px;
      .title {
        position: relative;
        &:before {
          display: flex;
          content: '';
          clear: both;
          width: 16px;
          height: 16px;
          background-color: #55534f;
          border-radius: 16px;
          position: absolute;
          top: 50%;
          left: -34px;
          transform: translateY(-50%);
          line-height: 0;
        }
        &:after {
          display: flex;
          content: '';
          clear: both;
          position: absolute;
          top: 50%;
          left: -26px;
          width: 2px;
          height: 6px;
          border: 1px solid #fff;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -60%) rotate(45deg);
        }
      }
      p {
        margin-bottom: 0px;
      }
    }
  }

  ul {
    &.deco-decimal {
      li {
        list-style: decimal;
      }
    }
    &.deco-disc {
      li {
        list-style: disc;
        margin-left: 16px;
      }
    }
  }
}
