@import '../common/mixin';

.purchase-modal {
  .modal-content {
    max-width: 370px;
    border-radius: 8px;
    max-height: inherit;
    overflow: hidden;
  }

  .modal-close-btn {
    z-index: 1;
    top: 25px;
  }

  .invoice-title-wrap {
    p {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .invoice-title {
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .sale-select-box {
    .css-1wa3eu0-placeholder {
      font-weight: 500;
      font-size: 16px;
      color: #333333;
    }
  }

  .css-1uccc91-singleValue {
    font-weight: 500;
    font-size: 16px;
  }

  .option-select-wrap {
    > .select-box {
      .css-1wa3eu0-placeholder {
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #333333 !important;
      }
    }
  }

  .css-1uccc91-singleValue {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #333333 !important;
  }

  .modal-header h3 {
    padding: 0 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .invoice-content-wrap {
    padding-bottom: 100px;
  }

  @include tablet {
    .mo-invoice-title-wrap {
      h3 {
        padding-top: 16px;
      }
    }
    .purchase-modal-title {
      @include font(18px, 700) {
        line-height: 25px;
      }
      padding: 0 16px;
      margin-top: 24px;
    }
    .invoice-wrap {
      border: none;
    }
    .modal-close-btn {
      top: 20px;
    }
    .invoice-content-wrap {
      padding-bottom: 20px;
      position: absolute;
      overflow-y: auto;
      height: 100%;
      max-height: calc(100% - 190px);
      width: 100%;
      margin-top: 20px;
    }
    .option-select-wrap {
      padding: 0 16px;
    }
    .modal-content {
      height: inherit;
      max-height: 500px;
    }
    .purchase-btn-wrap {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 16px;
    }
  }

  @include mobile {
    .modal-content {
      max-height: 100%;
      width: 100%;
      max-width: inherit;
      border-radius: 0;
    }
    .invoice-wrap {
      border-radius: 0;
    }
  }
}
