@charset "utf-8";
@import 'common/reset';
@import 'common/header', 'common/footer', 'common/mixin', 'common/variables', 'common/bottom_nav', 'common/confirm', 'common/alert';

/* font connect */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css');

@include fontFace('GmarketSansLight', 'noonfonts_2001@1.1/GmarketSansLight.woff');
@include fontFace('GmarketSansMedium', 'noonfonts_2001@1.1/GmarketSansMedium.woff');
@include fontFace('GmarketSansBold', 'noonfonts_2001@1.1/GmarketSansBold.woff');

body {
  color: $default-color;
  position: relative;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  letter-spacing: 0;
  cursor: default;
}

button, input {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

.td-container {
  padding-top: 64px;
  padding-bottom: 130px;
}

.td-wrapper {
  @include wrapper(1280px);
}

.td-container-v2 {
  padding-top: 64px;

  &.overview-container {
    padding-top: 64px;
  }

  @include new-mobile {
    padding-top: 0;
  }

  &.overview-container {
    @include new-mobile {
      padding-top: 48px;
    }
  }
}

.td-wrapper-v2 {
  @include wrapper(1128px);
}

.common-center-title {
  margin-top: 50px;
  @include font(24px, 700) {
    color: $black-color;
    line-height: 28px;
  }
  text-align: center;

  &.type1 {
    margin-bottom: 30px;
  }

  &.type2 {
    margin-bottom: 100px;
  }

  &.type3 {
    margin-bottom: 60px;
  }
}

.content-title {
  @include font(24px, 700) {
    line-height: 24px;
  }

  &.type1 {
    @include font(20px, 700) {
    }
    margin-bottom: 20px;

    @include new-mobile {
      display: block;
      margin: 20px auto;
      max-width: 488px;
      white-space: nowrap;
      font-size: 16px;
    }
  }

  &.curation {
    max-width: 97%;
    @include font(20px, 700) {
      line-height: 25px;
    }
    @include textEllipsis(24px, 1);
    margin-bottom: 20px;

    @include new-mobile {
      max-width: 92%;
      white-space: nowrap;
      @include textEllipsis(22px, 1);
      font-size: 16px;
      line-height: 25px;
    }
  }

  &.loading {
    margin-bottom: 30px;

    @include new-mobile {
      display: block;
      margin: 0 auto 30px auto;
      max-width: 488px;
    }
  }
}

.td-layer {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
  z-index: 2;

  &.white {
    height: 20px;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.bottom {
    top: initial;
    bottom: 0;
    height: 36px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.td-btn {
  width: 100%;
  background-color: $main-color;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  padding: 12px 0;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  @include font(16px, 700) {
    color: #ffffff;
    line-height: 24px;
  }

  &:hover {
    background-color: #4c25d7;
  }

  &.inactive {
    background-color: #eaeaea;
  }

  &.active {
    background-color: #6236ff;
  }
}

.border-btn {
    width: 100%;
    border: 2px solid $main-color;
    border-radius: 2px;
    color: $main-color;
    text-align: center;
    padding: 12px 0;
    -webkit-transition: border-color 0.2s ease-in-out;
    -moz-transition: border-color 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
    @include font(16px, 700) {
        color: $main-color;
        line-height: 24px;
    }

    &:hover {
        color: #eaeaea;
        background-color: $main-color;
    }

    &.inactive {
        background-color: #eaeaea;
    }

    &.active {
        color: #eaeaea;
        background-color: $main-color;
    }
}

.pc-visible {
  display: block;
}

.mo-visible {
  display: none;
}

/* scroll style */
.td-scroll::-webkit-scrollbar {
  width: 10px;
}

.td-dark-scroll::-webkit-scrollbar {
  width: 10px;
}

.td-scroll::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.td-scroll::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.td-dark-scroll::-webkit-scrollbar-thumb {
  background-color: #444444 !important;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.td-scroll::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #ffffff;
}

.td-dark-scroll::-webkit-scrollbar-track {
  background-color: #1e1e1e !important;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #1e1e1e !important;
}

/* Firefox scrollbar */
.td-scroll {
  scrollbar-width: thin;
  scrollbar-color: #cccccc #ffffff;
}

.td-dark-scroll {
  scrollbar-width: thin;
  scrollbar-color: #444444 #1e1e1e !important;
}


/* 수강코드, 쿠폰 등록 버튼 스타일 */
.enrollment-btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &.pc-style {
    max-width: 286px;
    height: 62px;
    border-radius: 28px;
    margin-top: 28px;
    padding-top: 3px;
    -webkit-box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 12px 12px -8px rgba(0, 0, 0, 0.15);
    @include font(20px, 700) {
      line-height: 62px;
      color: $main-color;
    }

    img {
      vertical-align: -3px;
    }

    &:hover {
      background-color: #eaeaea;
    }
  }

  &.mo-style {
    height: 40px;
    border: 1px solid #6236ff;
    border-radius: 4px;
    margin-top: 16px;
    padding-top: 1px;
    @include font(16px, 700) {
      line-height: 40px;
      color: $main-color;
    }

    img {
      vertical-align: -5px;
    }
  }

  img {
    margin-right: 2px;
  }
}

/* media query */
/* 1280px */
@include desktop {
  .td-wrapper {
    padding: 0 16px;
  }
}

/* 767px */
@include new-mobile {
  .pc-visible {
    display: none !important;
  }

  .mo-visible {
    display: block;
  }

  .common-center-title {
    display: none;
  }

  .td-container {
    padding-top: 0;
  }

  .hdr {
    background-color: transparent;
  }
}

@include mobile {
  .content-title {
    font-size: 18px;

    a {
      &:after {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
        margin-left: 12px;
        vertical-align: 0;
        margin-left: 8px;
      }
    }
  }
}

.flex-all-center {
  @include flex(center,center);
}

.flex-col {
  flex-direction: column;
}

$unit: 0.25rem;
$sizes: ();
@for $i from 0 through 150 {
  $sizes: map-merge(
    (
      $i: ($i * $unit)
    ),
    $sizes
  );
}

//padding & margin
@each $prop, $abbrev in (padding: p, margin: m) {
  @each $size, $length in $sizes {
    @if "#{$size}" != "0" {

      @each $prop2, $abbrev2 in (top: t, bottom: b, left: l, right: r) {
        .#{$abbrev}#{$abbrev2}-#{$size} {
          #{$prop}-#{$prop2}: $length;
        }
      }

      @each $prop3 in (x,y) {
        .#{$abbrev}#{$prop3}-#{$size} {
          @if "#{$prop3}" == "x" {
            #{$prop}-left: $length;
            #{$prop}-right: $length;

          } else {
            #{$prop}-top: $length;
            #{$prop}-bottom: $length;
          }
        }
      }

    }
  }
}

//width & height
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    @if "#{$size}" != "0" {
      .#{$abbrev}-#{$size} {
        #{$prop}: $length;
      }
    }
  }
}

//min & max
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    @if "#{$size}" != "0" {
      @each $prop2 in (min, max) {
        .#{$prop2}-#{$abbrev}-#{$size} {
          #{$prop2}-#{$prop}: $length;
        }
      }
    }
  }
}
