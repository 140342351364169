@import '../common/mixin';

.book-review-slider {
  width: 1110px;
  max-width: 100%;
  margin: 0 auto;

  .swiper-container {
    overflow: visible;
  }

  .slider-button {
    position: absolute;
    top: 125px;
    z-index: 10;
    width: 48px;
    height: 48px;
    background-color: rgba(68, 68, 68, 0.8);
    text-align: center;

    &.swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .slider-button-prev {
    left: 80px;
  }

  .slider-button-next {
    right: 80px;
  }

  .book-review-wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .book-review-item {
    height: 173px;
    padding: 20px 20px 20px 160px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }

  .book-thumbnail {
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 120px;
    height: 173px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .book-review {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #242424;
    font-size: 16px;
    word-break: break-all;
  }

  .book-info {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    justify-content: space-between;

    .book-title {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #242424;
      font-weight: bold;
      font-size: 14px;
    }

    a {
      color: #999;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 767px) {
  .book-review-slider {
    padding: 0 15px;

    .slider-button-prev {
      left: 10px;
    }

    .slider-button-next {
      right: 10px;
    }
  }
}
