.address-change-modal {
  .modal-header {
    h3 {
      margin-top: 0;
    }
  }

  .modal-content {
    max-height: inherit;
  }

  .modal-section {
    min-height: inherit;
    max-height: inherit;
  }
}
