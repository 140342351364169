@import '../common/mixin';

.id-find-container {
  .content {
    .desc {
      margin-top: 20px;
      margin-bottom: 80px;
      @include font(14px, 300) {
        line-height: 20px;
        color: #666666;
      }

      @include new-mobile {
        margin-top: 0;
        margin-bottom: 60px;
      }

      img {
        width: 14px;
        height: auto;
        opacity: 0.5;
        margin-right: 4px;
        vertical-align: -1px;
      }

      &.result-id-find {
        margin-top: 100px;
        margin-bottom: 100px;
      }
    }
  }

  .td-btn {
    margin-top: 40px;
  }
}
