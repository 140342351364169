@charset "utf-8";
@import '../common/mixin';

/* main-nav-wrap */
.main-nav-wrap {
  @include flex(space-between, center);
  padding: 13px 16px 12px;
  width: 100%;
  height: 46px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1005;
  background-color: #fff;

  .main-left-wrap {
    @include flex(flex-start, center);
    width: 50%;
  }

  .main-event-btn {
    width: 24px;
    height: 24px;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_event_dg.svg',
      center,
      center,
      cover
    );
  }

  h1 {
    a {
      display: block;
      width: 76px;
      height: 17px;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/bearu_logo_black.svg',
        center,
        center,
        cover
      );
    }
  }

  .main-right-wrap {
    width: 50%;
    @include flex(flex-end, center);

    .main-noti-btn {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      cursor: pointer;
      position: relative;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_alarm_dg_m.svg',
        center,
        center,
        cover
      );

      .main-new-notice {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        top: 0;
        right: 0;
        background-color: #4745ff;
      }
    }

    .main-search-btn {
      width: 24px;
      height: 24px;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_search_dg_m.svg',
        center,
        center,
        cover
      );
    }
  }
}

/* main section */
.td-container-v2 {
  &.main {
    padding-top: 0;

    .td-wrapper-v2 {
      position: relative;
      height: inherit;
    }
  }
}

.main-video-wrap {
  position: relative;
  height: 720px;
  background-color: #242424;

  @include new-tablet {
    height: 480px;
  }

  @include new-mobile {
    height: 480px;
    text-align: center;
  }
}

.video-bg {
  @include size(100%, 100%);
  position: absolute;
  background: #0d114b;
  opacity: 0.65;
  z-index: 1;
}

.td-wrapper-v2 {
  .video-content {
    position: absolute;
    top: 30.5%;
    left: 8%;
    color: $white-color;
    z-index: 2;

    @include new-tablet {
      top: 30%;
      left: 8%;
    }

    @include new-mobile {
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    button {
      margin-top: 23.5%;
      @include font(14px, 700) {
        color: $white-color;
        line-height: 17px;

        @include new-mobile {
          font-weight: 400;
          margin-top: 60px;
        }
      }

      &:after {
        content: '';
        display: block;
        margin-top: 6px;
        @include size(143px, 2px);
        background-color: $white-color;

        @include new-mobile {
          height: 1px;
        }
      }
    }

    .pc-title {
      @include font(46px, 700) {
        line-height: 62px;
      }

      @include new-tablet {
        font-size: 33px;
        line-height: 46px;
      }

      @include new-mobile {
        font-size: 28px;
        line-height: 42px;
        white-space: pre;
      }
    }
  }
}

/* top-content-section */
.top-content-section {
  padding: 50px 84px;

  @include new-tablet {
    padding: 30px 60px;
  }

  @include new-mobile {
    padding: 30px 0 20px;
    text-align: center;
  }

  li {
    width: 100%;
    margin-top: 100px;

    @include flex(flex-end, center) {
      flex-direction: row-reverse;
    }

    @include new-tablet {
      margin-top: 30px;
      justify-content: center;
    }

    @include new-mobile {
      flex-direction: column-reverse;
      padding: 0;
    }

    .img-wrap {
      margin-left: 76px;
      @include size(100%, 400px) {
        max-width: 400px;
      }

      @include new-tablet {
        height: 340px;
        max-width: 340px;
        margin-left: 64px;
      }

      @include new-mobile {
        max-width: 320px;
        margin-left: 0;
      }
    }

    &:nth-child(1) {
      margin-top: 0;

      .img-wrap {
        @include background(
          '#{$img-root-url}/static/v2/png/main/graphic_1.png',
          center,
          center,
          cover
        );
      }
    }

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: row;

      @include new-tablet {
        justify-content: center;
      }

      @include new-mobile {
        flex-direction: column-reverse;
      }

      .img-wrap {
        margin-left: 0;
        margin-right: 116px;
        @include background(
          '#{$img-root-url}/static/v2/png/main/graphic_2.png',
          center,
          center,
          cover
        );

        @include new-tablet {
          margin-right: 68px;
        }
        @include new-mobile {
          margin-right: 0;
        }
      }
    }

    &:nth-child(3) {
      .img-wrap {
        @include background(
          '#{$img-root-url}/static/v2/png/main/graphic_3.png',
          center,
          center,
          cover
        );
      }
    }

    .text-desc {
      h4 {
        @include font(36px, 700) {
          color: $black-color;
          line-height: 48px;
        }
        word-break: keep-all;

        @include new-tablet {
          font-size: 26px;
          line-height: 34px;
        }
      }

      h5 {
        width: 100%;
        max-width: 440px;
        margin-top: 10px;
        @include font(20px, 400) {
          color: #595959;
          line-height: 31px;
        }
        word-break: keep-all;

        @include new-tablet {
          font-size: 18px;
          line-height: 26px;
        }

        @include new-mobile {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
}

/* review section */
.review-content-wrap {
  background-color: $main-color-v2;
}

/* popular class section */
.popular-class-wrap {
  background-color: #e6e9f4;
}
