@import '../common/mixin';

.complete-container {
  text-align: center;

  .complete-logo {
    width: 108px;
    height: auto;
    margin-top: 100px;
  }

  .title {
    @include font(24px, 700) {
      line-height: 32px;
    }
    margin-top: 12px;
  }

  .desc-container {
    margin-top: 36px;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 16px;
    text-align: center;

    .desc {
      word-break: keep-all;
      @include font(14px, 400) {
        line-height: 20px;
        color: #767676;
      }
    }
  }

  .btn-wrap {
    margin-top: 80px;
  }
}

@include tablet {
  .complete-container {
    .complete-logo {
      margin-top: 0;
      width: 78px;
    }
  }
}
