@charset "utf-8";
@import '../common/mixin';

.more-info-container {
  padding-top: 100px;

  .account-card {
    margin: 0px 16px;
    border-bottom: 1px solid #ededed;

    .account-wrap {
      display: flex;
      flex-direction: column;
    }
    .account-info {
      padding: 0px !important;
    }
  }

  @include new-mobile {
    padding-top: 66px;
  }

  .link-menu-list {
    padding: 0 16px 60px 16px;
    width: 100%;
    @include wrapper(1128px);
    @include font(17px, 700) {
      line-height: 18px;
      color: #242424;
    }

    .menu-li {
      display: flex;
      height: 62px;
      padding: 20px 0;
      border-bottom: 1px solid #ededed;

      a {
        align-self: center;
      }
    }

    .info-class {
      display: flex;
      padding: 20px 0px;
      border-bottom: 1px solid #ededed;

      .menu-title {
        display: flex;
        padding-right: 24px;
        line-height: 24px;

        p {
          width: max-content;
        }

        .direction {
          margin-left: 8px;
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_arrow_r_lg.svg',
            center,
            center,
            cover
          );
        }
      }

      .category-title {
        @include font(15px, 400) {
        }
        border: none;
        padding-bottom: 28px;
        margin: 0;

        &:first-child {
          padding-top: 4px;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .info-hardstudy {
      .hard-study {
        width: 40px;
        height: 22px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/빡공단black 1.svg',
          center,
          center,
          cover
        );
      }
    }

    .info-superz {
      height: 60px;
      padding: 20px 0;
      border-bottom: 1px solid #ededed;

      .menu-title {
        display: flex;
        padding-right: 24px;
        line-height: 24px;

        p {
          width: max-content;
        }
        .direction {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_running-crew_bl_active.svg',
            center,
            center,
            cover
          );
        }
      }
    }

    .info-event {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #ededed;

      .menu-title {
        display: flex;
        padding-right: 24px;
        line-height: 24px;

        p {
          width: max-content;
        }

        .direction {
          margin-left: 8px;
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_arrow_r_lg.svg',
            center,
            center,
            cover
          );
        }
      }

      .event-menu {
        width: 100%;

        .event-title {
          @include font(15px, 400) {
          }
          position: relative;
          padding-right: 30px;
          margin-bottom: 28px;
          word-break: break-all;
          @include textEllipsis(21px, 1);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .direction {
          position: absolute;
          right: 0;
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_arrow_r_lg.svg',
            center,
            center,
            cover
          );
        }
      }
    }

    .none-border {
      border-bottom: none;
    }
  }
}
