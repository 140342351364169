@import '../common/mixin';

/* 추후 QNA textarea input 창 ui 변경에 대응해야함 */
.comment-write-wrap {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 16px;
  margin-top: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  @include flex(flex-start, flex-start);

  > * {
    flex-shrink: 0;
  }

  &.focus {
    border: 1px solid $main-color;

    button {
      color: $main-color;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .user-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: #eaeaea;
  }
}

/* textarea */
.write-wrap {
  padding-left: 48px;
  position: relative;
  width: 100%;
  opacity: 0.5;
  pointer-events: none;
  cursor: default;

  &.active {
    opacity: 1;
    pointer-events: inherit;
    cursor: inherit;
  }

  textarea {
    width: 94%;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    overflow-y: hidden;
  }

  @include placeholder {
    color: #888888;
  }

  button {
    background: transparent;
    @include font(16px, 700) {
      line-height: 16px;
      color: #eaeaea;
    }
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.course-scope-wrap {
  text-align: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 12px;

  li {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
