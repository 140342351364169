@import '../common/mixin';

.input-field {
  .profile_image_edit {
    display: flex;
    align-items: center;

    label {
      padding-right: 20px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 300;
      color: #666666;
    }

    div {
      position: relative;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }

      .profile-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        filter: brightness(0.6);
        object-fit: cover;
      }

      .profile-edit-icon {
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        top: 12px;
        left: 13px;
      }
    }
  }

  h6 {
    @include font(12px, 300) {
      line-height: 16px;
      color: #666666;
    }
    margin-top: 20px;
  }

  &.detail-info {
    margin-top: 26px;
    padding-top: 20px;
    border-top: 1px solid #eaeaea;
  }

  .gender-btn-wrap {
    margin-top: 6px;
    @include flex(space-between, center);
    position: relative;
  }

  .gender-btn {
    width: 48%;
    height: 48px;
    border: 1px solid #d2d2d2;
    @include font(14px, 300) {
      line-height: 20px;
      color: #666666;
    }
    border-radius: 2px;

    &.active {
      background-color: $main-color;
      border-color: $main-color;
      color: #ffffff;
    }
  }

  .address-input-wrap {
    margin-top: 6px;
    position: relative;

    input {
      width: 100%;
      border-bottom: 1px solid #eaeaea;
      height: 48px;
      padding: {
        left: 16px;
        right: 92px;
      }
      @include font(14px, 300) {
        line-height: 20px;
      }
    }

    button {
      position: absolute;
      top: 9px;
      right: 16px;
      padding: 5px 7px;
      border: 1px solid #eaeaea;
      border-radius: 2px;
      @include font(12px, 300) {
        color: #666666;
      }
    }

    .detail-address {
      padding-right: 16px;
    }
  }

  .agree-wrap {
    margin-top: 40px;
  }

  .job-select-box-wrap {
    margin-top: 6px;
    position: relative;

    .select-box {
      .css-1uccc91-singleValue {
        font-weight: 300;
      }
    }
  }

  p {
    position: absolute;
    @include font(10px, 300) {
      line-height: 16px;
      color: #ff0067;
    }
    bottom: -16px;
    right: 0;
  }
}

.password-wrap {
  margin-top: 20px;

  .pwd-change {
    @include font(12px, 300) {
      line-height: 16px;
      color: #666666;
    }
    cursor: pointer;
    text-align: right;

    img {
      width: 12px;
      height: auto;
      vertical-align: -2px;
      margin-left: 2px;
    }
  }
}
