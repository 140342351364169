@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';
@import '../../../assets/scss/base.scss';

.product-dark {
  .count-clock-wrap {
    .time-wrap {
      background-color: #3a3a3a !important;
    }
  }

  .count-clock-info {
    p {
      font-weight: normal;
      margin: 0px;
    }
  }
}

.count-clock-info {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 20px;
  h5 {
    font-size: 24px;
  }
}

.count-clock-wrap {
  &.custom {
    .count-time-wrap {
      .time-wrap {
        margin: 0px 20px;
      }
    }
  }
  @include flex(center, center);
  width: 100%;
  bottom: 20px;
  color: $white-color;
  border-radius: 4px;

  @include animation(show-up-anim, 0.2s, 0s, linear, forwards);
  @include keyframe(show-up-anim) {
    0% {
      opacity: 0;
      bottom: 0;
    }
    100% {
      opacity: 1;
      bottom: 20px;
    }
  }

  &.expire {
    //background-color: #727272;
    .count-time-wrap {
      color: #999999;
    }

    .day-text {
      color: #d8d8d8 !important;
    }

    .time-wrap {
      background-color: $black-color !important;
    }
  }

  .count-time-wrap {
    width: inherit;
    @include flex(center, center);

    .day-number,
    .time-wrap {
      @include font(50px, 700) {
      }
      text-align: center;
      background-color: #336dff;
      border-radius: 6px;
    }

    .time-wrap {
      width: inherit;
    }

    .day-number {
      padding: 8px 10px;
    }

    .day-text {
      margin-left: 4px;
      margin-right: 10px;
      @include font(15px, 700) {
        color: #d6e5ff;
      }
    }

    .time-wrap {
      @include flex(center, center);
      background-color: #242424;
      padding: 20px;

      > span {
        margin-right: 10px;
        color: #fff;
        &:last-child {
          margin-right: 0px;
        }
        &.colon {
          height: 24px;
          line-height: 0.33;
        }
      }

      @include new-mobile {
        > span {
          font-size: 8vw;
          margin-right: 2vw;
          &.colon {
            height: 4.5vw;
          }
        }
      }
    }
  }

  .count-content-wrap {
    margin-left: 20px;

    .title {
      margin-bottom: 2px;
    }

    .sub-title {
      @include font(11px, 400) {
        color: #e8e8e8;
      }
    }
  }
}
