@import '../common/mixin';

.ebook-detail-container {
  padding: 64px 0 160px;
}

.ebook-detail-content {
  display: flex;
  flex-direction: row;
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 40px;

  .ebook-area {
    flex: 1 1 600px;
  }

  .book-cover {
    overflow: hidden;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
    }
  }

  .purchase-area {
    flex: 1 1 392px;
    margin-left: 32px;
  }

  .purchase-inner {
    position: sticky;
    top: 20px;
  }

  .purchase-box {
    margin-bottom: 12px;
    padding: 20px 20px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;

    .category-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .book-category {
      color: #242424;
      font-size: 14px;
    }

    .book-title {
      color: #222;
      font-size: 24px;
      font-weight: 600;
      line-height: 31.2px;
      text-align: left;
    }

    .book-info {
      margin-top: 8px;
      color: #777;
      font-size: 14px;
    }

    .book-stat {
      margin-top: 20px;
      color: #777;
      font-size: 14px;
      line-height: 1.5;

      .book-star-count {
        display: inline-block;
        padding-left: 20px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_star.svg',
          left,
          1px,
          16px 16px
        );
        vertical-align: middle;
      }

      .book-bookmark-count {
        display: inline-block;
        margin-left: 10px;
        padding-left: 20px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_bookmark_3.svg',
          left,
          2px,
          16px 16px
        );
        vertical-align: middle;
      }
    }

    .book-utils {
      // position: absolute;
      // top: 20px;
      // right: 20px;
      height: 28px;

      button + button {
        margin-left: 15px;
      }

      img {
        width: 28px;
        height: 28px;

        &.enp_mobon_wish {
          filter: invert(50%) grayscale(50%);
        }
      }
    }

    .purchased {
      margin-bottom: 12px;
      background-color: #d8d8d8;
      color: #999;
      cursor: default;
    }

    .price-area {
      margin-top: 24px;
      padding-top: 24px;
      border-top: 1px solid #e8e8e8;

      .origin-price {
        margin-bottom: 8px;
        color: #999;
        font-size: 14px;
        text-decoration: line-through;
      }

      .real-price-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
      }

      .real-price {
        font-weight: bold;
        font-size: 24px;
        color: #242424;
      }

      .discount-rate {
        padding: 6px;
        border-radius: 4px;
        background-color: #24c091;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }

    .popup-desc {
      margin-top: 20px;
      color: #595959;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      a {
        text-decoration-line: underline;
      }
    }
  }

  .book-navi {
    position: sticky;
    top: 0;
    margin: 40px 0;
    background-color: #fff;
    font-size: 0;

    li + li {
      margin-left: 28px;
    }

    li {
      display: inline-block;
      vertical-align: middle;

      &.active {
        a {
          color: #242424;
          font-weight: bold;

          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #4745ff;
          }
        }
      }

      a {
        display: block;
        position: relative;
        padding: 12px 0;
        color: #595959;
        font-size: 16px;
      }
    }
  }

  .book-introduce {
    line-height: 1.5;
    img {
      display: block;
      width: 100%;
    }
  }

  .book-index {
    margin-top: 40px;
    padding-bottom: 40px;
    color: #595959;
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-line;

    img {
      display: block;
      width: 100%;
    }
  }

  .close-popup {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ebook-detail-container {
    padding-top: 0;
  }

  .ebook-detail-content {
    flex-direction: column;
    padding: 0 20px 50px;

    .purchase-inner {
      position: static;
    }

    .book-navi {
      top: 48px;
    }

    .purchase-area {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      margin-left: 0;
      background-color: #fff;

      &.open {
        &:before {
          content: '';
          display: block;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
        }

        .purchase-wrap {
          display: block;
        }

        .close-popup {
          display: block;
          overflow: hidden;
          position: absolute;
          top: 15px;
          right: 24px;
          width: 16px;
          height: 16px;
          @include background(
            '#{$img-root-url}/static/png/common/icon-close-gray.png',
            center,
            center,
            contain
          );
          text-indent: -9999px;
        }
      }
    }

    .purchase-box {
      position: relative;
      background-color: #fff;
      margin-bottom: 0;
      padding: 12px;
      border-bottom: 0;

      .book-utils {
        top: 50px;
      }

      .purchase-wrap {
        display: none;
        padding: 30px 5px 0;
      }

      .price-area {
        .discount-rate {
          margin-left: 10px;
        }
      }
    }

    .sub-banner-wrap {
      display: none;
    }
  }
}
