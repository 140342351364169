@import '../common/mixin';

.curation-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 488px;
  max-height: 285px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 40px;
  padding-bottom: 40px;
  @include flex(flex-start, flex-start);

  @include new-mobile {
    padding-bottom: 18px;
  }

  .curation-course-wrapper {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(auto, 70px));
    grid-template-rows: repeat(1, minmax(auto, 70px));
    column-gap: calc(2%);
    row-gap: calc(5%);
    margin-top: 19px;
    width: 100%;

    .curation-course {
      width: 100%;

      .img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }

      &:last-child {
        position: relative;

        .background {
          position: relative;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: #242424;

          &:before {
            content: '+';
            width: 100%;
            height: calc(100% - 5px);
            font-size: 60px;
            font-weight: 50;
            color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
          }
        }

        .img {
          position: relative;
          opacity: 0.4;
        }
      }
    }
  }

  .curation-desc-wrapper {
    .title-wrap {
      display: flex;
      align-items: center;
    }

    .title {
      display: inline-block;
      margin-top: 3px;
      @include font(18px, 700) {
        line-height: 16px;
        color: #2725d2;
      }

      @include new-mobile {
        font-size: 16px;
      }
    }

    img {
      display: inline-block;
      margin-top: 2px;
      margin-left: 8px;
    }

    .description {
      margin-top: 9px;
      height: 48px;
      @include font(16px, 400) {
        color: #242424;
        line-height: 24px;
      }
      @include textEllipsis(42px, 2);

      @include new-mobile {
        margin-top: 4px;
        font-size: 14px;
      }
    }

    .tag {
      margin-top: 8px;
      @include font(14px, 400) {
        line-height: 20px;
        color: #727272;
      }

      @include new-mobile {
        margin-top: 10px;
        font-size: 12px;
      }

      span {
        &:last-child::before {
          content: ',\00A0';
          display: inline-block;
          cursor: default;
        }
      }
    }
  }
}
