@import '../common/mixin';

.time-event-banner-skeleton {
  max-width: 1128px;
  height: 308px;
  margin-bottom: 64px;
  background-color: #f6f7f8;
  border-radius: 20px;

  @include new-mobile {
    height: 244px;
  }

  .display-time-box {
    width: 714px;
    margin: 0 auto;
    padding-top: 168px;
    @include flex(space-between, center);

    @include new-mobile {
      width: 324px;
      padding-top: 160px;
    }

    @include mediaQuery(360px) {
      width: 292px;
    }

    .count-box-wrap {
      @include flex(center, center);

      .count-box {
        position: relative;
        @include size(107px, 100px);
        background-color: #e8e9eb;
        border-radius: 10px;

        @include new-mobile {
          @include size(53px, 50px);
        }

        @include mediaQuery(360px) {
          @include size(48px, 45px);
        }
      }
    }
  }
}
