@import '../common/mixin';

.my-page-wrap {
  .popular-class-wrap {
    min-width: 360px;
    padding-bottom: 24px;
    background: #f6f6f9;

    @include new-mobile {
      margin: 0 0 57px;
    }

    h3 {
      margin: 0;
      padding: 40px 0 38px;

      @include new-mobile {
        padding: 15px 0 20px;
      }
    }

    .course-list {
      margin-bottom: -3px;
    }
  }
}

.my-page {
  padding: 56px 20px 60px;
  width: 100%;
  min-width: 360px;

  @include new-mobile {
    padding-top: 16px;
  }

  &.pc {
    display: flex;
    justify-content: center;

    .side-bar {
      width: 254px;
      margin: 24px 33px 0 0;
    }
  }

  .category-contents-container {
    padding-top: 30px;
  }

  &.pc {
    .category-contents-container {
      padding-top: 19px;
      width: 839px;
    }
  }

  .my-category-wrap {
    display: flex;
    flex-direction: column;

    @include new-tablet {
      margin-top: 20px;
    }
  }

  .category-select-nav-wrap {
    position: sticky;
    top: 42px;
    margin: 0 -20px;
    z-index: 99;
  }

  .category-select-nav {
    overflow-x: auto;
    min-width: 360px;
    padding: 4px 12px;
    background-color: #ffffff;
    z-index: 99;
    white-space: nowrap;

    img {
      margin: 0 3px -7px 0;
      width: 24px;
      height: 24px;
    }

    .category {
      margin: 10px;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      color: #595959;
    }

    .selected {
      padding-bottom: 5px;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      color: #2725d2;
      border-bottom: 2px solid #2725d2;
    }
  }

  .around-course-img {
    width: 100px;
    border: 1px solid #eaeaea;
    object-fit: cover;
  }
}

//내 강의
.my-course-filter-nav {
  padding: 8px 0;
  margin: 7px 0 16px;

  span {
    height: 32px;
    padding: 9px 12px;
    margin-right: 10px;
    border: 1px solid #eaeaea;
    border-radius: 19px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #242424;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    background: #4745ff;
    color: #ffffff;
    border: 1px solid #4745ff;
  }
}

.course {
  @include new-tablet {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 20px;
  }

  .my-course-card {
    height: 140px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    @include new-tablet {
      display: flex;
      padding: 0;
      height: 100%;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 20px;
    }

    .lecture-row {
      width: 100%;
      cursor: pointer;

      .flex-box {
        display: flex;
        width: 100%;
        gap: 20px;
        height: 100%;

        .title-box {
          width: 100%;
          margin-top: 8px;
        }

        .img-box {
          position: relative;
        }

        img {
          width: 161px;
          height: 100%;
          border-radius: 8px;
        }

        h3 {
          width: 100%;
          padding-bottom: 4px;
          font-weight: 600 !important;
          font-size: 16px;
          line-height: 130%;
          color: #242424;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @include new-mobile {
            width: 100%;
            white-space: normal !important;
            word-wrap: break-word !important;
          }
        }

        p {
          max-width: 235px;
          min-width: 114px;
          padding-right: 10px;
          font-size: 13px;
          line-height: 17px;
          color: #999;
          overflow: hidden;
          word-break: keep-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @media (max-width: 1045px) {
            max-width: 500px;
          }

          @include new-mobile {
            font-size: 12px;
            line-height: normal;
          }
        }
      }

      .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        min-width: 54px;
        height: 54px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);

        img {
          width: 24px;
          height: 24px;
          margin: 15px;
        }
      }
    }

    .my-course-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: end;
      flex-direction: column;

      @include new-tablet {
        align-items: start;
        justify-content: start;
        gap: 12px;
      }

      span {
        p {
          width: 110px;
          font-size: 12px;
          line-height: 17px;
          color: #999999;
        }

        button,
        a {
          height: 34px;
          text-align: center;
          border-radius: 2px;
          padding: 0 15px;
          font-size: 13px;
          line-height: 16px;
          background: #f2f2ff;
        }

        .document-down-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px 6px 9px 12px;
          background: #242424;
          color: #ffffff;

          img {
            height: 24px;
            padding-left: 3px;
            margin: -9px 0px -7px;
          }
        }
      }

      h5 {
        padding: 17px 0 0;
        font-size: 12px;
        line-height: 17px;
        color: #999999;
      }

      .progress-row {
        display: flex;
        margin-top: 8px;

        @include new-tablet {
          margin: 0;
        }

        div {
          display: flex;

          .progress-state {
            height: 24px;
            padding: 4px 8px;
            border: 1px solid #4745ff;
            box-sizing: border-box;
            border-radius: 16px;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            color: #2725d2;
          }

          .expired {
            border: 1px solid #d8d8d8;
            color: #727272;
          }

          p {
            margin: 5px 0 5px 8px;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            color: #595959;
          }
        }
      }

      .badge-row {
        display: flex;
        gap: 8px;
        margin-bottom: 6px;
        font-size: 13px;

        @include new-tablet {
          margin: 0;
        }

        .certificate-down-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px 6px 9px 12px;
          background: #4745ff;
          color: #ffffff;
          border-radius: 2px;

          img {
            height: 24px;
            padding-left: 3px;
            margin: -9px 0px -7px;
          }
        }
      }
    }
  }
}

//저장한 클래스
.favorite {
  gap: 80px;

  @include new-mobile {
    gap: 40px;
  }

  h2 {
    padding: 0 0 12px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #242424;
  }

  .my-favorite-course {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 48px;
    column-gap: 20px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @include new-mobile {
      column-gap: 8px;
      row-gap: 20px;
    }
  }
}

//쿠폰함
.coupon {
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 42px;
  grid-column-gap: 3vw;
  margin: 0 -4px 0;

  @include new-mobile {
    grid-row-gap: 20px;
  }

  @media (max-width: 1128px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .coupon-card {
    display: flex;
    height: 172px;
    width: 376px;
    padding: 20px 0;
    @include background(
      '#{$img-root-url}/static/v2/png/mypage/couponcard.png',
      center,
      center,
      cover
    );

    @include new-mobile {
      height: 140px;
      width: 328px;
      padding: 20px 0 16px;
      margin: 0 auto;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      @include background(
        '#{$img-root-url}/static/v2/png/mypage/m_couponcard.png',
        center,
        center,
        cover
      );
    }

    .coupon-main-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 287px;
      padding: 0 20px;
      border-right: 1px solid #a1a0f4;

      .coupon-target {
        display: inline-block;
        margin-bottom: 5px;
        padding: 6px 8px 6px 8px;
        border-radius: 13px;
        background-color: #4745ff;
        color: #fff;
        font-size: 10px;
      }

      @include new-mobile {
        padding: 0 14px;
        width: 228px;
      }

      .coupon-name {
        margin-bottom: 8px;
        font-size: 13px;
        color: #242424;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include new-mobile {
          margin-bottom: 12px;
        }
      }

      .coupon-extra-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          position: relative;
          font-size: 12px;
          line-height: 14px;
          color: #727272;
        }

        .coupon-guide {
          cursor: pointer;

          p {
            display: inline-block;
            padding-right: 3px;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            color: #999999;
            vertical-align: middle;
          }

          img {
            width: 16px;
            height: 16px;
            margin-top: -1px;
            vertical-align: middle;
          }
        }
      }

      h2 {
        display: flex;
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        color: #2725d2;

        @include new-mobile {
          line-height: 19px;
          font-size: 20px;
        }

        p {
          font-size: 18px;
          margin: 14px 4px 0;
          line-height: 16px;
          text-align: center;
          color: #727272;

          @include new-mobile {
            margin: 2px 2px 0;
            font-size: 17px;
          }
        }
      }

      h3 {
        height: 74px;
        margin-bottom: 22px;
        display: flex;
        font-weight: bold;
        font-size: 28px;
        line-height: 38px;
        color: #2725d2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include new-mobile {
          height: 37px;
          line-height: 19px;
          font-size: 20px;
        }
      }
    }

    .coupon-amount-info {
      margin: auto;
      text-align: center;

      p {
        padding: 4px;
      }
    }
  }
}

//배송조회
.shipping {
  grid-row-gap: 30px;
  grid-column-gap: 34px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  @include new-mobile {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }

  .my-shipping-card {
    height: 218px;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.16),
      0 2px 14px rgba(0, 0, 0, 0.16);
    border-radius: 12px;

    @include new-mobile {
      height: 184px;
      padding: 10px 20px;
    }

    .kit-info-row {
      display: flex;
      align-items: center;

      div {
        padding: 0 8px;

        h3 {
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          color: #242424;
          word-break: keep-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @include new-mobile {
            font-size: 16px;
            min-width: 200px;
          }
        }
      }

      .kit-img {
        width: 64px;
        min-width: 64px;
        height: 64px;
        background: beige;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
    }

    .shipping-info {
      padding-bottom: 20px;

      @include new-mobile {
        padding-bottom: 6px;
      }

      .shipping-process-bar {
        height: 61px;
        display: flex;
        padding: 48px 25px 0;

        @include new-mobile {
          padding: 37px 25px 0;
        }

        div {
          padding: 3px;
          height: 8px;
          width: 8px;
          margin-top: 3px;
          border: 2px solid #e8e8e8;
          border-radius: 50%;

          &.active {
            padding: 5px;
            width: 13px;
            height: 13px;
            margin-top: 1px;
            border: 2px solid #4745ff;
          }

          p {
            width: 70px;
            margin: -33px 0px 0px -22px;
            font-size: 13px;
            line-height: 20px;
            color: #242424;
          }
        }

        hr {
          height: 2px;
          margin-top: 7px;
          border: none;
          background-color: #e8e8e8;
          width: 50%;

          &.active {
            background-color: #4745ff;
          }
        }
      }
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 12px;
        line-height: 14px;
        color: #595959;
      }

      button {
        width: 75px;
        height: 30px;
        background: #4745ff;
        border-radius: 15px;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
      }
    }
  }
}

.my-ebook-list {
  overflow: hidden;

  .my-ebook-list-inner {
    margin: -10px;
  }

  .popup-desc {
    margin-bottom: 8px;
    text-align: center;
    padding: 20px;
    font-size: 14px;
    line-height: 130%;
    border-radius: 4px;
    background: #f4f4f4;
    color: #595959;
    font-weight: 400;

    a {
      text-decoration-line: underline;
    }
  }

  .ebook-item {
    float: left;
    width: 50%;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .my-ebook-list {
    .ebook-item {
      width: 100%;
    }
  }
}
