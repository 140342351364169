@import '../common/mixin';

.placeholder-h3 {
  background: #fff;
  height: 28px;
  max-width: 300px;

  &.type1 {
    @include new-mobile {
      margin: 0 0 0 10%;
    }
  }

  @keyframes placeholderAnim {
    0% {
      background-position: -300px 0;
    }
    100% {
      background-position: 300px 0;
    }
  }

  .placeholder-h3-background {
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderAnim;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(
      90deg,
      rgba(246, 246, 246, 1) 0%,
      rgba(237, 237, 237, 1) 50%,
      rgba(246, 246, 246, 1) 100%
    );
    height: inherit;
    position: relative;

    @include new-mobile {
      display: block;
      margin: 0 auto;
      max-width: 488px;
    }
  }
}
