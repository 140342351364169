@import '../common/mixin';

.coupon-choice-modal {
  z-index: 9998;

  .modal-content {
    max-height: none;
  }

  .modal-section {
    padding: 16px 16px 80px;
  }
}

.coupon-list {
  margin-top: 24px;
  max-height: 460px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  padding-bottom: 2px;

  li {
    position: relative;
    background-color: #f6f6f6;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    margin-top: 12px;
    padding: 20px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .coupon-discount-amount {
      color: #6043fa;
      font-weight: bold;
      font-size: 20px;
    }

    .coupon-basic-info {
      margin-top: 15px;
      color: #242424;
      font-size: 14px;
      max-width: 89%;
      word-break: keep-all;
    }

    .coupon-title {
      font-weight: bold;
    }

    .coupon-category {
      position: relative;
      margin-left: 11px;
      line-height: 1.6;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -6px;
        width: 1px;
        height: 12px;
        background-color: #242424;
        transform: translateY(-50%);
      }
    }

    .coupon-expire-date {
      margin-top: 8px;
      color: #727272;
      font-size: 14px;
    }

    &:first-child {
      margin-top: 0;
    }

    img {
      display: block;
      width: 24px;
      height: 24px;
    }

    &.selected {
      border-color: $main-color;
    }
  }

  .coupon-checkbox {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .empty-coupon-list {
    display: inherit;
    text-align: center;
    cursor: default;

    p {
      @include font(14px, 400) {
        color: #999999;
        line-height: 24px;
      }
    }
  }
}

.coupon-enrollment-btn-wrap {
  right: 16px;
  top: 16px;
  cursor: pointer;
  @include flex(flex-end, center);

  @include new-mobile {
    padding: 16px 16px 0;
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    padding-top: 2px;
  }

  span {
    @include font(16px, 500) {
      color: $main-color;
      line-height: 18px;
    }
  }
}

.coupon-choice-btn-wrap {
  .coupon-choice-btn {
    opacity: 0.3;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: inherit;
      cursor: pointer;
    }
  }
}

@include mobile {
  .coupon-list {
    max-height: calc(100% - 105px);
    padding: 0 16px;
  }
}
