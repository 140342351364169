@charset "utf-8";
@import '../../scss/common/mixin';

.error_container {
  padding: 200px 0;

  .browser-list {
    margin-top: 20px;
  }

  .browser-card {
    border: 1px solid #eeeeee;
    margin: 10px;
    padding: 20px;
    display: inline-block;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .browser-card:hover {
    transform: scale(1.03);
  }
}

@include tablet() {
  .error_container {
    padding: 20px 20px;

    .browser-list {
      display: grid;
      text-align: center;
    }
  }
}
