@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';

.search-container {
  padding-top: 80px;

  @include new-mobile {
    padding-top: 0;
  }
}

.td-wrapper-v2 {
  &.search {
    @include new-mobile {
      padding: 0;
    }
  }
}

.search-popular-wrap {
  background-color: #f6f6f9;
  padding: 40px 0 10px 0;

  @include new-mobile {
    background-color: transparent;
  }
}

.search-master-wrap {
  margin-top: 96px;
}

.search-header-wrap {
  margin: 40px 0;

  .td-wrapper-v2 {
    margin-top: 64px;
    padding: 0 16px;
    position: relative;
    @include flex(flex-start, flex-start);
  }
}

.search-input-wrap {
  position: relative;
  background-color: #ffffff;
}

.search-content-wrap {
  margin: 50px 0 0;
}

.search-content-wrap.ebook-wrap {
  margin-bottom: 50px;
}

.search-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  color: #888;
}

.search-input {
  margin-top: 8px;
  width: 400px;
  flex-shrink: 0;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 52px 0 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #c4c4c4;

  &::placeholder {
    @include new-mobile {
      font-size: 14px;
    }
  }
}

.search-btn {
  cursor: pointer;
  width: 22px;
  height: 23px;
  margin-left: -42px;
  vertical-align: -6px;
  -webkit-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 0.35;
}

.search-nav-set {
  display: grid;
  justify-content: center;
  justify-items: flex-start;
  padding: 0 16px;
  margin: 25px 0 40px 0;
  grid-template-columns: repeat(2, minmax(154px, 234px));
  column-gap: calc(4.8%);
  row-gap: 20px;

  li {
    width: 100%;
    height: 34px;
    border-bottom: 1px solid #ededed;
    font-size: 15px;

    a {
      display: flex;
      align-items: center;

      .super-z {
        line-height: 15px;
        margin-left: 2px;
        font-size: 16px;
        color: #005bff;
      }

      .hard-study {
        width: 34.4px;
        height: 19px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/빡공단black 1.svg',
          center,
          center,
          cover
        );
      }
    }
  }
}

.search-title {
  @include font(20px, 400) {
    color: #595959;
  }
  margin-bottom: 20px;

  @include new-mobile {
    display: block;
    margin: 0 auto;
    max-width: 488px;
    font-size: 14px;
  }

  span {
    font-weight: bold;
  }
}

.search-result {
  @include font(20px, 700) {
    color: #242424;
  }
  margin-bottom: 20px;

  @include new-mobile {
    display: block;
    margin: 0 auto 20px auto;
    max-width: 488px;
    font-size: 15px;
  }
}

.empty-img {
  width: 100%;
  margin: 140px 0;
  align-items: center;
  text-align: center;
}

.search-keyword-wrapper {
  margin-top: 24px;

  ul {
    max-width: 400px;
  }

  li {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #888888;
    background: #f6f6f6;
    border-radius: 16px;
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 8px;
    padding: 10px 11px;
    cursor: pointer;
  }
}
