@import '../common/mixin';
@import '../common/mixin_animation';

.top-banner {
  @include flex(center, center);
  @include size(100%, 72px);
  z-index: 99;

  @include animation(show-anim, 0.2s, 0.1s, linear, backwards);
  @include keyframe(show-anim) {
    from {
      height: 0;
    }
    to {
      height: 72px;
    }
  }

  @include new-tablet {
    justify-content: flex-start;
  }

  &.show {
    .top-banner-wrap {
      @include animation(opacity-anim, 0.2s, 0.3s, linear, backwards);
      @include keyframe(opacity-anim) {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  &.hide {
    @include animation(hide-anim, 0.2s, 0s, linear, forwards);
    @include keyframe(hide-anim) {
      from {
        height: 72px;
      }
      to {
        height: 0;
      }
    }

    .top-banner-wrap {
      @include animation(opacity-anim-2, 0.1s, 0.1s, linear, forwards);
      @include keyframe(opacity-anim-2) {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }
  }
}

.top-banner-wrap {
  width: 100%;
  max-width: 1128px;
  @include flex(space-between, center);

  @include new-tablet {
    padding: 0 16px;
  }

  .left-wrap {
    @include flex(space-between, center) {
      flex-basis: 1000px;
    }

    .content {
      width: 100%;

      @include new-tablet {
        max-width: 596px;
      }

      @include new-mobile {
        max-width: 224px;
      }

      h2 {
        @include font(20px, 700) {
          line-height: 24px;
          letter-spacing: -1px;
        }

        @include new-mobile {
          margin-bottom: 2px;
          font-size: 14px;
          line-height: 18px;

          @include textEllipsis(18px, 1);
        }
      }

      h3 {
        @include font(16px, 400) {
          line-height: 24px;
          letter-spacing: -1px;
          opacity: 0.9;
        }

        @include new-mobile {
          font-size: 12px;
          line-height: 14px;

          @include textEllipsis(28px, 2);
        }
      }
    }

    a {
      width: 100%;
    }
  }

  .right-wrap {
    @include flex(center, center);

    .icon-image {
      @include size(96px, 72px);

      @include new-mobile {
        @include size(64px, 48px);
      }
    }

    .close-btn {
      margin-left: 20px;
      @include size(24px, 24px);
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_x_2.svg',
        center,
        center,
        cover
      );
      cursor: pointer;

      @include new-mobile {
        margin-left: 10px;
      }
    }
  }
}
