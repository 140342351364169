@import '../common/mixin';

.my-coupon-list {
  margin-top: 8px;

  .coupon-item {
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    border: solid 4px #eaeaea;
    margin-top: 16px;
  }
}

.coupon-info {
  padding: 18px 28px 19px;
  position: relative;
  border-bottom: 1px solid #eaeaea;

  .use-status {
    position: absolute;
    width: 88px;
    height: 88px;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f9f9f9;
    @include font(16px, 700) {
      line-height: 88px;
      color: $main-color;
    }
    text-align: center;
    border-radius: 50%;
  }

  .use-status-unlimit {
    position: absolute;
    width: 88px;
    height: 88px;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000000;
    display: grid;
    align-items: center;
    @include font(14px, 500) {
      line-height: 24px;
      color: #ff3d70;
    }

    text-align: center;
    border-radius: 50%;
    span {
      font-size: 22px;
      font-weight: bold;
    }
  }

  h6 {
    @include font(14px, 700) {
      line-height: 14px;
      color: #888888;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 80px;
  }

  h4 {
    margin-top: 4px;
    @include font(40px, 900) {
      line-height: 59px;
      color: $main-color;
    }
    padding-right: 80px;
  }
}

.coupon-info-status {
  padding: 12px 28px;
  @include flex(space-between, center);

  p {
    @include font(14px, 400) {
      line-height: 14px;
      color: #999999;
    }
  }

  .coupon-info-btn {
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
      vertical-align: -2px;
      margin-right: 4px;
    }
    span {
      @include font(12px, 500) {
        line-height: 16px;
      }
    }
  }
}
