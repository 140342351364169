@import '../../scss/common/mixin';

.ebook-card {
  position: relative;

  .bookmark-button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;
  }

  .bookmark-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_bookmark_3.svg',
      center,
      center,
      cover
    );

    &.active {
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_bookmark_1.svg',
        center,
        center,
        cover
      );
    }
  }
}

.ebook-card-inner {
  display: flex;
  flex-direction: row;
  height: 164px;
  padding: 16px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;

  .ebook-thumbnail {
    overflow: hidden;
    flex: 0 1 90px;
    height: 130px;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ebook-info-area {
    width: 60%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
    padding: 4px 0;
  }

  .ebook-card-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222;
    font-weight: bold;
    font-size: 18px;
  }

  .ebook-info {
    margin-top: 8px;
    color: #777;
    font-size: 14px;
  }

  .ebook-description {
    margin-top: 10px;
    font-size: 12px;
    color: #999;
  }

  .ebook-completed {
    margin-top: 7px;
    font-size: 12px;
    color: #999;
  }

  .read-button {
    display: block;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: #4745ff;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
  }

  .read-start-button {
    display: block;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba(71, 69, 255, 0.1);
    color: #4745ff;
    font-weight: bold;
    font-size: 13px;
  }

  .discount-wrap {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 12px;

    .discount-rate {
      color: #4745ff;
    }

    .origin-price {
      margin-left: 4px;
      color: #adadad;
      text-decoration: line-through;
    }
  }

  .price {
    font-weight: bold;
    font-size: 18px;
    color: #222;
  }
}
