@import '../common/mixin';

.book-list-wrap {
  overflow: hidden;
  width: 100%;
}

.book-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  .book-item {
    flex: 0 0 20%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;

    &:hover {
      .bookmark-area {
        opacity: 1;
      }
    }
  }

  .book-thumbnail {
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 144%;
    border-radius: 4px;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .book-badges {
    margin: 10px 0;
    font-size: 0;

    .badge {
      display: inline-block;
      padding: 3px 8px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      font-weight: bold;
    }

    .soon {
      background-color: #9570ff;
    }

    .best {
      background-color: #ff4646;
    }

    .new {
      background-color: #ffc83c;
    }
  }

  .book-title {
    margin-top: 10px;
    color: #222;
    font-weight: bold;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .book-info {
    margin-top: 7px;
    color: #777;
    font-size: 14px;

    span {
      vertical-align: middle;

      & + span {
        margin-left: 4px;
      }
    }
  }

  .bookmark-area {
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    transition: opacity 0.3s ease-in-out;
  }

  .bookmark-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
  }

  .bookmark-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_bookmark_2.svg',
      center,
      center,
      cover
    );

    &.active {
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_bookmark_1.svg',
        center,
        center,
        cover
      );
    }
  }

  .bookmark-count-area {
    margin-top: 12px;
    padding-left: 20px;
    font-size: 14px;
    color: #777;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_bookmark_1.svg',
      left,
      center,
      16px 16px
    );
  }
}

@media screen and (max-width: 1000px) {
  .book-list-wrap {
    padding: 0 15px;
  }

  .book-list {
    margin: 0 -5px;

    .book-item {
      flex: 0 0 33.3333333%;
      padding: 20px 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .book-list {
    .book-item {
      flex: 0 0 50%;
    }
  }
}
