@import '../common/mixin';

/* 메인홈 인기 클래스 */
.popular-course-content-main {
  padding: 70px 0 40px;

  @include new-tablet {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include new-mobile {
    padding: 36px 0 30px 16px;
  }

  .content-title {
    display: flex;
    justify-content: center;
    margin-bottom: 38px;

    @include new-mobile {
      justify-content: flex-start;
      margin-bottom: 28px;
    }

    h3 {
      @include font(28px, 700) {
        color: $black-color;
        line-height: 34px;
      }
      text-align: center;

      @include new-mobile {
        margin-right: 9px;
        text-align: left;
        font-size: 20px;
        line-height: 24px;
      }
    }

    img {
      display: none;

      @include new-mobile {
        display: block;
      }
    }

    a::after {
      display: none;
    }
  }

  .popular-course-btn {
    @include new-mobile {
      display: none;
    }

    > a {
      @include flex(center, center);
    }

    span {
      font-size: 16px;
      line-height: 19px;
      color: #0226f0;
      margin-right: 4px;
    }
  }
}

.popular-course-slide {
  padding: 0 58px;
  margin-bottom: 42px;
  position: relative;

  @include new-tablet {
    padding: 0 34px;
  }

  @include new-mobile {
    padding: 0;
    margin-bottom: 0;
  }

  .base-card {
    box-shadow: none;

    @include new-mobile {
      width: 154px;
    }
  }

  .swiper-slide {
    @include new-mobile {
      width: 154px !important;
    }
  }

  .swiper-popular-slider-prev,
  .swiper-popular-slider-next {
    @include size(40px, 40px);
    @include flex(center, center);
    border-radius: 50%;
    position: absolute;
    top: 100px;
    cursor: pointer;
    transition: all, 0.3s, ease-in-out;

    &:hover {
      background-color: $white-color;

      @include new-tablet {
        background-color: transparent;
      }
    }

    @include new-mobile {
      display: none;
    }
  }

  .swiper-popular-slider-prev {
    left: 0px;

    @include new-tablet {
      left: -14px;
    }
  }

  .swiper-popular-slider-next {
    right: 0px;

    @include new-tablet {
      right: -14px;
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

/* 전체 페이지 인기 클래스 */
.popular-course-content {
  padding: 0 16px;

  .popular-content-title {
    margin-bottom: 38px;

    @include font(18px, 700) {
      color: #595959;
      line-height: 26px;
    }

    @include new-mobile {
      font-size: 16px;
      line-height: 19px;
      display: block;
      margin: 0 auto 38px auto;
      max-width: 488px;
    }
  }
}
