@charset "utf-8";
@import '../../scss/common/mixin';

.info-container {
  padding: {
    top: 80px;
    bottom: 180px;
    left: 16px;
    right: 16px;
  }
  margin: auto;
  max-width: 1024px;
  text-align: center;
}

.info-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 39px;
  font-weight: 600;
  text-align: center;
  margin-top: 64px;
}

.info-box {
  background: #4390f8;
  border-radius: 10px;
  padding: 50px 10px;
  color: #ffffff;

  &.info-hint {
    padding: 30px 16px;
  }

  .box-sub-title {
    font-size: 16px;
    font-weight: 400;
  }

  .box-title {
    margin-top: 14px;
    font-size: 28px;
    font-weight: 600;
    line-height: 26px;
    word-break: keep-all;

    span {
      color: #f9f91d;
    }
  }
}

.info-wrapper {
  text-align: left;
  margin-top: 35px;

  .info-hint {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    word-break: keep-all;

    span {
      font-size: 16px;
      color: #bbbbbb;
    }

    b {
      font-weight: 700;
    }
  }

  .title {
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;

    span {
      color: $main-color;
    }
  }

  .info {
    margin-top: 12px;
    line-height: 24px;
    font-size: 16px;

    a {
      color: blue;
      cursor: pointer;
    }

    b {
      font-weight: 600;
    }

    img {
      margin-top: 4px;
      border: 1px solid #dddddd;
    }
  }
}

@include tablet {
  .info-title {
    display: none;
  }

  .info-box {
    padding: 30px 10px;

    .box-sub-title {
      font-size: 12px;
    }

    .box-title {
      font-size: 20px;
    }
  }

  .info-wrapper {
    margin-top: 0;

    .info-hint {
      font-size: 14px;
      line-height: 20px;

      span {
        font-size: 12px;
        line-height: 14px;
      }
    }

    .title {
      font-size: 16px;
    }

    .info {
      font-size: 12px;

      input {
        vertical-align: -2px;
      }

      img {
        width: 100%;
      }
    }
  }
}
