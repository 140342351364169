@charset "utf-8";
@import '../common/mixin';

.template-input-wrap {
  /* InputLabel */
  .label-wrap {
    margin-bottom: 8px;

    .label {
      display: block;
      @include font(14px, 700) {
        color: $black-color;
        line-height: 22px;
      }

      span {
        margin-left: 4px;
        @include font(13px, 400) {
          color: $main-color-v2;
          line-height: 16px;
        }
      }
    }
  }

  /* TemplateInput */
  .input-wrap {
    margin-bottom: 30px;
    height: 70px;

    @include new-mobile {
      margin-bottom: 20px;
    }

    input {
      padding: 12px 0 10px;
      width: 540px;
      @include font(14px, 17px) {
        color: $black-color;
        line-height: 17px;
      }
      border-bottom: 1px solid #d8d8d8;

      @include new-mobile {
        width: 100%;
      }

      &:focus {
        border-color: #595959 !important;
      }
    }
  }

  .default-msg {
    margin-top: 6px;
    @include font(12px, 400) {
      color: #ff2828;
      line-height: 14px;
    }

    &.success {
      color: $main-color-v2;
    }
  }

  /* PasswordInput */
  .password-input-wrap {
    position: relative;

    .eye-icon {
      @include size(24px, 24px);
      position: absolute;
      right: 1px;
      top: 39px;
    }
  }

  /* GenderButton */
  .gender-button-wrap {
    .gender-btn-wrap {
      @include flex(flex-start, center);
      max-width: 328px;
      margin-top: 5px;
      margin-bottom: 6px;

      .gender-btn {
        width: 48%;
        padding: 12px 0;
        border: 1px solid #eaeaea;
        border-radius: 2px;
        @include font(14px, 400) {
          color: $black-color;
          line-height: 16px;
        }

        &:first-child {
          margin-right: 12px;
        }

        &.active {
          color: $main-color-v2;
          border-color: $main-color-v2;
        }

        &:focus {
          color: $main-color-v2;
          border-color: $main-color-v2;
        }
      }
    }
  }

  /* JobSelectBox */
  .select-box-wrap {
    margin-top: 5px;
    cursor: pointer;
    height: 89px !important;

    .label-wrap {
      margin-bottom: 5px;
    }

    .css-1uccc91-singleValue {
      padding-left: 12px;
      margin-left: 0;
      margin-right: 0 !important;
      @include font(14px, 400) {
        line-height: 16px;
        color: $black-color;
      }
    }

    .css-1hwfws3,
    .css-g1d714-ValueContainer {
      padding: 0px !important;

      .css-1wa3eu0-placeholder {
        margin-left: 12px;
        @include font(14px, 400) {
          line-height: 16px;
          color: #999999;
        }
      }
    }

    .css-1okebmr-indicatorSeparator,
    .css-109onse-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 8px 12px 8px 0;

      svg {
        width: 24px;
        height: 24px;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_arrow_down_b.svg',
          center,
          center,
          cover
        );

        path {
          display: none !important;
        }
      }
    }

    .css-1x716vn-control {
      border-radius: 2px !important;
    }

    .css-26l3qy-menu {
      margin-top: 0px !important;
      box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
      border: 1px solid #cdcdcd;
      border-top: 0px;
      border-radius: 2px !important;

      .css-1jqbu51-MenuList,
      div {
        padding: 0;
        overscroll-behavior: contain;

        > div {
          padding: 12px;
          cursor: pointer !important;
          @include font(14px, 400) {
            line-height: 16px;
            color: $black-color;
          }
        }
      }
    }

    .css-26l3qy-menu {
      div {
        &.css-1en3sjf-option:hover,
        &.css-1en3sjf-option {
          background-color: #f3f3f3 !important;
        }

        &.css-1ce6wmp-option {
          background-color: #ffffff !important;
        }
      }
    }
  }
}
