@import '../common/mixin';

/* 추후 수강후기 댓글 ui 변경에 대응해야함 */
.review-comment-card {
  padding: 16px 0;
  border-top: 1px solid #f6f6f6;

  .comment-card-wrap {
    padding: 16px;
    border-radius: 4px;
    background-color: #f6f6f6;
    position: relative;

    &.enabled-card {
      .review-bottom {
        justify-content: flex-start;
      }
    }
  }

  .user-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: #eaeaea;
  }

  .review-text {
    padding-left: 48px;
    position: relative;

    p {
      @include font(14px, 400) {
        line-height: 20px;
      }
      min-height: 20px;
      margin-top: 4px;
      white-space: pre-line !important;
      word-break: break-all;
      //&.hidden-text {
      //  white-space: pre-line;
      //}
    }

    h5 {
      position: relative;
      @include font(12px, 700) {
        line-height: 12px;
      }
    }

    .star-rating {
      margin-top: 7px;
    }

    .comment-write-btn {
      position: relative;
      margin-left: 20px;
      @include font(12px, 400) {
        line-height: 12px;
        color: #888888;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -13px;
        top: 2px;
        width: 12px;
        height: 10px;
        @include background(
          '#{$img-root-url}/static/png/product_detail/icon-reply.png',
          center,
          center,
          cover
        );
      }
    }
  }

  .review-bottom {
    @include flex(space-between, flex-start);
    margin-top: 8px;

    h6 {
      @include font(12px, 400) {
        line-height: 18px;
        color: #888888;
      }
    }

    a {
      @include font(12px, 400) {
        line-height: 18px;
        color: #888888;
      }

      &::after {
        content: url('#{$img-root-url}/static/svg/common/right-arrow.svg');
        display: inline-block;
        vertical-align: -1px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
        margin-left: 2px;
      }
    }

    button {
      @include font(12px, 300) {
        line-height: 18px;
        color: #aaaaaa;
      }
      cursor: pointer;
      margin-left: 7px;
    }
  }

  .review-info {
    position: relative;

    .edit-cancel-btn {
      position: absolute;
      right: 0;
      top: 4px;
      cursor: pointer;
      @include font(12px, 400) {
        line-height: 12px;
        color: #888888;
      }
    }
  }

  .comment-setting-wrap {
    position: absolute;
    right: 0;
    top: 1px;

    img {
      display: inline-block !important;
      cursor: pointer;

      &:first-of-type {
        width: 11px;
        height: 12px;
        margin-right: 12px;
      }

      &:last-of-type {
        width: 12px;
        height: 12px;
      }
    }
  }

  .comment-edit {
    background-color: #ffffff;
    margin-top: 6px !important;

    .write-wrap {
      padding-left: 0;
    }

    .star-rating {
      justify-content: center !important;
    }
  }

  .more-re-comment-btn-wrap {
    text-align: center;
    margin-top: 16px;
  }

  .more-re-comment-btn {
    @include font(12px, 400) {
      line-height: 12px;
      color: #2725d2;
    }

    &::after {
      content: url('#{$img-root-url}/static/svg/common/right-arrow-blue.svg');
      display: inline-block;
      vertical-align: -1px;
      -webkit-transform: scale(0.8) rotate(90deg);
      -moz-transform: scale(0.8) rotate(90deg);
      -o-transform: scale(0.8) rotate(90deg);
      transform: scale(0.8) rotate(90deg);
      margin-left: 4px;
    }

    &.fold::after {
      -webkit-transform: scale(0.8) rotate(-90deg);
      -moz-transform: scale(0.8) rotate(-90deg);
      -o-transform: scale(0.8) rotate(-90deg);
      transform: scale(0.8) rotate(-90deg);
    }
  }
}
