@import '../common/mixin';
.early-wrap-box {
  color: #fff;
  background-color: #6236ff;
  margin-bottom: 40px;
}
.earlybird-wrap {
  max-width: 600px;
  padding: 30px 0;
  @include new-tablet {
    max-width: 100%;
  }
  position: relative;

  .earlybird-box {
    width: 100%;
    padding: 0px 5%;

    & > div {
      div:nth-of-type(3) {
        .price-box {
          opacity: 0.6;
          .percent-circle {
            background-color: #999999;
          }
        }
      }
      div:nth-of-type(4) {
        .price-box {
          opacity: 0.6;
          .percent-circle {
            background-color: #999999;
          }
        }
      }
    }

    .earlybird-title {
      padding-bottom: 30px;

      div:first-child {
        color: white;
        font-size: 25px;
        @include new-tablet {
          font-size: 20px;
        }
        @include new-mobile {
          font-size: 5vw;
        }
      }
      div:last-child {
        color: white;
        font-size: 30px;
        font-weight: bold;
        margin-top: 10px;
        @include new-mobile {
          font-size: 5.5vw;
        }
      }
    }
    .icon-down-arrow {
      position: relative;
      z-index: 3;
      text-align: center;
      padding: 15px 0px;
    }
    .price-box-wrap {
      position: relative;
      color: #333;
      &:last-of-type {
        margin-top: 50px;
      }

      > h5 {
        font-weight: normal;
        padding: 3px 2px;
        position: absolute;
        top: 10px;
        left: 13px;
        z-index: 1;
        &.limited {
          color: red;
          border: 1px solid red;
          border-radius: 3px;
        }
        @include new-mobile {
          font-size: 12px;
        }
      }

      .price-box {
        height: 100px;
        background-color: white;
        border-radius: 5px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        position: relative;

        .content-left {
          color: #333;
          margin-top: 10px;
          width: 200px;
          position: relative;
          span {
            font-size: 27px;
            font-weight: bold;
          }
          @include new-tablet {
            width: 50%;
            margin-top: 20px;
            span {
              font-size: 24px;
            }
          }
          @include new-mobile {
            margin-top: 8px;
            span {
              font-size: 24px;
            }
          }
        }

        .content-right {
          margin-right: 15px;
          @include new-mobile {
            width: 50%;
          }
          > ul:first-child {
            color: #333;
            text-align: right;
            > li:first-child {
              font-size: 18px;
              font-weight: bold;
              vertical-align: bottom;
              display: inline-block;
            }
            > li:last-child {
              font-size: 23px;
              font-weight: bold;
              vertical-align: bottom;
              display: inline-block;
              margin-left: 2px;
            }
          }
          > ul:last-child {
            text-align: right;
            margin-top: 5px;
            span {
              font-weight: 600;
              color: #a2a2a2;
              text-decoration: line-through;
              text-decoration-thickness: 2px;
              margin-right: 1px;
            }
            @include new-mobile {
              font-size: 3.8vw;
            }
          }
        }

        .percent-circle {
          width: 60px;
          height: 60px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          right: -15px;
          top: -30px;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            color: white;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
