@import '../common/mixin';

.pwd-change-btn {
  margin-top: 60px;

  @include new-mobile {
    margin-top: 50px;
  }
}

.old-password-wrap {
  margin-bottom: 64px;
}
