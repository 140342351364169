@import '../common/mixin';
@import '../common/mixin_animation';

.curation-container {
  padding-bottom: 50px;
}

.curation-focus {
  margin-top: 40px;
  width: 100%;
  min-height: 110px;
  padding: 24px 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  letter-spacing: 0;

  h4 {
    font-family: 'GmarketSansBold';
    font-size: 12px;
    line-height: 14px;
    color: #6236ff;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    @include textEllipsis(auto, 2);
    word-break: keep-all;
    white-space: break-spaces;
  }
}

.curation-wrapper {
  @include wrapper(1128px);
  padding: 0 16px;
}

.curation-list {
  margin-top: 64px;

  @include new-mobile {
    display: block;
    margin: 0 auto 30px auto;
    max-width: 488px;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.curation-top-header {
  height: 560px;
  background-color: #28283d;
  position: relative;
  overflow: hidden;

  .td-wrapper-v2 {
    &.curation {
      position: relative;
    }
  }

  .title-wrap {
    position: absolute;
    left: 40px;
    top: 136px;
    z-index: 6;
    @include animation(fade-in-anim, 1.5s, 0s, ease-in-out, forwards);
    @include keyframe(fade-in-anim) {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    h2 {
      width: 100%;
      max-width: 550px;
      word-break: keep-all;
      @include font(40px, bold) {
        line-height: 48px;
        color: #ffffff;
        font-family: 'GmarketSansBold';
      }
      white-space: break-spaces;
    }

    p {
      @include font(24px, normal) {
        font-family: 'GmarketSansLight';
        line-height: 32px;
        color: #cccccc;
      }
      margin-top: 24px;
      white-space: pre-line;
    }
  }
}

.box-wrap {
  margin-top: 40px;
  @include flex(flex-start, center);

  li {
    background-color: #ffffff;
    padding: 15px 18px;
    border-radius: 8px;
    margin-right: 8px;
    @include font(14px, normal) {
      font-family: 'GmarketSansLight';
      line-height: 14px;
      color: #444444;
    }

    img {
      width: 16px;
      height: auto;
      vertical-align: -3px;
      margin-right: 4px;
    }

    span {
      font-family: 'GmarketSansBold';
    }

    &:last-child > img {
      width: 17px;
    }
  }
}

.bg-img-icon-wrap {
  position: relative;

  img {
    position: absolute;
    z-index: 1;
    opacity: 0;
    -webkit-animation:
      fade-in-anim 1.5s ease-in-out forwards,
      up-down-anim 1.5s ease-in-out infinite alternate;
    -moz-animation:
      fade-in-anim 1.5s ease-in-out forwards,
      up-down-anim 1.5s ease-in-out infinite alternate;
    -o-animation:
      fade-in-anim 1.5s ease-in-out forwards,
      up-down-anim 1.5s ease-in-out infinite alternate;
    animation:
      fade-in-anim 1.5s ease-in-out forwards,
      up-down-anim 1.5s ease-in-out infinite alternate;
    @include keyframe(fade-in-anim) {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @include keyframe(up-down-anim) {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-5px);
      }
    }

    &:nth-of-type(1) {
      width: 515px;
      height: 465px;
      left: 60%;
      top: 106px;
      margin-left: -7%;
      z-index: 1;
      -webkit-animation-delay: 0.2s;
      -moz-animation-delay: 0.2s;
      -o-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }

    &:nth-of-type(2) {
      width: 213px;
      height: 310px;
      left: 60%;
      top: 233px;
      margin-left: -8.5%;
      z-index: 2;
      -webkit-animation-delay: 0.4s;
      -moz-animation-delay: 0.4s;
      -o-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }

    &:nth-of-type(3) {
      width: 173px;
      height: 195px;
      left: 60%;
      top: 307px;
      margin-left: 28.5%;
      z-index: 3;
      -webkit-animation-delay: 0.6s;
      -moz-animation-delay: 0.6s;
      -o-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }

    &:nth-of-type(4) {
      width: 168px;
      height: 154px;
      left: 60%;
      top: 80px;
      margin-left: 16.5%;
      z-index: 4;
      -webkit-animation-delay: 0.8s;
      -moz-animation-delay: 0.8s;
      -o-animation-delay: 0.8s;
      animation-delay: 0.8s;
    }

    &:nth-of-type(5) {
      width: 246px;
      height: 258px;
      left: 0;
      top: 304px;
      z-index: 5;
      -webkit-animation-delay: 1s;
      -moz-animation-delay: 1s;
      -o-animation-delay: 1s;
      animation-delay: 1s;
    }
  }
}

@include mediaQuery(1200px) {
  .curation-top-header {
    .title-wrap {
      left: 24px;
    }
  }
}

@include mediaQuery(1024px) {
  .bg-img-icon-wrap {
    img:nth-of-type(1) {
      width: 415px;
      height: 365px;
      top: 205px;
    }

    img:nth-of-type(2) {
      width: 153px;
      height: 250px;
      top: 306px;
    }

    img:nth-of-type(3) {
      width: 123px;
      height: 145px;
      top: 377px;
      margin-left: 30%;
    }

    img:nth-of-type(4) {
      width: 118px;
      height: 104px;
      top: 190px;
      margin-left: 17.5%;
    }

    img:nth-of-type(5) {
      width: 176px;
      height: 188px;
      left: 16px;
      top: 365px;
    }
  }
}

@include tablet {
  .curation-top-header {
    height: 520px;

    .title-wrap {
      top: 80px;

      h2 {
        font-size: 32px;
        line-height: 40px;
        max-width: 320px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
  .box-wrap {
    margin-top: 32px;
  }

  .bg-img-icon-wrap {
    img:nth-of-type(1) {
      top: 165px;
    }

    img:nth-of-type(2) {
      top: 275px;
      margin-left: -9%;
    }

    img:nth-of-type(3) {
      margin-left: 38%;
    }

    img:nth-of-type(4) {
      top: 145px;
      margin-left: 26%;
    }

    img:nth-of-type(5) {
      top: 325px;
    }
  }
}

@include mediaQuery(600px) {
  .bg-img-icon-wrap {
    img:nth-of-type(5) {
      display: none;
    }
  }
}

@include mobile {
  .curation-top-header {
    .title-wrap {
      left: 16px;
      padding-right: 27px;
    }
  }

  .curation-list {
    margin-top: 72px;

    .curation-content-title {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .curation-focus {
    margin-top: 24px;
  }

  .bg-img-icon-wrap {
    img:nth-of-type(1) {
      width: 296px;
      height: 271px;
      left: 12.5%;
      top: 255px;
      margin-left: 0;
    }

    img:nth-of-type(2) {
      width: 123px;
      height: 180px;
      left: 10%;
      top: 328px;
      margin-left: 0;
    }

    img:nth-of-type(3) {
      width: 91px;
      height: 113px;
      left: 70%;
      top: 307px;
      margin-left: 0;
    }

    img:nth-of-type(4) {
      width: 98px;
      height: 90px;
      left: 50%;
      top: 240px;
      margin-left: 0;
    }
  }

  .box-wrap {
    li {
      padding: 12px 8px;
    }
  }
}
