@import '../common/mixin';

.mo-mypage-container {
  .favorite-list-wrap {
    padding: 16px;
  }

  .favorite-article {
    h4 {
      font-size: 16px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
}

@include tablet {
  .mo-mypage-container {
    .favorite-list-wrap {
      padding: 0;

      .my-course-item {
        margin-bottom: 8px;
        margin-top: 0;
      }
    }

    .favorite-article {
      margin-top: 16px;

      h4 {
        margin-left: 16px;
      }
    }

    .my-course-item-wrap {
      background-color: #f6f6f6;
    }

    .empty-list-wrap {
      background-color: #f6f6f6;
      padding: 40px 16px;
      margin-top: 0;

      img {
        width: 123px;
        height: 123px;
      }

      p {
        font-size: 14px;
        line-height: 14px;
        margin-top: 16px;
      }
    }
  }
}
