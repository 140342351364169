@import '../common/mixin';

.course-slide-list {
  margin-top: 42px;

  > p {
    @include font(16px, 400) {
      line-height: 24px;
      color: #888888;
    }
    margin-top: 6px;
  }

  .swiper-button-prev-unique,
  .swiper-button-next-unique {
    position: absolute;
    z-index: 2;
    right: -80px;
    top: 0;
    width: 64px;
    height: 47%;
    background-color: #f6f6f6;
    cursor: pointer;
    border-radius: 4px;

    &::before {
      display: block;
      -webkit-transform: translate(-45%, -50%) scale(1.2);
      -moz-transform: translate(-45%, -50%) scale(1.2);
      -o-transform: translate(-45%, -50%) scale(1.2);
      transform: translate(-45%, -50%) scale(1.2);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }

  .swiper-button-prev-unique {
    top: auto;
    bottom: 0;

    &::before {
      content: url('#{$img-root-url}/static/svg/common/course-slider-left-arrow.svg');
    }
  }

  .swiper-button-next-unique::before {
    content: url('#{$img-root-url}/static/svg/common/course-slider-right-arrow.svg');
  }

  .swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

@include tablet {
  .course-slide-list {
    margin-top: 66px;
  }
}

@include mobile {
  .td-container {
    &.main-container {
      padding-bottom: 0px;
    }
  }
  .course-slide-list {
    margin-top: 44px;
  }
}
