@charset "utf-8";
@import '../common/mixin';

.sign-up-wrap {
  @include wrapper(540px);

  .agree-check-wrap {
    margin-top: 68px;
    height: 77px;

    .agree-icon {
      @include size(20px, 20px);
      margin-right: 14px;
      border: 1px solid #999999;
      border-radius: 2px;

      &:focus {
        color: $black-color;
        border: 1px solid $black-color;
      }

      &.error {
        border-color: #ff2828;
      }
    }

    li {
      @include flex(flex-start, center);
      @include font(14px, 400) {
        line-height: 16px;
        color: $black-color;
      }
      cursor: pointer;

      &:first-child {
        margin-bottom: 17px;
      }

      img {
        @include size(20px, 20px);
        vertical-align: bottom;
        margin-right: 14px;
      }

      a {
        border-bottom: 1px solid $black-color;
      }
    }
  }

  .login-btn {
    margin-top: 20px;

    button {
      width: 100%;
      padding: 16px 0;
      @include font(14px, 700) {
        color: #ffffff;
        line-height: 17px;
      }
      border-radius: 2px;
      background-color: $main-color-v2;
    }
  }

  &.template-input-wrap {
    padding-bottom: 100px;

    @include new-mobile {
      padding: 80px 16px 110px;
    }
  }
}
