@charset "utf-8";
@import '../../scss/common/mixin';

.survey-container {
  height: 100vh;
  max-width: 100%;

  .survey-form {
    height: 100%;
  }
}
