@import '../common/mixin';

/* 추후 수강후기 대댓글 ui 변경에 대응해야함 */
.review-re-comment-card {
  padding-left: 48px;
  position: relative;
  margin-top: 16px;

  .re-comment-icon {
    display: inline-block !important;
    position: absolute;
    width: 15px;
    height: 12px;
    left: 24px;
    top: 14px;
  }

  .comment-edit {
    background-color: #ffffff;
    margin-top: 6px !important;

    .write-wrap {
      padding-left: 0;
    }

    .star-rating {
      justify-content: center !important;
    }
  }

  .comment-card-wrap {
    padding: 0;
  }

  .user-profile-img {
    top: 0;
    left: 0;
  }
}
