@charset "utf-8";
@import '../common/mixin';

.viewer-container {
  min-height: 100vh;
  padding: {
    top: 36px;
    bottom: 40px;
  }

  .td-wrapper {
    margin: 30px auto 0;
    @include flex(space-between, flex-start);
  }

  .modal-content {
    max-height: inherit;
  }

  .modal-section {
    overflow-y: hidden;
    max-height: inherit;

    &.simple-section {
      padding-bottom: 80px !important;

      h4:first-child {
        margin-top: 10px;
      }
    }
  }

  .modal-section-wrap {
    max-height: calc(500px - 96px);
    overflow-y: auto;
    margin-top: 20px;
    height: 100%;
  }

  .td-top-btn {
    bottom: 68px;
    right: 10px;
    z-index: 4;

    &.qna {
      bottom: 30px;
    }
  }
}

.video-wrapper {
  width: 65%;
  padding-right: 2%;
  flex-shrink: 0;

  .video-title-wrap {
    margin-top: 9px;
    @include flex(space-between, flex-start);

    p {
      @include font(17px, 700) {
        line-height: 20px;
      }
      padding-right: 10px;
    }

    a {
      display: block;
      flex-shrink: 0;
      width: 96px;
      padding: 3px 12px;
      background-color: #242424;
      border-radius: 2px;
      @include font(13px, 400) {
        line-height: 16px;
        color: #ffffff;
      }
      text-align: center;

      img {
        width: 24px;
        height: auto;
        vertical-align: -7px;
        margin-left: 2px;
      }
    }
  }

  .video-error-resolution {
    @include flex(flex-end, center);
    margin-top: 6px;

    img {
      width: 12px;
      height: auto;
      margin: {
        top: 1px;
        right: 3px;
      }
      opacity: 0.3;
    }

    a {
      @include font(12px, 300) {
        color: #cccccc;
        line-height: 16px;
      }
    }
  }

  .subject-info-wrapper {
    margin-top: 20px;
    position: relative;

    h3 {
      @include font(19px, 700) {
        line-height: 22px;
      }
      margin-top: 4px;
      word-break: keep-all;
    }

    .view-more-description {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px 0;

      p {
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        padding-left: 8px;
      }
    }
  }

  .subject-description {
    margin-top: 8px;
    line-height: 22px;

    &.folded {
      height: 65px;
      overflow: hidden;
    }

    * {
      @include font(14px, 300) {
        line-height: 22px;
        color: #555555;
        word-break: break-all;
      }
    }
  }
}

.video {
  position: relative;
  margin-top: 20px;
  aspect-ratio: 16/9;
  background-color: #000000;

  .video-action-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;

    .back-to-current-time-btn {
      display: none;
      padding: 4px;
      width: 199px;
      height: 36px;
      background: #242424;
      color: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.8;
      border-radius: 4px;
      align-items: center;
      justify-content: space-evenly;

      &.show {
        position: relative;
        display: flex;
        top: 6px;
        left: 7px;
        z-index: 13;
        pointer-events: visible;
      }

      img {
        width: 24px;
      }

      p {
        font-size: 13px;
      }
    }

    .video-load-failed {
      height: 100%;
      background: -webkit-linear-gradient(to bottom, #333333 1%, #636363 100%);
      background: -moz-linear-gradient(to bottom, #333333 1%, #636363 100%);
      background: -o-linear-gradient(to bottom, #333333 1%, #636363 100%);
      background: linear-gradient(to bottom, #333333 1%, #636363 100%);
      @include flex(center, center) {
        flex-direction: column;
      }
      color: #ffffff;

      h3 {
        @include font(26px, 700) {
          line-height: 40px;
        }
      }

      p {
        @include font(16px, 300) {
          line-height: 20px;
        }
        margin-top: 10px;
      }
    }

    .video-action-btn {
      position: absolute;
      width: 130px;
      height: 130px;
      z-index: 11;
      pointer-events: visible;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      .action-btn {
        display: none;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 12;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        &::before {
          content: '';
          display: block;
          width: 64px;
          height: 64px;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 13;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }

      &.prev {
        left: 25px;

        .action-btn {
          &::before {
            @include background(
              '#{$img-root-url}/static/svg/viewer/replay_10.svg',
              center,
              center,
              cover
            );
          }
        }

        &.hover {
          .action-btn {
            display: block;
          }
        }
      }

      &.next {
        right: 25px;

        .action-btn {
          &::before {
            @include background(
              '#{$img-root-url}/static/svg/viewer/forward_10.svg',
              center,
              center,
              cover
            );
          }
        }

        &.hover {
          .action-btn {
            display: block;
          }
        }
      }
    }
  }
}

.autoplay-switch-wrap {
  @include flex(flex-start, center);

  input[type='checkbox'] {
    width: 30px;
    height: 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: block;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    border: 1px solid #dddddd;

    &::before {
      content: '';
      display: block;
      letter-spacing: -1px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #dddddd;
      position: absolute;
      left: 0;
      top: -4px;
      -webkit-transition: left 0.2s ease;
      -moz-transition: left 0.2s ease;
      -o-transition: left 0.2s ease;
      transition: left 0.2s ease;
    }

    &:checked {
      background-color: #b5b4ff;
      border-color: #b5b4ff;
    }

    &:checked::before {
      background-color: #4745ff;
      left: 12px;
    }
  }

  label {
    margin-right: 8px;
    @include font(12px, 300) {
      color: #595959;
      line-height: 16px;
    }
  }
}

.subject-wrapper {
  width: 35%;
  background-color: #ffffff;
  flex-shrink: 0;

  &.sticky {
    position: sticky;
    top: 16px;
    right: 0;
  }
}

.subject-wrapper-top {
  @include flex(space-between, center);
  padding: 16px 12px 15px;
  border-radius: 2px;
  background: #f4f4f4;

  .subject-info-wrap {
    display: flex;

    .total-video-count {
      @include font(12px, 300) {
        color: #727272;
        line-height: 16px;
        padding-right: 16px;
      }
    }

    .attendance_rate {
      font-size: 13px;
      line-height: 16px;
      color: #595959;
    }
  }
}

@include new-mobile {
  .subject-wrapper-top {
    background-color: #ffffff;
    padding: 0 0 16px;
  }
}

.subject-list {
  margin-top: 8px;

  li {
    border-top: 1px solid #eaeaea;
    @include flex(flex-start, center);
    padding: 8px;
    position: relative;
    cursor: pointer;
    border-radius: 4px;

    &:first-child {
      border-top: none;
    }

    &.active {
      background-color: $main-color;

      .current-playing-video-wrap {
        img {
          width: 20px;
          height: auto;
          vertical-align: -4px;
          margin-right: 4px;
        }

        .video-viewed-icon {
          margin-right: 0;
        }

        span {
          @include font(14px, 700) {
            line-height: 18px;
            color: #ffffff;
          }
        }
      }

      .subject-index {
        background-color: $main-color;
        color: #ffffff;
      }
    }

    .video-viewed-icon {
      position: absolute;
      right: 8px;
      width: 18px;
      height: auto;
    }
  }

  .subject-index {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    @include font(12px, 300) {
      line-height: 36px;
      color: #727272;
    }
    text-align: center;
  }

  .subject-title-wrap {
    margin-left: 4px;
    width: 85%;

    * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.is-viewed {
      width: 73%;
    }

    p {
      @include font(12px, 300) {
        line-height: 12px;
      }
    }

    h4 {
      @include font(14px, 700) {
        line-height: 14px;
      }
      margin-top: 4px;
    }
  }
}

.subject-pagenation {
  margin-top: 35px;
  margin-bottom: 12px;
  text-align: center;

  span {
    margin-right: 16px;
    @include font(15px, 400) {
      line-height: 15px;
      color: #bbbbbb;
    }
    cursor: pointer;

    &.selected {
      color: $main-color;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.bottom-viewer-btn {
  display: none;
}

.video-buffer-wrap {
  position: absolute;
  width: 45%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  bottom: 14%;
  margin: auto;
  padding: 24px 0;
  z-index: 1000;
  text-align: center;

  h4 {
    @include font(22px, 700) {
      line-height: 22px;
      color: #ffffff;
    }
    position: relative;

    img {
      vertical-align: -5px;
      width: 26px;
      margin-right: 4px;
      height: auto;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 3px;
      background-color: #aaaaaa;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -20px;
    }
  }

  .video-solution-btn {
    width: 50%;
    margin: 35px auto 0;
    display: block;
    @include font(16px, 400) {
      line-height: 16px;
      color: #ffffff;
    }
  }
}

@include mediaQuery(1050px) {
  .video-buffer-wrap {
    width: 54%;
    padding: 16px 0;

    h4 {
      font-size: 18px;
    }

    .video-solution-btn {
      font-size: 14px;
    }
  }
}

@include tablet {
  .subject-list {
    .subject-title-wrap {
      width: 90%;

      &.is-viewed {
        width: 93%;
      }
    }
  }
  .fixed-area-line {
    position: absolute;
    top: 0;
  }
  .viewer-container {
    padding: 48px 0;

    .td-wrapper {
      display: block;
      padding: 0;
      margin-top: 0;
    }
  }

  .video-wrapper {
    width: 100%;
    min-height: 100%;
    padding: 0;

    .video {
      width: 100%;
      margin-top: 0;
      min-height: calc((100vw * 9 / 16) - 17px);
      z-index: 3;

      &.sticky {
        position: sticky;
        right: 0;
        top: 48px;
      }
    }

    .video-title-wrap {
      display: none;
    }

    .video-error-resolution {
      display: none;
    }

    .subject-info-wrapper {
      padding: 0 18px 24px;

      h3 {
        width: 100%;
        font-size: 17px;
        display: inline-block;
        word-break: keep-all;
      }

      .secret-note-dw-btn {
        padding: 3px 12px;
        background-color: #242424;
        border-radius: 2px;
        width: 96px;
        position: absolute;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: #ffffff;
        right: 16px;
      }

      .mo-secret-note-down {
        display: flex;
        width: 100%;
        font-size: 13px;
        line-height: 16px;
        padding: 12px;
        margin: 16px 0 0;
        background: #f2f2ff;
        border-radius: 4px;
        align-items: center;
        justify-content: space-between;
      }

      img {
        width: 24px;
        height: auto;
        vertical-align: -7px;
        margin-left: 2px;
      }
    }
  }

  .shuffle-btn-wrap {
    display: flex;
    justify-content: center;
    z-index: 3;
    background-color: #ffffff;
    border-top: #f4f4f4 1px solid;
    position: sticky;
    top: 40px;

    button {
      font-size: 15px;
      width: 50%;
      padding: 16px;
      border-bottom: #ffffff 2px solid;
      color: #595959;

      &.selected {
        border-bottom: #4745ff 2px solid;
        font-weight: bold;
        color: #242424;
      }
    }
  }

  .slide-wrap {
    padding: 16px;
  }

  .subject-wrapper {
    width: 100%;
    display: block;

    &.sticky {
      position: relative;
      top: 0;
    }
  }
  .subject-list {
    margin-top: 0;

    li {
      border-radius: 0;
      margin: 0 -15px;
    }
  }

  .bottom-viewer-btn {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 1003;
    bottom: -2px;

    button {
      background: white;
      font-size: 15px;
      line-height: 45px;
      border: 1px solid #d1d1d1;
      width: 50%;

      &.active {
        background: $main-color;
        color: white;
        font-weight: bold;
      }

      &.disabled {
        pointer-events: none;
        color: #d1d1d1;
      }
    }
  }
}

@include mediaQuery(550px) {
  .video-buffer-wrap {
    width: 60%;
    padding: 0;

    .mo-video-solution-btn {
      display: block;
      padding: 12px 0;
      @include font(16px, 700) {
        line-height: 22px;
        color: #ffffff;
      }

      img {
        vertical-align: -5px;
        width: 21px;
        margin-right: 4px;
        height: auto;
      }
    }
  }
}

@include mobile {
  .viewer-container {
    .modal-section {
      &.simple-section {
        padding: 24px !important;
        min-height: inherit;
        height: inherit;

        h4:first-child {
          margin-top: 10px;
        }
      }
    }

    .modal-section-wrap {
      max-height: calc(100% - 96px);
    }
  }

  .video-buffer-wrap {
    .mo-video-solution-btn {
      padding: 10px 0;
      font-size: 14px;

      img {
        width: 19px;
      }
    }
  }

  .subject-list {
    .subject-title-wrap {
      width: 86%;
      &.is-viewed {
        width: 88%;
      }
    }
  }
}
