@import 'mixin';

.hdr {
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all ease-in-out 0.1s;

  .td-wrapper-v2 {
    &.gnb {
      height: 100%;
    }
  }

  &.transparency-type {
    background-color: transparent;

    h1 {
      a {
        @include background(
          '#{$img-root-url}/static/v2/svg/common/bearu_logo_white.svg',
          center,
          center,
          cover
        );
      }
    }

    .left-wrap {
      .event-btn {
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_event_w.svg',
          center,
          center,
          cover
        );
      }

      .nav-menu {
        li {
          color: #ffffff;

          @include new-tablet {
            font-size: 10px;
          }

          &.superz-category {
            b {
              color: #fff;
            }
          }
        }
      }
    }

    .search-bar {
      button {
        background-image: url('#{$img-root-url}/static/v2/svg/common/ic_search_b_w.svg') !important;
      }

      input {
        color: #ffffff !important;
        border-bottom: none !important;
      }
    }

    .noti-btn {
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_alarm_w.svg',
        center,
        center,
        cover
      );

      @include new-mobile {
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_alarm_w_m.svg',
          center,
          center,
          cover
        );
      }
    }

    .login-btn {
      button {
        color: #ffffff !important;
      }
    }
  }

  &.transparent-type {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 0;

    .gnb {
      padding: 29px 0;
      @include flex(flex-start, center);
    }

    a {
      width: 23px;
      height: 22px;

      &.master {
        @include background(
          '#{$img-root-url}/static/svg/master_apply/icon-home-master.svg',
          center,
          center,
          cover
        );
      }

      &.curation {
        @include background(
          '#{$img-root-url}/static/png/common/bearu-home-icon-white.png',
          center,
          center,
          cover
        );
      }
    }

    img {
      width: 76px;
      height: 19px;
      margin-left: 4px;
    }
  }

  &.viewer-type {
    height: 64px;

    @include new-mobile {
      height: 48px;
    }

    .td-wrapper-v2 {
      &.gnb {
        max-width: 1280px;
        height: 100%;
      }
    }

    .nav-menu {
      display: none !important;
    }

    .search-bar {
      display: none;
    }

    .noti-btn {
      display: none;
    }
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    @include font(20px, 700) {
      line-height: 22px;
      color: #ffffff;
    }
    margin-left: 16px;
  }

  h1 {
    a {
      display: block;
      width: 76px;
      height: 17px;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/bearu_logo_black.svg',
        center,
        center,
        cover
      );
    }
  }

  .gnb {
    padding: 13px 0;
  }

  .pc-visible {
    @include flex(space-between, center);
  }

  .left-wrap {
    width: 65%;
    @include flex(flex-start, center);

    @include new-mobile {
      width: 50%;
    }

    .event-btn {
      width: 24px;
      height: 24px;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_event_dg.svg',
        center,
        center,
        cover
      );
    }

    .nav-menu {
      width: max-content;

      @include flex(flex-start, center);
      & > li {
        margin-left: 50px;
        height: 37px;

        @include new-tablet {
          margin-left: 30px;
        }
      }
      li {
        @include font(16px, 700) {
          line-height: 37px;
          color: #242424;
        }

        &:hover {
          color: #4745ff;
        }

        &.class-category {
          position: relative;

          @include mediaQuery(780px) {
            margin-left: 30px;
          }

          .category-list-container {
            position: absolute;
            padding: 16px 20px;
            width: max-content;
            @include flex(flex-start, flex-start) {
              flex-direction: column;
            }
            top: 36px;
            left: -10px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

            li {
              @include font(14px, 400) {
                line-height: 14px;
                color: #242424;
              }
              margin-bottom: 24px;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                font-weight: bold;
                color: #4745ff;
              }
            }
          }
        }

        &.ebook-category {
          margin-left: 50px;
          height: 37px;

          @include new-tablet {
            margin-left: 30px;
          }
        }

        &.superz-category {
          b {
            font-weight: 700;
            color: #005bff;
          }
        }

        &.event-category {
          position: relative;

          .event-list-container {
            position: absolute;
            padding: 16px 20px;
            width: 250px;
            @include flex(flex-start, flex-start) {
              flex-direction: column;
            }
            top: 36px;
            left: -10px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

            li {
              position: relative;
              width: 100%;
              display: flex;
              margin-bottom: 24px;
              @include font(14px, 400) {
                line-height: 14px;
                color: #242424;
              }
              @include textEllipsis(14px, 1) {
              }
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                padding-right: 30px;
                font-weight: bold;
                color: #4745ff;

                .direction {
                  position: absolute;
                  top: -6px;
                  right: 0;
                  width: 24px;
                  height: 24px;
                  @include background(
                    '#{$img-root-url}/static/v2/svg/common/ic_arrow_r_bl_03.svg',
                    center,
                    center,
                    cover
                  );
                }
              }
            }
          }
        }
        &.b2b-category {
          position: relative;

          .event-list-container {
            position: absolute;
            padding: 16px 20px;
            width: 250px;
            @include flex(flex-start, flex-start) {
              flex-direction: column;
            }
            top: 36px;
            left: -10px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

            li {
              position: relative;
              width: 100%;
              display: flex;
              margin-bottom: 24px;
              @include font(14px, 400) {
                line-height: 14px;
                color: #242424;
              }
              @include textEllipsis(14px, 1) {
              }
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                padding-right: 30px;
                font-weight: bold;
                color: #4745ff;

                .direction {
                  position: absolute;
                  top: -6px;
                  right: 0;
                  width: 24px;
                  height: 24px;
                  @include background(
                    '#{$img-root-url}/static/v2/svg/common/ic_arrow_r_bl_03.svg',
                    center,
                    center,
                    cover
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  .noti-btn {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_alarm_g.svg',
      center,
      center,
      cover
    );

    @include new-mobile {
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_alarm_dg_m.svg',
        center,
        center,
        cover
      );
    }

    .new-notice {
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      top: 0;
      right: 0;
      background-color: #4745ff;
    }
  }

  .right-wrap {
    width: 50%;
    @include flex(flex-end, center);

    .login-btn {
      button {
        @include font(16px, 700) {
          line-height: 18px;
          color: #595959;
        }

        &:hover {
          color: #4745ff;
        }
      }
    }

    .search-bar {
      position: relative;
      max-width: 280px;
      width: 58%;
      margin-right: 14px;

      button {
        position: absolute;
        top: 50%;
        right: 10px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: top;
        cursor: pointer;
        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_search_b.svg',
          center,
          center,
          cover
        );
      }

      input {
        display: block;
        width: 100%;
        height: 37px;
        @include font(14px, 400) {
          line-height: 14px;
        }
        border-bottom: 1px solid #999999;
        padding: 0 40px 0 10px;
      }
    }

    & > * {
      flex-shrink: 0;
    }
  }

  .user-profile-btn {
    width: 24px;
    height: 24px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 50%;

    div {
      width: 100%;
      height: 100%;
    }
  }
}

@include desktop {
  .hdr {
    .gnb {
      padding: {
        top: 12px;
        bottom: 12px;
        left: 16px !important;
        right: 16px !important;
      }
    }
  }
}

@include new-mobile {
  .hdr {
    height: 48px;
    border-bottom: none;

    .gnb {
      padding: 0 !important;
    }

    h1 {
      a {
        width: 76px;
        height: 17px;
        @include background(
          '#{$img-root-url}/static/png/common/mo_logo_white.png',
          center,
          center,
          cover
        );
      }
    }

    .home-btn {
      position: absolute;
      right: 16px;
      width: 30px;
      height: 30px;
      display: block;
      @include background(
        '#{$img-root-url}/static/v2/svg/common/ic_home_dg.svg',
        center,
        center,
        contain
      );
    }

    .search-btn {
      display: block;
      width: 21px;
      height: 22px;
    }

    .mo-visible {
      @include flex(space-between, center);
      padding: 13px 16px 12px;
      height: 46px;

      .search-btn {
        margin-left: 0;
        opacity: 1;
      }

      &.mo-main {
        .event-btn {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_event_w.svg',
            center,
            center,
            cover
          );
        }

        .noti-btn {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_alarm_w_m.svg',
            center,
            center,
            cover
          );
        }

        .search-btn {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_search_w_m.svg',
            center,
            center,
            cover
          );
        }
      }

      &.mo-class {
        background-color: #ffffff;

        h1 {
          a {
            @include background(
              '#{$img-root-url}/static/v2/svg/common/bearu_logo_black.svg',
              center,
              center,
              cover
            );
          }
        }

        .search-btn {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_search_dg_m.svg',
            center,
            center,
            cover
          );
        }
      }

      &.mo-fixed {
        width: 100%;
        position: relative;
        background-color: #ffffff;

        h1 {
          a {
            @include background(
              '#{$img-root-url}/static/v2/svg/common/bearu_logo_black.svg',
              center,
              center,
              cover
            );
          }
        }

        .search-btn {
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_search_dg_m.svg',
            center,
            center,
            cover
          );
        }
      }
    }

    &.transparency-type {
      .mo-sub {
        background-color: #1e1e1e;

        h3 {
          color: #ffffff;
        }

        .back-btn {
          background: url('#{$img-root-url}/static/v2/svg/common/ic_back_m_w.svg') !important;
        }

        .search-btn {
          background: url('#{$img-root-url}/static/v2/svg/common/ic_search_w_m.svg') !important;
        }
      }
    }

    .mo-sub {
      position: relative;
      width: 100%;
      height: 48px;
      background-color: #ffffff;

      .back-btn-wrapper {
        display: flex;
        justify-content: center;
        height: 100%;

        .back-btn {
          width: 24px;
          height: 24px;
          cursor: pointer;
          @include background(
            '#{$img-root-url}/static/v2/svg/search/ic_back_m.svg',
            center,
            center,
            cover
          );
        }
      }

      .search-btn-wrapper {
        display: flex;
        justify-content: center;
        height: 100%;

        .search-btn {
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: top;
          cursor: pointer;
          @include background(
            '#{$img-root-url}/static/v2/svg/search/ic_menu_search.svg',
            center,
            center,
            cover
          );
        }
      }

      .home-btn-wrapper {
        display: flex;
        justify-content: center;
        width: 10%;
        height: 100%;

        .home-btn {
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: top;
          cursor: pointer;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_home_dg.svg',
            center,
            center,
            contain
          );
        }
      }

      h3 {
        @include font(16px, 400) {
          line-height: 48px;
        }
        text-align: center;
        width: 100%;
        min-width: 200px;
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .mo-search {
      padding: 0;
      position: fixed;
      width: 100%;
      height: 48px;
      background-color: #ffffff;

      @include new-mobile {
        position: relative;
      }

      .back-btn-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        width: 10%;
        height: 100%;

        .back-btn {
          width: 24px;
          height: 24px;
          cursor: pointer;
          @include background(
            '#{$img-root-url}/static/v2/svg/search/ic_back_m.svg',
            center,
            center,
            cover
          );
        }
      }

      .mobile-search-bar {
        width: 100%;
        min-width: 200px;
        margin: 0 auto;

        input {
          display: block;
          width: 100%;
          height: 30px;
          @include font(15px, 400) {
            line-height: 15px;
          }
          border-bottom: 1px solid #999999;
          padding: 0 10px;
        }
      }

      .search-btn-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        width: 10%;
        height: 100%;

        .mobile-search-btn {
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: top;
          cursor: pointer;
          @include background(
            '#{$img-root-url}/static/v2/svg/search/ic_menu_search.svg',
            center,
            center,
            cover
          );
        }
      }
    }

    &.transparent-type {
      .gnb {
        padding: 13px 16px !important;
      }

      h3 {
        display: none;
      }
    }
  }
}
