@import '../common/mixin';

.category-nav-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 40px 0px 30px;
  padding: 0px 20px;

  @include new-mobile {
    margin-top: 1vh;
    margin-bottom: 10px;
  }
}

.category-nav-button {
  position: absolute;
  bottom: 30px;
  z-index: 1;
  background: white;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
    filter: invert(50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.category-nav-list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
  overflow: hidden;

  @include new-mobile {
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.tablet-or-mobile {
    gap: 15px;
    overflow-x: auto;
  }

  &.desktop {
    gap: 35px;
  }
}

.category-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  transform: scale(0.9);

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  img {
    width: 50px;
  }

  span {
    color: gray;
    font-size: 9px;
    font-weight: normal;
  }

  &.desktop {
    img {
      width: 70px;
    }

    span {
      font-size: 13px;
    }
  }

  &.visible span {
    color: inherit;
    font-weight: bold;
  }

  &.visible {
    transform: scale(1.1);
    width: 85px;
    height: 110px;
  }
}
