@import '../common/mixin';

.mo-mypage-container {
  .my-course-item {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    width: 100%;
    max-width: inherit;
    margin-top: 8px;
    padding: 14px 16px 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .course-image-wrap {
    max-width: 56px;
    margin-right: 8px;

    .course-image {
      padding: 0;
      border-radius: 2px;
      height: 36px;
    }

    .certificate-badge {
      width: 32px;
      height: 14px;
      line-height: 12px;
      font-size: 8px;
      letter-spacing: -1px;
    }
  }

  .my-course-list-info {
    justify-content: flex-start;
    position: relative;
    margin-bottom: 14px;

    .course-note-down-btn {
      position: absolute;
      top: 50%;
      opacity: 0.3;
      pointer-events: none;
      right: 0;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      &.active {
        pointer-events: inherit;
        cursor: pointer;
        opacity: 1;
      }

      img {
        width: 13px;
        height: 13px;
        display: block;
        margin: 0 auto 3px;
      }

      @include font(10px, 700) {
        line-height: 10px;
        color: $main-color;
      }
    }
  }

  .title-wrap {
    width: 80%;
    padding-right: 40px;

    a {
      font-size: 14px;
      line-height: 1.29;
      @include textEllipsis(36px, 2);
    }
  }
}

.card-bottom {
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  @include flex(space-between, center);

  .left-wrap {
    @include flex(flex-start, center);
  }

  .certificate-down-btn {
    margin-left: 6px;
    background-color: #eeeeee;
    border-radius: 12px;
    padding: 1px 6px 2px;

    img {
      width: 10px;
      height: 12px;
      vertical-align: -2px;
    }

    span {
      @include font(12px, 400) {
        line-height: 18px;
        color: #666666;
      }
      margin-left: 4px;
    }
  }

  .rental-expire-time {
    span {
      font-size: 12px;
      color: #888888;
      line-height: 1;
      display: block;
    }
  }

  .rental-extend-btn {
    @include font(12px, 700) {
      line-height: 1;
      color: #888888;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 6px;
      @include background(
        '#{$img-root-url}/static/svg/common/right-arrow.svg',
        center,
        center,
        cover
      );
    }

    &.mo-favorite {
      color: $main-color;

      &::after {
        background-image: url('#{$img-root-url}/static/svg/common/right-arrow-point.svg');
      }
    }
  }
}

.course-recommend-wrap {
  padding: 32px 16px 0;
}

.mo-mypage-container {
  .my-coupon-list {
    margin-top: 0;
  }

  .coupon-info {
    padding: 16px;
    background-color: #ffffff;

    .use-status-unlimit {
      width: 77px;
      height: 77px;
      right: 16px;
      font-size: 12px;
      line-height: 17px;

      span {
        font-size: 18px;
        margin-right: 3px;
      }
    }

    h4 {
      font-size: 28px;
      line-height: 41px;
      padding-right: 100px;
    }

    .use-status {
      width: 77px;
      height: 77px;
      right: 16px;
      font-size: 14px;
      line-height: 77px;
    }
  }

  .coupon-info-status {
    background-color: #ffffff;
    padding: 12px 16px;

    p {
      font-size: 12px;
    }
  }

  .coupon-item {
    margin-top: 0;
    border: none;
    border-radius: 0px;
    background-color: #f6f6f6;
    padding-bottom: 8px;
  }
}
