@import '../common/mixin';
@import '../common/mixin_animation';

/* 모달창의 기본 스타일 지정 */
.td-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  &.w640-modal {
    .td-dimmed {
      background-color: rgba(0, 0, 0, 0.88);
    }

    .modal-content {
      overflow-y: visible;
      max-width: 640px;
      max-height: inherit;
      height: initial;
      background-color: transparent;
    }
  }
}

.purchase-notice-modal {
  text-align: center;

  @include new-mobile {
    @include animation(show-up-anim, 300ms, 1ms, ease-out, forwards);
    @include keyframe(show-up-anim) {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .modal-content {
    @include size(328px, 360px);
    border-radius: 10px;
  }

  .modal-close-btn {
    @include size(24px, 24px);
  }

  .modal-section {
    border-radius: 10px;
    height: 360px;

    h4 {
      @include new-mobile {
        justify-content: center !important;
      }
    }
  }

  .modal-section-wrap {
    width: 280px;

    .icon {
      margin: 16px auto 0;
      @include size(120px, 120px);
      @include background(
        '#{$img-root-url}/static/v2/svg/common/logo_bear_b4.svg',
        center,
        center,
        cover
      );
    }

    .content-title {
      margin-top: 16px;
      @include font(19px, 700) {
        color: $black-color;
        line-height: 24px;
      }
    }

    .content {
      margin-top: 12px;
      @include font(16px, 400) {
        color: #595959;
        line-height: 26px;
      }
      white-space: break-spaces;
    }
  }
}

.td-dimmed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.td-dimmed.no-background {
  background-color: initial;
}

.modal-content {
  width: 100%;
  max-width: 480px;
  max-height: 500px;
  background-color: #ffffff;
  position: absolute;
  z-index: 1002;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modal-header {
  width: 100%;
  height: 48px;
  background-color: #666666;
  position: relative;

  h3 {
    @include font(14px, 400) {
      line-height: 48px;
      color: #ffffff;
      margin-top: 0;
    }
    text-align: center;
  }
}

.modal-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.modal-section {
  background-color: #ffffff;
  position: relative;
  /* min-height: 393px; */
  min-height: 313px;
  overflow-y: auto;
  border-top: 1px solid #eaeaea;

  h3 {
    @include font(18px !important, normal !important) {
      line-height: 21px !important;
      color: $main-color !important;
    }
    text-align: center !important;
  }

  h4 {
    @include font(16px, 700) {
      line-height: 16px;
      color: #888888;
    }
  }

  &.simple-section {
    padding: 24px !important;
    min-height: 100px;

    .modal-close-btn {
      top: 24px;
      right: 24px;
    }

    .coupon-class-type {
      margin-top: 16px;

      h5 {
        @include font(14px, 500) {
          line-height: 1.47;
        }
      }

      &.no-extension {
        padding-top: 30px;
        color: #999999;
      }
    }

    h4 {
      @include font(16px, 700) {
        line-height: 1.33;
        color: #333333;
      }
      margin-top: 30px;
    }

    p {
      @include font(14px, 400) {
        line-height: 1.47;
      }
      margin-top: 12px;

      &.coupon-apply-target-title {
        margin-top: 4px;
        color: #666666;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .read-notice-btn {
    position: relative;
    margin-top: 10px;
    margin-left: auto;
    right: 0px;
    width: 100px;
    padding: 5px 10px;
    @include font(14px, 400) {
      line-height: 1.5;
      color: #888888;
    }
    border: 1px solid #dddddd;
    border-radius: 4px;
    cursor: pointer;
  }
}

.modal-tab-btn {
  @include flex(flex-start, center);

  li {
    width: 50%;
    height: 56px;
    background-color: #eaeaea;
    text-align: center;
    @include font(16px, 700) {
      line-height: 56px;
      color: #888888;
    }
    cursor: pointer;

    &.selected {
      background-color: #ffffff;
      color: $main-color;
    }
  }
}

.modal-tab-label {
  padding: 16px;

  input {
    width: 100%;
    border: solid 1px #eaeaea;
    @include font(14px, 500) {
      line-height: 54px;
    }
    padding: 0 16px;
  }

  p {
    @include font(12px, 400) {
      line-height: 1.67;
      color: #999999;
    }
    margin-top: 12px;
    white-space: pre-line;
    padding-bottom: 160px;
  }
}

.profile-edit-wrap {
  .user-profile-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin: 40px auto 0;
  }

  .control-btn-group {
    margin-top: 40px;
    padding: 0 16px;
    @include flex(space-between, flex-start);

    li {
      width: 49%;
      text-align: center;
      border: 1px solid #eaeaea;
      cursor: pointer;

      > div {
        height: 86px;
        @include flex(center, center);
      }

      h6 {
        background-color: #f9f9f9;
        @include font(14px, 700) {
          line-height: 42px;
          color: #999999;
        }
      }
    }
  }
}

.modal-button-container {
  margin-top: 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 16px;
  background-color: #ffffff;
}

.modal-section-list {
  height: 41vh;
  overflow-y: scroll;
}

/* 패키지 상세 페이지, 미리보기 팝업창의 닫기 버튼 */
.preview-close-btn {
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: -60px;
  width: 40px;
  height: 40px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);

  img {
    width: 100%;
    height: auto;
  }
}

@include mobile {
  .modal-content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    max-width: initial;
    max-height: initial;
  }

  .modal-section {
    width: 100%;
    height: calc(100% - 48px);
    padding: 0 !important;
  }

  .modal-section-list {
    height: auto;
    overflow-y: scroll;
  }

  .modal-section h4 {
    left: 16px;
    top: 16px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
