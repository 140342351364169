@import '../common/mixin';

.text-input {
  margin-top: 20px;
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  label {
    display: block;
    @include font(12px, 300) {
      line-height: 16px;
      color: #666666;
    }
  }

  input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #d2d2d2;
    @include font(14px, 300) {
      line-height: 20px;
    }
    margin-top: 6px;

    &:first-child {
      margin-top: 0;
    }

    &.has-confirm-btn {
      padding-right: 80px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  button {
    @include font(12px, 300) {
      color: #666666;
    }
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}
