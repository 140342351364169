@import '../common/mixin';

.star-rating {
  @include flex(space-between, center) {
    display: inline-flex;
  }

  li {
    width: 14px;
    height: 13px;
    margin-right: 4px;
    background-color: yellow;
    @include background(
      '#{$img-root-url}/static/svg/detail/product-star-default.svg',
      center,
      center,
      cover
    );

    &.on {
      @include background(
        '#{$img-root-url}/static/svg/detail/product-star-active.svg',
        center,
        center,
        cover
      );
    }

    &.half {
      -webkit-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -o-transform: scale(1.25);
      transform: scale(1.25);
      @include background(
        '#{$img-root-url}/static/svg/detail/half-star.svg',
        center,
        center,
        cover
      );
    }
  }
}
