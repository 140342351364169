@import '../common/mixin';

/* 추후 메인 수강후기 ui 변경에 대응해야함 */
.main-review-comment-card {
  .comment-card-wrap {
    padding: 16px;
    border-radius: 4px;
    background-color: #f6f6f6;
    position: relative;
  }

  .user-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: #eaeaea;
  }

  .review-text {
    padding-left: 48px;
    position: relative;

    h5 {
      position: relative;
      @include font(12px, 700) {
        line-height: 12px;
      }
    }

    .star-rating {
      margin-top: 7px;
    }

    p {
      @include font(14px, 400) {
        line-height: 22px;
      }
      min-height: 20px;
      margin-top: 4px;
    }
  }

  .review-bottom {
    @include flex(space-between, flex-start);
    margin-top: 8px;

    h6 {
      @include font(12px, 400) {
        line-height: 18px;
        color: #888888;
      }
    }

    a {
      @include font(12px, 400) {
        line-height: 18px;
        color: #888888;
      }

      &::after {
        content: url('#{$img-root-url}/static/svg/common/right-arrow.svg');
        display: inline-block;
        vertical-align: -1px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -o-transform: scale(0.8);
        transform: scale(0.8);
        margin-left: 2px;
      }
    }
  }
}
