@charset "utf-8";
@import '../common/mixin';

.search-bar-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px 0px;

  @include new-mobile {
    margin-top: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @include new-desktop {
    .search-bar {
      position: relative;
      max-width: 540px;
      margin-right: 14px;

      button {
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: top;
        cursor: pointer;

        @include background(
          '#{$img-root-url}/static/v2/svg/common/ic_search_b.svg',
          center,
          center,
          cover
        );
      }

      input {
        display: block;
        width: 100%;
        height: 37px;
        @include font(14px, 400) {
          line-height: 14px;
        }
        font-size: 16px;
        border: 1px solid #999999;
        border-radius: 9999px;
        padding: 32px 50px 32px 25px;
      }
    }
  }

  @include new-mobile {
    .search-bar {
      max-width: 90%;
      margin-right: 0px;
      input {
        padding: 24px 48px 24px 24px;
      }
    }
  }
}
