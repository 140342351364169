@charset "utf-8";
@import '../common/mixin';

.promotion-video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & video {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);

    @include new-desktop {
      left: 50%;
      width: initial;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
}
