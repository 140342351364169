@import '../common/mixin';

.side-bar {
  .account-wrap {
    padding-bottom: 10px;
  }
}

.account-wrap {
  padding-bottom: 16px;

  .profile-img-wrap {
    padding-right: 12px;
  }

  .account-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    a {
      margin: auto 0 auto 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .info-wrap {
      display: flex;

      .profile-img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
        object-fit: cover;
      }
    }

    div {
      display: grid;
      align-items: center;

      .account-info-row {
        display: flex;
        padding-bottom: 4px;

        img {
          margin-right: 6px;
          height: 18px;
          width: 18px;
        }

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: #242424;
        }
      }
    }

    .email-info {
      font-size: 12px;
      line-height: 14px;
      color: #727272;
    }
  }

  .enrollment-btn {
    padding: 15px;
    font-size: 14px;
    height: 46px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    color: #ffffff;
    background: #4745ff;
    cursor: pointer;
  }
}

.recent-class-wrap {
  padding-bottom: 10px;

  p {
    width: 169px;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .recent-class {
    display: flex;
    align-items: center;
    padding: 10px 0 2px;

    img {
      height: 64px;
      width: 64px;
      border: 1px solid #eaeaea;
      border-radius: 50%;
      object-fit: cover;
    }

    .class-info-text {
      padding-left: 12px;

      .class-progress {
        padding-top: 5px;
        font-size: 13px;
        font-weight: normal;
        line-height: 16px;
        color: #727272;
      }
    }
  }
}

.category-wrap {
  margin: 0 0 10px;

  .category-tap {
    display: flex;
    justify-content: space-between;
    padding: 21px 0;

    .info-text {
      padding-top: 0;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #242424;
    }

    .count-txt {
      font-size: 14px;
      line-height: 17px;
      color: #242424;
    }

    div {
      display: flex;

      img {
        width: 27px;
        height: 20px;
        margin: -1px 11px 0px 0px;
      }
    }
  }

  &.side_bar {
    margin: 0 0 20px;

    .category-tap {
      padding: 21px;
    }

    .selected {
      background-color: #f2f2ff;
      border-radius: 4px;

      p {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #2725d2;
      }

      img {
        filter: invert(55%) sepia(89%) saturate(7276%) hue-rotate(236deg) brightness(98%)
          contrast(112%);
      }
    }
  }
}

.my-page-banner-bg {
  &.default {
    @include new-mobile {
      background-color: #0d0e11;
      border-radius: 6px;
    }
  }
}

.my-page-banner-wrap {
  display: flex;
  justify-content: center;
  height: 140px !important;

  .my-page-banner {
    border-radius: 4px;
    height: 140px;
    width: auto;
  }
}

.side-bar {
  .my-page-banner {
    height: 112px;
  }
}

.log-out-btn {
  height: 44px;
  width: 166px;
  margin: 32px auto 0;
  display: block;
  float: none;
  color: #242424;
  font-weight: normal;
  line-height: 14px;
  background: #f6f6f6;
  border-radius: 23px;
  border: none;

  &.modal {
    float: right;
    width: 74px;
    height: 30px;
    margin: 8px 0 0;
    font-size: 12px;
  }
}
