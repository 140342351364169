@import '../common/mixin';

.shipping-progress {
  position: relative;
  height: 7px;
  width: 87%;
  margin: 0 auto;
  @include flex(flex-start, center);

  div {
    position: relative;

    &.step {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
      background-color: #ffffff;
      flex-shrink: 0;

      &.active {
        background-color: $main-color;
        border: 1px solid $main-color;
      }
    }

    &.step-line {
      width: 100%;
      max-width: 110px;
      height: 1px;
      background-color: #eaeaea;

      &.active {
        background-color: $main-color;
      }
    }

    &::after {
      display: block;
      position: absolute;
      @include font(12px, 300) {
        line-height: 12px;
        color: #cccccc;
      }
      width: 43px;
      text-align: center;
      top: 14px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    &:nth-child(1)::after {
      content: '배송준비';
    }

    &:nth-child(3)::after {
      content: '배송출고';
    }

    &:nth-child(5)::after {
      content: '배송완료';
    }

    &.active::after {
      color: $main-color;
    }
  }
}
