@import '../common/mixin', '../common/mixin_animation';

.td-top-btn {
  visibility: hidden;
  width: 52px;
  height: 52px;
  @include background(
    '#{$img-root-url}/static/v2/svg/common/ic_pagetop.svg',
    center,
    center,
    cover
  );
  position: fixed;
  bottom: 112px;
  cursor: pointer;
  z-index: 999;
  transition: all 0.3s ease-in-out;

  @include new-mobile {
    bottom: calc(148px + env(safe-area-inset-bottom));
    bottom: calc(148px + constant(safe-area-inset-bottom));
  }

  &.fade-in-right-anim {
    visibility: visible;
    @include animation(fade-in-right-anim, 0.3s, 0s, ease-in-out, forwards);
    @include keyframe(fade-in-right-anim) {
      from {
        right: -68px;
        opacity: 0;
      }
      to {
        right: 48px;
        opacity: 1;
      }
    }

    @include new-mobile {
      @include keyframe(fade-in-right-anim) {
        from {
          right: -68px;
          opacity: 0;
        }
        to {
          right: 16px;
          opacity: 1;
        }
      }
    }
  }

  &.fade-out-right-anim {
    @include animation(fade-out-right-anim, 0.3s, 0s, ease-in-out, forwards);
    @include keyframe(fade-out-right-anim) {
      from {
        right: 48px;
        opacity: 1;
      }
      to {
        right: -68px;
        opacity: 0;
      }
    }

    @include new-mobile {
      @include keyframe(fade-out-right-anim) {
        from {
          right: 16px;
          opacity: 1;
        }
        to {
          right: -68px;
          opacity: 0;
        }
      }
    }
  }
}
