@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';

.product-dark {
  background-color: #1e1e1e;
  color: #ffffff;

  h6 {
    &.test {
      font-size: 23px;
      font-weight: bold;
      vertical-align: bottom;
      display: inline-block;
      margin-left: 2px;
      padding-top: 0px;
    }
  }

  .purchase-btn-text {
    @include flex(center, center);
    margin-top: 2px;
  }

  .course-curriculum-wrap {
    h5 {
      padding-top: 0;
    }
  }

  .select-box {
    .css-yk16xz-control,
    .css-1pahdxg-control {
      background-color: #1e1e1e !important;
      border-color: #3a3a3a !important;
    }

    .css-1wa3eu0-placeholder {
      color: #ffffff !important;
    }

    .css-1uccc91-singleValue {
      color: #ffffff;
      font-weight: 500;
    }

    .css-26l3qy-menu {
      border-color: #3a3a3a !important;

      div {
        color: #ffffff !important;
        background-color: #1e1e1e !important;

        &.css-1en3sjf-option:hover,
        &.css-1en3sjf-option,
        &.css-1ce6wmp-option {
          background-color: #3a3a3a !important;
          font-weight: 500 !important;
          color: $point-color !important;
        }
      }
    }
  }

  .content-wrap {
    margin-top: 0;
  }

  .detail-sticky-nav {
    background-color: rgba(30, 30, 30, 0.95);

    li {
      color: #ffffff;

      &:hover::before {
        background-color: $main-color;
      }

      &.selected::before {
        background-color: $main-color;
      }
    }
  }

  .detail-nav {
    li {
      color: #ffffff;

      &:hover::before {
        background-color: $main-color;
      }

      &.selected::before {
        background-color: $main-color;
      }
    }
  }

  h6 {
    @include font(14px, 400) {
      line-height: 20px;
    }
    padding-top: 29px;
  }

  h3 {
    line-height: 36px;
    margin-top: 8px;
    padding-top: 0;

    b {
      color: $point-color;
    }
  }

  p {
    @include font(16px, 700) {
      line-height: 24px;
      color: #ffffff;
    }
    margin-top: 24px;
    word-break: keep-all;
  }

  .curriculum-detail {
    margin-top: 24px;
  }

  .invoice-wrap {
    background-color: #1e1e1e;
    border-color: #3a3a3a;
  }

  .invoice-title-wrap {
    background-color: #1e1e1e !important;
    border-bottom: none !important;
    height: inherit !important;
    padding-top: 16px !important;

    img {
      right: 16px !important;
      top: 24px !important;
    }

    h6 {
      @include font(14px, bold) {
        font-family: 'GmarketSansBold';
        line-height: 14px;
        color: $main-color;
      }
      padding-top: 5px;
    }

    h4 {
      @include font(20px, 700) {
        line-height: 29px;
        color: #ffffff;
      }
      margin-top: 8px;
    }
  }

  .selected-list {
    li {
      border-radius: 2px;
      padding: 9px 16px 11px;
      background-color: #444444;
      @include flex(space-between, center);
      margin-top: 8px;

      .selected-title {
        position: relative;
        width: 72%;
      }

      img {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
      }

      h5 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 18px;
        @include font(14px, 400) {
          line-height: 20px;
        }
      }

      h6 {
        flex-shrink: 0;
        padding-top: 0;
      }
    }
  }

  .invoice-content-wrap {
    padding: 0 16px;
  }

  .kit-option-select-wrap {
    border-top: 1px solid #3a3a3a;
    margin-top: 16px;
    padding-top: 16px;
  }

  .img-group {
    @include flex(flex-start, center) {
      flex-wrap: wrap;
    }
    border-top: 1px solid #3a3a3a;
    border-bottom: 1px solid #3a3a3a;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;

    > div {
      width: 50%;

      span {
        @include font(16px, 700) {
          line-height: 16px;
        }
      }
    }

    p {
      @include font(10px, 700) {
        line-height: 15px;
        color: $point-color;
      }
      margin-top: 8px;
      flex-grow: 1;
    }
  }

  .purchase-info {
    background-color: #3a3a3a;
    padding: 20px 10px 30px;
    margin-top: 12px;
    border-radius: 4px;

    ul {
      li {
        @include flex(space-between, flex-start);
        margin-top: 6px;

        h5 {
          @include font(14px, 700) {
            line-height: 21px;
            text-decoration: inherit;
            color: #ffffff;
          }
        }

        h6 {
          @include font(14px, 400) {
            line-height: 21px;
          }
          padding-top: 0;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      .calculate-price {
        margin-top: 6px;
        margin-bottom: 24px;
        text-align: right;
        display: block;

        h6 {
          display: inline-block;
          padding-top: 4px;
          font-size: 16px;
          font-weight: 700;

          b {
            font-size: 13px;
            vertical-align: text-top;
            margin-right: 4px;
            color: $point-color;
          }
        }
      }

      &.product-option-item {
        border-top: 1px dashed #555555;
        margin-top: 16px;
        padding-top: 16px;

        h6 {
          font-weight: 700;
        }
      }

      &.product-total-price {
        border-top: 1px dashed #555555;
        margin-top: 16px;
        padding-top: 16px;

        h6 {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .total-price {
    padding: 16px 0;
    border-top: 1px solid #3a3a3a;
    @include flex(space-between, center);
    position: relative;

    .questions-box {
      border: 2px solid #818181;
      border-radius: 8px;
      padding: 16px;
      position: absolute;
      background-color: #1e1e1e;
      bottom: 57px;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        width: 0;
        height: 0;
      }

      &::before {
        border-top: 14px solid #818181;
        border-right: 14px solid transparent;
        left: 6px;
        bottom: -15px;
        border-bottom-left-radius: 3px;
      }

      &::after {
        border-top: 12px solid #1e1e1e;
        border-right: 12px solid transparent;
        left: 8px;
        bottom: -10px;
      }
    }

    p {
      @include font(13px, 300) {
        line-height: 13px;
        color: #ffffff;
      }
      margin-top: 0;
    }
  }

  .total-price-wrap {
    h6 {
      padding-top: 0;
    }
  }

  .event-msg-wrap {
    background-color: #0c041f;
    border: 4px solid #3a3a3a;
    margin-bottom: 0;

    p {
      @include font(24px, bold) {
        line-height: 60px;
        color: #ffffff;
      }
      letter-spacing: 4px;
      padding-top: 4px;
      margin-top: 0;

      &.event-finish-text {
        font-size: 18px;
        letter-spacing: 1px;
        color: #888888;
      }
    }
  }
}

.purchase-info-description-wrap {
  img {
    width: 16px;
    height: 16px;
    vertical-align: -3px;
    cursor: pointer;
    margin-right: 6px;
  }

  span {
    @include font(14px, 700) {
      line-height: 29px;
    }
  }

  h6 {
    line-height: 29px;
  }
}

.product-top-image {
  width: 100%;
  padding: 33.334% 0;
  border-radius: 10px;
}

.product-class-group {
  margin-top: 20px;

  .swiper-container {
    border-radius: 10px;
  }

  .class-thumbnail-img {
    min-height: 100px;
    height: 100px;
  }

  &.no-slider {
    @include flex(flex-start, flex-start);

    .class-thumbnail-img {
      width: 25%;
    }
  }
}

.info-group {
  margin-top: 40px;
  margin-bottom: 56px;
  @include flex(flex-start, center);

  li {
    width: 48.5%;
    max-width: 388px;
    border: 1px solid #3a3a3a;
    border-radius: 2px;
    padding: 29px 0 30px;
    text-align: center;
    background-color: #2d2d2d;
    margin-left: 3%;

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-right: 8px;
      vertical-align: -3px;
    }

    span {
      @include font(20px, 700) {
        line-height: 29px;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.curriculum-tab-wrap {
  margin-top: 36px;
}

.curriculum-tab {
  position: relative;

  .tab-item {
    cursor: pointer;
    opacity: 0.5;

    &.selected {
      opacity: 1;

      .border-top-anim {
        @include animation(width-anim, 0.5s, 0s, ease, forwards);
        @include keyframe(width-anim) {
          0% {
            width: 0%;
            background-color: transparent;
          }
          100% {
            width: 100%;
            background-color: #6236ff;
          }
        }
      }
    }

    h5 {
      @include font(12px, 300) {
        line-height: 14px;
        font-family: 'Roboto', sans-serif;
        color: #ffffff;
      }
    }

    h4 {
      @include font(16px, 700) {
        line-height: 24px;
      }
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tab-img-wrap {
    margin-top: 8px;
    position: relative;

    .border-top-anim {
      height: 2px;
    }

    .tab-img {
      padding: 31% 0;
    }
  }

  .tab-swiper-prev-unique,
  .tab-swiper-next-unique {
    position: absolute;
    width: 27px;
    height: 30px;
    top: -69px;
    background-color: #3a3a3a;
    border: 1px solid #666666;
    border-radius: 4px;
    z-index: 2;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      margin: 8px auto 0;
      width: 7px;
      height: 13px;
    }
  }

  .tab-swiper-prev-unique {
    right: 30px;

    &::before {
      @include background(
        '#{$img-root-url}/static/svg/detail/icon-prev-gray.svg',
        center,
        center,
        cover
      );
    }
  }

  .tab-swiper-next-unique {
    right: 0;

    &::before {
      @include background(
        '#{$img-root-url}/static/svg/detail/icon-next-gray.svg',
        center,
        center,
        cover
      );
    }
  }

  .swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.curriculum-title {
  padding-top: 56px;
  display: block;
  @include font(20px, 700) {
    line-height: 29px;
  }
}

.curriculum-list {
  margin-top: 16px;

  .curriculum-item {
    border-color: #3a3a3a;

    &:first-of-type {
      margin-top: 0;
    }
  }

  li {
    margin-top: 18px;
    position: relative;
  }

  h4 {
    @include font(20px, 700) {
      line-height: 29px;
    }
  }

  .chapter-content-number {
    width: 24px;
    height: 24px;
    background-color: #3a3a3a;
    color: #ffffff;
    @include font(14px, 400) {
      line-height: 24px;
    }
  }

  h5 {
    @include font(15px, 400) {
      line-height: 22px;
      color: #ffffff;
    }
  }

  .preview-video-btn-wrap {
    position: absolute;
    right: 0;
    height: 24px;
    background-color: $main-color;
    border-radius: 2px;
    text-align: center;
    padding: 0 9px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 4px;
      vertical-align: -3px;
    }

    span {
      @include font(12px, 700) {
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}

.review-rating-total-wrap {
  margin-top: 32px;
  border: 1px solid #444444;
  padding: 22px 0;
  background-color: #444444;
  border-radius: 2px;
  text-align: center;

  h4 {
    @include font(15px, 700) {
      line-height: 22px;
    }
    letter-spacing: 0px;
  }

  .star-rating {
    margin-top: 9px;

    li {
      width: 38px;
      height: 37px;
      margin-right: 8px;
    }
  }
}

.course-review-more-btn {
  border-top: 1px solid #3a3a3a;
  margin-top: 24px;
  padding-top: 24px;

  a {
    display: block;
    width: 100%;
    height: 48px;
    text-align: center;
    border-radius: 2px;
    background-color: #3a3a3a;
    padding: 11px 0 13px;
    @include font(16px, 400) {
      line-height: 24px;
    }
  }
}

.preview-review-wrap {
  position: relative;

  .td-layer {
    width: 70px;
    height: 100%;
    left: auto;
    right: 0;
    background: transparent;
    background: -moz-linear-gradient(left, rgba(30, 30, 30, 1) 0%, rgba(30, 30, 30, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(30, 30, 30, 1) 0%, rgba(30, 30, 30, 0) 100%);
    background: linear-gradient(to left, rgba(30, 30, 30, 1) 0%, rgba(30, 30, 30, 0) 100%);
  }

  .swiper-slide {
    width: 320px;
  }

  .preview-review-header {
    @include flex(space-between, center);
  }

  .preview-user-info {
    @include flex(flex-start, center);
  }

  .preview-review {
    width: 320px;
    max-height: 240px;
    background-color: #444444;
    padding: 24px;
    border-radius: 2px;
    border: 1px solid #444444;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }

  .preview-profile {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .preview-user {
    h5 {
      @include font(12px, 500) {
        line-height: 18px;
      }
    }

    h6 {
      @include font(10px, 300) {
        line-height: 15px;
      }
      padding-top: 0;
    }
  }

  .preview-review-content {
    margin-top: 16px;

    p {
      @include font(15px, 400);
      margin-top: 0;
      @include textEllipsis(144px, 6);
    }
  }

  .product-review-swiper-next-unique {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #777777;
    border: 1px solid #444444;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;

    &::before {
      content: '';
      @include background(
        '#{$img-root-url}/static/svg/detail/icon-next-gray.svg',
        center,
        center,
        cover
      );
      display: block;
      margin: 12px auto 0;
      width: 9px;
      height: 15px;
    }
  }

  .empty-review-wrap {
    padding: 63px 16px;

    img {
      width: 32px;
      height: 32px;
      display: block;
      margin: 0 auto;
    }

    h3 {
      @include font(14px, 400) {
        line-height: 20px;
        color: #cccccc;
      }
      margin-top: 12px;
      text-align: center;
    }
  }
}

.product-course-period-wrap {
  border-radius: 2px;
  background-color: #0c041f;
  padding: 15px 16px 17px;
  border: 1px solid #6236ff;
  @include flex(space-between, center);

  h5 {
    @include font(16px, 700) {
      line-height: 24px;
      color: $main-color;
    }
  }

  b {
    @include font(18px, bold) {
      font-family: 'GmarketSansBold';
      line-height: 20px;
    }
    letter-spacing: -0.45px;
  }

  @include new-mobile {
    margin-top: 16px;
  }
}

.course-ticket-wrap {
  border-right: 1px solid #3a3a3a;

  img {
    width: 18px;
    height: 15px;
    vertical-align: -2px;
    margin-right: 4px;
  }
}

.course-secret-note-wrap {
  img {
    width: 15px;
    height: 16px;
    margin-right: 4px;
    vertical-align: -2px;
  }
}

@include tablet {
  .product-dark {
    @include new-mobile {
      padding-top: 0;
    }

    .mo-detail-header-wrap {
      margin-top: 40px;

      .img-group {
        img {
          height: 16px;
        }
      }
    }

    .mo-invoice-title-wrap {
      h3 {
        margin-top: 0;
        line-height: 24px;
      }
    }

    .invoice-content-wrap {
      padding-bottom: 20px;
    }

    .purchase-btn-wrap {
      background-color: #1e1e1e !important;
      border-color: #444444 !important;
    }

    .content-wrap {
      padding-top: 0;
    }

    .course-info-wrap {
      h6 {
        padding: 0 16px;
      }

      h3 {
        padding: 0 16px;
      }

      p {
        padding: 0 16px;
      }
    }

    .mo-purchase-btn-wrap {
      background-color: #1e1e1e;
      border-color: #444444;

      .icon-wrap {
        border-color: #727272;
      }

      .pay-dark-btn {
        margin-left: 8px;
        background: none;
        border: 2px solid $main-color;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #240e73;
        }
      }
    }
  }

  .mo-product-course-img-slider {
    .class-thumbnail-img {
      width: 100%;
      padding: 31% 0;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 20px;
      left: 50%;
      height: 4px;
      transform: translateX(-50%);
      z-index: 2;
      @include flex(center, center);

      span {
        width: 4px;
        height: 4px;
        background-color: #ffffff;
        border-radius: 50%;
        margin-left: 8px;
        opacity: 0.3;

        &:first-child {
          margin-left: 0;
        }

        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }

  .mo-detail-header-wrap {
    .product-title {
      h6 {
        @include font(14px, bold) {
          line-height: 14px;
          color: $main-color;
          font-family: 'GmarketSansBold';
        }
        padding-top: 5px;
      }

      h4 {
        @include font(20px, 700) {
          line-height: 29px;
        }
        margin-top: 8px;
        word-break: break-all;
      }
    }

    .purchase-info {
      padding-bottom: 16px !important;
    }

    .calculate-price {
      margin-bottom: 0 !important;
    }
  }

  .info-group {
    padding: 0 16px;
  }

  .course-curriculum-wrap {
    h6 {
      padding: 29px 16px 0;
    }
  }

  .curriculum-tab {
    padding: 0 16px;

    .tab-swiper-prev-unique,
    .tab-swiper-next-unique {
      width: 24px;
      height: 27px;
      top: -63px;

      &:before {
        margin-top: 6px;
      }
    }

    .tab-swiper-prev-unique {
      right: 43px;
    }

    .tab-swiper-next-unique {
      right: 16px;
    }
  }

  .curriculum-title {
    margin-left: 16px;
  }
}

@include mobile {
  .product-dark {
    h3 {
      line-height: 29px;
    }
  }

  .info-group {
    display: block;

    li {
      height: 72px;
      padding: 21px 0 17px;
      width: 100%;
      max-width: inherit;
      margin-left: 0;
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .course-curriculum-wrap {
    h3 {
      font-size: 17px;
      line-height: 24px;
    }
  }
  .curriculum-list {
    .preview-video-btn-wrap {
      img {
        margin-right: 0;
        vertical-align: -5px;
      }

      span {
        display: none;
      }
    }
  }

  .preview-review-wrap {
    .td-layer {
      width: 20px;
    }

    .swiper-slide {
      width: 280px;
    }

    .preview-review {
      width: 280px;
    }
  }
}
