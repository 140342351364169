@import '../common/mixin';

/* PreviewYoutube banner, MasterApply Banner */
.banner-wrapper {
  width: 100%;
  max-width: 1280px;
  height: 240px;
  min-height: 240px;
  margin: 0 auto;
  position: relative;
  padding: 30px 32px;
  border-radius: 4px;

  &.preview-youtube-banner {
    @include flex(space-between, flex-start);

    .text-wrapper {
      .preview-title {
        font-size: 24px;
        line-height: 1.35;
        @include textEllipsis(96px, 3);
      }
    }
  }

  &.master-apply-banner {
    background: #7948f9;
    background: -moz-linear-gradient(to right, #2725d2 0%, #7948f9 100%);
    background: -webkit-linear-gradient(to right, #2725d2 0%, #7948f9 100%);
    background: linear-gradient(to right, #2725d2 0%, #7948f9 100%);

    & > a {
      @include flex(space-between, flex-start);
    }
  }

  img {
    width: 136px;
    height: 32px;
    border-radius: 16px;
    position: absolute;
    left: 16px;
    top: -16px;
  }

  .text-wrapper {
    width: 72%;
    padding-top: 14px;
    padding-left: 8px;
    padding-right: 20px;

    .preview-title {
      @include font(28px, 700) {
        line-height: 1.43;
        color: #ffffff;
      }
      letter-spacing: -1px;
      word-break: keep-all;
    }

    a,
    span {
      display: inline-block;
      margin-top: 28px;
      @include font(16px, 300) {
        color: #ffffff;
        line-height: 1;
      }
      letter-spacing: -1px;

      &::after {
        content: '';
        display: inline-block;
        margin-left: 6px;
        vertical-align: -1px;
        width: 8px;
        height: 13px;
        @include background(
          '#{$img-root-url}/static/svg/common/main-right-arrow.svg',
          center,
          center,
          cover
        );
      }
    }
  }

  .img-wrapper {
    width: 288px !important;
    height: 180px !important;
    overflow: hidden;
    border-radius: 4px;
  }

  .master-apply-thumbnail {
    div {
      width: 100%;
      height: 100%;
    }
  }
}

@include mediaQuery(1280px) {
  .banner-wrapper {
    border-radius: 0;

    .img-wrapper {
      flex-shrink: 0;
    }
  }
}

@include mediaQuery(740px) {
  .banner-wrapper {
    padding: 30px 24px 24px;

    .text-wrapper {
      padding: 0;

      .preview-title {
        font-size: 24px;
      }
    }
  }
}

@include mediaQuery(576px) {
  .banner-wrapper {
    padding: 30px 16px 16px;
    height: inherit;
    max-height: inherit;

    &.preview-youtube-banner {
      display: block;

      .text-wrapper {
        .preview-title {
          font-size: 20px;
          line-height: 1.45;
          height: 87px;
        }
      }
    }

    &.master-apply-banner {
      > a {
        display: block;
      }
    }

    .text-wrapper {
      width: 100%;
      padding: 0;

      a,
      span {
        font-size: 14px;
        margin-top: 8px;

        &:after {
          vertical-align: 0px;
          width: 6px;
          height: 9px;
        }
      }
    }

    .img-wrapper {
      margin-top: 16px;
      width: 100% !important;
      max-width: inherit !important;
      height: inherit !important;

      > div {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 544px;
        height: 0 !important;
        padding-bottom: 56.25%;

        > iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
