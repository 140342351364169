@import '../common/mixin';

.review-card-slide {
  color: $black-color;
  padding: 53px 30px 60px;
  position: relative;
  touch-action: pan-y;

  .swiper-slide {
    @include new-mobile {
      width: 154px !important;
    }
  }

  @include new-mobile {
    padding: 36px 0 30px 16px;
  }

  .review-title {
    @include font(28px, 400) {
      line-height: 40px;
      color: $white-color;
    }
    margin-bottom: 31px;

    @include new-mobile {
      @include font(20px, 700) {
        line-height: 26px;
      }
      margin-bottom: 28px;
    }

    > b {
      font-weight: 700;
    }
  }

  .swiper-review-slider-prev,
  .swiper-review-slider-next {
    @include size(36px, 36px);
    @include flex(center, center);
    border-radius: 50%;
    background-color: $white-color;
    position: absolute;
    top: 108px;
    cursor: pointer;

    @include new-mobile {
      display: none;
    }
  }

  .swiper-review-slider-prev {
    right: 80px;
  }

  .swiper-review-slider-next {
    right: 34px;
  }
}

.review-card-wrap {
  max-width: 332px;
  height: 248px;
  padding: 20px;
  background-color: $white-color;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.135129);
  border-radius: 12px;

  @include new-mobile {
    width: 154px;
    border-radius: 4px;
    padding: 12px;
    height: 210px;
  }

  .review-info-wrap {
    @include flex(flex-start, center);

    .thumbnail {
      display: inline-block;
      @include size(60px, 60px);
      border-radius: 50%;
      margin-right: 13px;

      @include new-mobile {
        margin-right: 8px;
        @include size(46px, 46px);
      }
    }

    .content {
      h4 {
        margin-bottom: 7px;
        @include font(14px, 700) {
          line-height: 17px;
        }
        @include textEllipsis(17px, 1);
      }

      h5 {
        font-size: 13px;
        line-height: 16px;

        > span {
          font-size: 11px;
        }
      }
    }
  }
}

.review-comment-wrap {
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  @include textEllipsis(105px, 4);

  @include new-mobile {
    margin-top: 14px;
    font-size: 12px;
    line-height: 18px;
    -webkit-line-clamp: 6;
  }
}
