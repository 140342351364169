@import '../common/mixin';
@import '../common/mixin_animation';

.pop-up-button {
  position: fixed;
  right: 48px;
  bottom: 148px;
  @include size(120px, 204px);
  cursor: pointer;
  z-index: 999;

  &:hover {
    .tooltip-wrap {
      top: 0;
      opacity: 1;
    }
  }

  @include new-mobile {
    @include size(93px, 136px);
    right: 16px;
    bottom: calc(148px + env(safe-area-inset-bottom));
    bottom: calc(148px + constant(safe-area-inset-bottom));
  }

  .gift-bear-lottie {
    position: absolute;
    bottom: 0;
    @include size(120px, 120px);

    @include new-mobile {
      @include size(80px, 80px);
      left: 6px;
    }
  }

  .tooltip-wrap {
    position: absolute;
    top: 8px;
    transition: all linear 300ms;
    opacity: 0;

    @include new-mobile {
      top: 0;
      opacity: 1;
    }

    .tooltip {
      position: relative;
      @include size(120px, 64px);
      @include font(15px, 500) {
        color: $dark-gray-1-color;
        letter-spacing: -1px;
        text-align: center;
        line-height: 64px;
      }
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      @include new-mobile {
        @include size(93px, 44px);
        font-size: 13px;
        line-height: 44px;
      }
    }

    .polygon {
      position: absolute;
      top: 71px;
      left: 50%;
      transform: translate(-50%, -50%);
      @include size(30px, 30px);
      @include background(
        '#{$img-root-url}/static/v2/png/common/ic_polygon.png',
        center,
        center,
        cover
      );

      @include new-mobile {
        top: 48px;
        @include size(20px, 20px);
      }
    }
  }
}
