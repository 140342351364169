@import '../common/mixin';
@import '../common/mixin_animation';

.time-event-container {
  letter-spacing: 0;

  > .td-wrapper {
    @include wrapper(960px);
  }

  /* time-event for hard-study */
  &.hard-study {
    .making-banner-image {
      &.default {
        @include background(
          '#{$img-root-url}/static/v2/png/hardstudy/hardstudy_time_event_banner.png',
          center,
          center,
          cover
        );
      }
    }

    .time-event-category-name {
      margin-top: 180px;
      margin-bottom: 130px;
      font-size: 32px;
      line-height: 1.5;

      @include new-tablet {
        margin-top: 150px;
        margin-bottom: 100px;
        font-size: 28px;
      }

      @include new-mobile {
        margin-top: 100px;
        margin-bottom: 70px;
        font-size: 19px;
      }

      span {
        margin-right: 5px;
        font-size: 34px;
        color: $hard-study-color;

        @include new-tablet {
          font-size: 30px;
        }

        @include new-mobile {
          margin-right: 3px;
          font-size: 20px;
        }
      }
    }

    .content-title {
      @include new-mobile {
        width: 100%;
        max-width: 488px;
        margin: 0 auto 20px;
      }
    }
  }

  /* time-event for tm */
  &.tm-event {
    .making-banner-image {
      max-height: 500px;

      &.default {
        @include background(
          '#{$img-root-url}/static/v2/png/event/tm_event_banner.png',
          top,
          center,
          cover
        );
      }
    }

    .td-wrapper {
      margin-top: 60px;
    }

    .content-title {
      @include new-mobile {
        width: 100%;
        max-width: 500px;
        margin: 0 auto 20px;
      }
    }
  }

  /*time-special-price*/
  .time-special-price {
    .time-event-list {
      .time-event-category-name {
        @include new-mobile {
          margin-bottom: 40px;
        }
      }

      .course-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        width: 100%;

        margin-bottom: 80px;

        @include new-tablet {
          grid-template-columns: repeat(3, 1fr);
        }

        @include new-mobile {
          grid-template-columns: repeat(2, 1fr);
        }

        .list-item {
          max-width: 267px;
          min-width: 154px;
          box-shadow: none;
          margin-bottom: 16px;

          @include new-tablet {
            max-width: none;
          }

          @include new-mobile {
            max-width: none;
          }
        }
      }

      .list-item {
        .badge-wrap-2 {
          position: absolute;
          top: 8px;
          left: 8px;
          background-color: #ff0067;
          padding: 4px 6px;
          border-radius: 4px;

          @include new-tablet {
            top: 8px;
            left: 8px;
          }

          p {
            @include font(11px, 700) {
              color: #ffffff;
            }
            @include new-mobile {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .thumbnail-wrap {
          @include flex(center, center);
          width: 100%;

          padding-bottom: 62%;
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 8px;

          position: relative;
          transition: transform 0.3s ease;

          .thumbnail-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }

          &:hover {
            transform: translateY(-4px);
          }
        }
        .course-desc-wrap {
          word-wrap: break-word;

          h4 {
            font-weight: normal;

            display: block;
            word-break: keep-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }

          p {
            color: #888;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-top: 8px;
          }
        }
      }

      .origin-price-wrap {
        @include flex(flex-start, center);
      }

      .time-event-price-wrap {
        @include flex(flex-start, center);
        background-color: initial;
        color: #000;
        height: initial;

        h5 {
          font-size: 16px;
          line-height: 1.3;
          @include new-mobile {
            font-size: 16px;
            line-height: 130%;
          }
        }

        .discount-ratio {
          color: #4745ff;
        }

        .right {
          margin-left: 4px;
        }
      }
    }
  }
  .disable-layer {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 2;
    @include flex(center, center);
    .info-text {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      padding: 10px 12px;
      background-color: black;
      border-radius: 8px;
      @include new-mobile {
        font-size: 16px;
        padding: 10px 16px;
      }
    }
  }

  .opacity-layer {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

/* default time-event */
.time-event-top-header {
  background-color: #0c041f;
  position: relative;
  max-height: 500px;

  .td-wrapper {
    position: absolute;
    top: 80px;
    width: 100%;
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);

    @include new-mobile {
      top: 56px;
    }
  }

  .animation-wrap {
    overflow: hidden;
    max-height: 500px;
  }

  .making-banner-image {
    width: 100%;
    height: 500px;

    @include new-mobile {
      height: 400px;
    }
  }

  .default-background-image {
    width: 100%;
    height: 500px;

    @include new-mobile {
      height: 400px;
    }

    @include animation(scale-anim, 2.8s, 0s, ease, alternate) {
      animation-iteration-count: infinite;
    }
    @include keyframe(scale-anim) {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.02);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .time-event-logo {
    width: 100%;
    max-width: 285px;
    height: auto;
    animation-delay: 0.45s;

    @include new-tablet {
      max-width: 175px;
    }

    @include new-mobile {
      max-width: 144px;
    }
  }

  .clock-anim {
    position: absolute;
    width: 100%;
    max-width: 960px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;

    @include new-tablet {
      max-width: 700px;
    }

    @include new-mobile {
      max-width: 540px;
    }
  }
}

.time-event-list {
  min-height: 620px;

  .time-event-category-list {
    padding-top: 100px;

    @include tablet {
      padding-top: 60px;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .time-event-category-name {
    @include font(24px, bold) {
      line-height: 24px;
      font-family: 'GmarketSansBold';
    }
    text-align: center;

    @include new-mobile {
      font-size: 20px;
    }
  }

  .course-list {
    display: flex;
    // gap: 20px;
    margin-top: 48px;
    // margin-right: 2%;

    // @include new-tablet {
    //   justify-content: center;
    // }

    @include new-mobile {
      margin-top: 24px;
    }

    &.justify-content-center {
      justify-content: center;
      margin-left: 0;
    }
  }

  .list-item {
    box-shadow:
      0 7px 18px rgba(0, 0, 0, 0.1),
      0 12px 8px rgba(0, 0, 0, 0.02);
    background-color: #ffffff;
    border-radius: 4px;

    @include new-mobile {
      width: 100% !important;
    }

    h4 {
      font-weight: 700;
    }

    .thumbnail-wrap {
      a {
        margin: 0 auto 8px;
        width: 120px;
        height: 120px;
        border-radius: 120px;
        overflow: hidden;
      }

      .course-image {
        width: 100%;
        height: 100%;

        &:hover {
          transform: none;
        }
      }
    }
  }

  .link-button {
    margin-bottom: 0 !important;
    font-size: 12px;

    &::after {
      content: '';
      display: inline-block;
      width: 5px;
      height: 9px;
      margin-left: 4px;
      @include background(
        '#{$img-root-url}/static/png/event/move-btn-arrow-icon.png',
        center,
        center,
        cover
      );
      vertical-align: 1px;
    }
  }

  .origin-price-wrap {
    margin-top: 12px;
    @include flex(space-between, center);

    @include new-tablet {
      margin-top: 12px;
    }

    @include new-mobile {
      margin-top: 8px;
    }

    h6 {
      @include font(12px, 500) {
        line-height: 130%;
        color: #aaaaaa;
      }

      span {
        text-decoration: line-through;
      }
    }
  }

  .time-event-price-wrap {
    @include flex(space-between, center);
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #0c041f;
    margin-top: 4px;
    color: #ffffff;

    img {
      width: 13px;
      height: 20px;
      margin-right: 4px;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      vertical-align: -4px;
    }

    span {
      @include font(16px, bold) {
        line-height: 48px;
        font-family: 'GmarketSansBold';
      }
    }

    .right {
      text-align: right;
    }

    h5 {
      @include font(16px, bold) {
        line-height: 130%;
      }
    }

    p {
      @include font(10px, normal) {
        line-height: 14px;
        color: #d8d8d8;
        font-family: 'GmarketSansMedium';
      }
    }
  }
}

.learning-reason-wrap {
  background-color: #f9f9f9;
  padding: 48px 16px 74px;

  @include new-mobile {
    margin-top: 60px;
    padding-left: 0;
    padding-right: 0;
  }

  .td-wrapper {
    @include wrapper(980px);
    overflow: hidden;
    padding-bottom: 30px;
  }

  h3 {
    @include font(24px, bold) {
      line-height: 25px;
      font-family: 'GmarketSansBold';
    }
    text-align: center;
  }

  ul {
    margin-top: 48px;
  }

  .swiper-container {
    overflow: visible;
    pointer-events: none;

    @include new-tablet {
      pointer-events: inherit;
    }
  }

  .swiper-wrapper {
    justify-content: center;

    @include new-tablet {
      justify-content: space-between;
    }
  }

  .swiper-slide {
    width: 23.8% !important;
    flex-shrink: 0;
    background-color: #ffffff;
    padding: 24px 0 32px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 1%;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(1) {
      img {
        width: 42px;
        height: 29px;
      }
    }

    &:nth-child(2) {
      img {
        width: 40px;
        height: 41px;
      }
    }

    &:nth-child(3) {
      img {
        width: 38px;
        height: 33px;
      }
    }

    &:nth-child(4) {
      img {
        width: 30px;
        height: 30px;
      }
    }

    @include new-tablet {
      width: 230px !important;
      margin-right: 0;
    }
  }

  li {
    .icon-wrap {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: #f9f9f9;
      margin: auto;
      position: relative;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }

    h4 {
      @include font(16px, 700) {
        line-height: 16px;
        color: #888888;
      }
      margin-top: 20px;
    }

    h3 {
      @include font(32px, bold) {
        line-height: 32px;
        font-family: 'GmarketSansBold';
      }
      letter-spacing: -1px;
      margin-top: 16px;

      span {
        font-family: 'GmarketSansMedium';
        font-size: 20px;
      }
    }

    .hash-tag-wrap {
      @include flex(center, center);
      margin-top: 30px;

      div {
        @include font(12px, bold) {
          line-height: 12px;
          font-family: 'GmarketSansBold';
          color: #6236ff;
        }
        padding: 9px 11px 6px;
        background-color: #f9f9f9;
        border-radius: 16px;
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    p {
      @include font(14px, 400) {
        line-height: 24px;
        color: #444444;
      }
      letter-spacing: -0.25px;
      margin-top: 16px;

      b {
        font-weight: 700;
      }
    }
  }
}

.time-event-nav {
  display: none;

  @include new-mobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $main-color;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

    a {
      @include flex(flex-start, center);
      height: 48px;
    }

    img:nth-of-type(1) {
      width: 23px;
      height: 22px;
    }

    img:nth-of-type(2) {
      width: 76px;
      height: 19px;
      margin-left: 5px;
    }

    &.sticky {
      display: block;
      @include animation(slide-down-anim, 0.15s, 0s, ease-in-out, forwards);
      @include keyframe(slide-down-anim) {
        from {
          top: -48px;
        }
        to {
          top: 0;
        }
      }
    }
  }
}

.time-box-wrap {
  position: absolute;
  width: 100%;
  max-width: 960px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 160px;
  z-index: 4;
  bottom: -80px;
  background-color: #ffffff;
  border-radius: 80px;
  animation-delay: 0.4s !important;

  @include new-tablet {
    width: 96%;
    height: 100px;
    bottom: -50px;
    border-radius: 50px;
  }

  @include new-mobile {
    width: 94%;
  }

  @include mediaQuery(530px) {
    height: 88px;
    border-radius: 44px;
    bottom: -44px;
    width: 92%;
  }

  .box-front {
    width: 98%;
    height: 89%;
    background: #1b5283;
    background: linear-gradient(to bottom, #1b5283 0%, #0c041f 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 72px;
    z-index: 5;

    @include mediaQuery(530px) {
      width: 97%;
    }
  }

  .time-event-count-wrap {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    z-index: 6;
    transform: translate(-50%, -50%);
  }

  .count-down {
    color: #ffffff;
    text-align: center;
    transition: all 0.1s ease-in-out;

    span {
      @include font(48px, bold) {
        line-height: 80px;
        font-family: 'GmarketSansBold';
      }
      letter-spacing: 40px;

      @include new-tablet {
        font-size: 32px;
        line-height: 50px;
        letter-spacing: 40px;
      }

      @include new-mobile {
        font-size: 26px;
        letter-spacing: 22px;
      }

      @include mediaQuery(530px) {
        font-size: 24px;
        line-height: 44px;
        letter-spacing: 12px;
      }

      &.day-text,
      &.time-colon {
        font-family: 'GmarketSansMedium';
      }

      &.day,
      &.time {
        margin-left: 40px;

        @include new-mobile {
          margin-left: 22px;
        }

        @include mediaQuery(530px) {
          margin-left: 12px;
        }
      }

      &.day-text {
        border-right: 1px solid rgba(234, 234, 234, 0.3);
      }

      &.event-finished-text {
        font-size: 35px;
        letter-spacing: 2px;

        b {
          color: #c700ff;
        }
      }
    }
  }
}
