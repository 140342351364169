@import '../common/mixin';

.course-list {
  margin-top: 16px;
  position: relative;

  @include flex(flex-start, flex-start) {
    flex-wrap: wrap;
  }

  &.promotion-list {
    justify-content: space-between;

    .list-item {
      width: 32%;
      max-width: 287px;
    }
  }

  &.course-slide {
    display: block !important;
    max-width: 1200px;
    width: calc(100% - 80px);
    margin-bottom: 0;

    .list-item {
      margin-bottom: 0;
    }
  }

  &.overview-list {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4, minmax(154px, 267px));
    column-gap: 20px;
    row-gap: 42px;
    margin-bottom: 68px;

    // 스크롤용
    overflow-x: auto;
    overflow-y: hidden;
    // &::-webkit-scrollbar {display: none;}
    // 스크롤용2
    padding-top: 16px;
    margin-top: -16px;
    padding-bottom: 20px;
    margin-bottom: 48px;
    // 스크롤용3
    padding-left: 10px;
    margin-left: -10px;
    padding-right: 10px;
    margin-right: -10px;

    .card-item-v2 {
      display: block;
      width: 100%;

      @include new-mobile {
        display: block;
        width: 100%;
      }
    }

    @include new-mobile {
      // 스크롤용4
      overflow-x: visible;
      overflow-y: visible;

      grid-template-columns: repeat(2, minmax(154px, 234px));
      column-gap: 8px;
      row-gap: 22px;
      margin-bottom: 40px;
      justify-content: center;
    }
  }

  &.search-curation {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, minmax(328px, 516px));
    column-gap: calc(11%);

    @include new-mobile {
      grid-template-columns: repeat(1, minmax(328px, 516px));
      justify-content: center;
      row-gap: 0;
    }
  }

  .swiper-slide {
    width: 288px;
  }
}

.list-item {
  margin-bottom: 68px;

  .link-button {
    display: block;
    margin-top: 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    color: #6236ff;
    padding: 13px 0;
    margin-bottom: 24px;
  }

  .thumbnail-wrap {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    .course-image {
      width: 100%;
      padding: 31% 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        -webkit-transform: scale(1.05);
        -moz-transform: scale(1.05);
        -o-transform: scale(1.05);
        transform: scale(1.05);
      }
    }

    > img {
      position: absolute;
      right: 8px;
      bottom: 8px;
      z-index: 2;
      cursor: pointer;
      width: 21px;
      height: 21px;
    }
  }

  .badge-wrap {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
    width: 40px;
    height: 40px;
    border-radius: 20px;

    img {
      display: block;
    }

    &.coming-soon-badge {
      background-color: $coming-soon-color;

      p {
        @include font(10px, 700) {
          font-family: 'GmarketSansBold';
          line-height: 40px;
          letter-spacing: -1px;
        }
        margin-top: 0;
      }
    }

    &.best-badge {
      background-color: $main-color;

      img {
        width: 16px;
        height: 13px;
        margin: 8px auto 0;
      }
    }

    &.new-badge {
      background-color: #ff7676;

      img {
        width: 14px;
        height: 16px;
        margin: 6px auto 0;
      }
    }

    &.package-badge {
      img {
        width: 40px;
        height: 40px;
      }
    }

    p {
      @include font(8px, normal) {
        font-family: 'Roboto', sans-serif;
        color: #ffffff;
        letter-spacing: 0;
      }
      text-align: center;
      margin-top: 3px;
    }
  }

  .badge-wrap-2 {
    position: absolute;
    display: inline-block;

    p {
      border-radius: 12px;
      font-size: 11px;
      font-weight: 700;
      color: #ff1454;
      line-height: 100%;
    }
  }

  b {
    @include font(12px, 700) {
      line-height: 20px;
      color: $point-color;
    }
  }

  h4 {
    margin-top: 7px;
    @include font(16px, 500) {
      line-height: 1.38;
    }
  }

  .bottom-wrap {
    margin-top: 9px;
    @include flex(space-between, center);

    p {
      width: 65%;
      @include textEllipsis(20px, 1);
    }

    a {
      @include font(12px, 400) {
        color: #888888;
        line-height: 20px;
      }

      &:last-child::before {
        content: '\00A0·\00A0';
        display: inline-block;
        cursor: default;
      }
    }

    img {
      width: 12px;
      height: 12px;
    }

    span {
      @include font(12px, 400) {
        color: #d8d8d8;
        line-height: 20px;
      }
      vertical-align: 2px;
      margin-left: 4px;
    }

    .kit-desc {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #888888;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@include tablet {
  .overview-popular-wrap {
    .course-list {
      &.promotion-list {
        .list-item {
          width: 23.5%;
        }
      }
    }
  }
  .course-list {
    &.promotion-list {
      .list-item {
        width: 32%;
      }
    }

    &.course-slide {
      width: 100%;
      max-width: initial;
      @include flex(space-between, flex-start) {
        display: flex !important;
      }
    }

    .list-item {
      width: 23.5%;

      h4 {
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
}

@include mediaQuery(600px) {
  .course-list {
    &.promotion-list {
      .list-item {
        margin-bottom: 24px;
      }
    }
  }
  .list-item {
    .thumbnail-wrap {
      > img {
        right: 4px;
        bottom: 4px;
      }
    }
  }
}

@include mobile {
  .top-content {
    .issue-check-wrap {
      .issue-list {
        margin-top: 0;
      }
    }
  }

  .list-item {
    .bottom-wrap {
      margin-top: 7px;
    }
  }
}

@include mediaQuery(360px) {
  .course-list {
    .list-item {
      width: 100% !important;
      max-width: inherit !important;
    }
  }
}
