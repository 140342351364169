@charset "utf-8";
@import '../common/mixin';

.purchase-container {
  .td-wrapper {
    max-width: 640px;
    margin-top: 71px;
    position: relative;
    padding: 0 !important;
  }

  h3 {
    @include font(20px, 700) {
      line-height: 22px;
    }
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .purchase-info {
    .sale-price {
      color: $point-color;
    }

    .del-button {
      width: 12px;
      height: 12px;
      cursor: pointer;
      margin-top: 3px;
      margin-left: 7px;
    }
  }

  .total-price-wrap {
    &.exist_coupon {
      border-bottom: 1px solid #f6f6f6;
    }
    @include flex(space-between, center);
    padding: 18px 0 24px;

    .total-price {
      padding: 0;
      @include font(20px, 700) {
        line-height: 20px;
        color: $main-color;
      }
    }
  }

  @include tablet {
    .td-wrapper {
      margin-top: 0;
    }
  }
}

.purchase-content {
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

.purchase-header {
  padding: 21px 24px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #eaeaea;
}

.purchase-section {
  padding: 24px;
  &.kit {
    padding: 0px;
  }
  h4 {
    @include font(14px, 700) {
      line-height: 14px;
      color: #888888;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: right;
  }
}

.sale-select-list,
.kit-select-list {
  li {
    margin-top: 12px;
    @include flex(space-between, center) {
      flex-wrap: wrap;
    }
    h5 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 69%;
    }

    h6 {
      width: 29%;
      text-align: right;
    }

    h5,
    h6 {
      @include font(16px, 400) {
        line-height: 18px;
      }
    }

    p {
      @include font(12px, 400) {
        line-height: 18px;
        color: #666666;
      }
      text-overflow: ellipsis;
      overflow: hidden;
      flex-shrink: 0;
      width: 100%;
      margin-top: 4px;
    }
  }
}

p {
  &.description {
    @include font(14px, 400) {
      line-height: 18px;
      color: #666666;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;
    width: 100%;
    margin-top: 4px;
  }
}

.select-wrap {
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 30px;
}

.kit-delivery-wrap {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;

  h4 {
    @include font(16px, 400) {
      line-height: 18px;
      color: #333333;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;

    &.digital-content-text {
      font-size: 12px;
      color: #888888;
      margin-top: 20px;
    }
  }

  .delivery-info {
    @include flex(flex-end, center);
    margin-top: 10px;
  }

  p {
    @include font(12px, 400) {
      line-height: 18px;
      color: #666666;
    }
    word-break: keep-all;
  }

  a {
    border: 1px solid #aaaaaa;
    padding: 0 5px;
    border-radius: 4px;
    margin-left: 6px;
    @include font(10px, 400) {
      line-height: 16px;
      color: #666666;
    }
    flex-shrink: 0;
  }

  button {
    border: 1px solid #aaaaaa;
    padding: 0 5px;
    border-radius: 4px;
    margin-left: 6px;
    @include font(10px, 400) {
      line-height: 16px;
      color: #666666;
    }
    flex-shrink: 0;
  }

  .address-edit {
    margin-top: 20px;
    text-align: right;
  }

  label {
    @include font(12px, 400) {
      line-height: 18px;
      color: #666666;
    }
  }

  input {
    margin-left: 10px;
    @include font(12px, 400) {
      line-height: 18px;
      color: #666666;
    }
    width: 220px;
    padding: 6px 8px;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
}

.purchase-info-wrap {
  margin-top: 140px;

  .sale-price {
    color: $point-color;
  }
}

.info_text {
  color: $point-color;
}

.coupon-select-btn {
  margin-top: 24px;
  width: 100%;
  padding: 19px 0 15px;
  text-align: center;
  height: 48px;
  border-radius: 4px;
  background-color: #f7f5ff;
  cursor: pointer;
  @include font(16px, 700) {
    color: #7600ff;
  }
}

.payment-way-wrap {
  margin-top: 12px;

  li {
    height: 48px;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    position: relative;
    margin-top: 8px;
    padding-left: 16px;
    cursor: pointer;
    @include font(16px, 400) {
      line-height: 46px;
    }

    &:first-child {
      margin-top: 0;
    }

    &::after {
      content: '';
      display: block;
      @include background(
        '#{$img-root-url}/static/png/purchase/icon-check-disable.png',
        center,
        center,
        cover
      );
      width: 18px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &.checked {
      border-color: #000000;

      &::after {
        background-image: url('#{$img-root-url}/static/png/purchase/icon-check-able.png');
      }
    }

    img {
      width: 16px;
      height: auto;
      margin-right: 4px;
    }

    &:nth-child(1) > img {
      vertical-align: -3px;
    }

    &:nth-child(4) > img {
      vertical-align: -2.3px;
    }
  }
}

.payment-agree-wrap {
  @include flex(flex-start, center);

  li {
    margin-left: 16px;
    cursor: pointer;

    img {
      width: 15px;
      height: 12px;
      margin-right: 4px;
    }

    span {
      @include font(12px, 400) {
        line-height: 12px;
        color: #888888;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &.checked {
      span {
        color: #333333;
      }
    }

    .check-content {
      display: inline-block;
      @include font(12px, 400) {
        line-height: 12px;
        color: #888888;
      }

      span {
        &:nth-child(2) {
          margin-left: 2px;
          text-decoration: underline;
        }
      }
    }
  }
}

.payment-btn {
  margin-top: 24px;
  pointer-events: none;
  background-color: #eaeaea;

  &.active {
    background-color: $main-color;
    pointer-events: inherit;
    cursor: pointer;
  }
}

@include tablet {
  .user-container {
    &.purchase-container {
      padding-top: 0;
    }
  }

  .purchase-container {
    .td-wrapper {
      max-width: inherit;
    }
  }

  .purchase-header {
    padding: 21px 16px;
    border-bottom: none;
  }

  .purchase-content {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border: none;
  }

  .purchase-section {
    padding: 16px;
    &.kit {
      padding: 0px;
    }
  }

  .mo-payment-btn-wrap {
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    left: 0;
    bottom: 0;
    bottom: calc(0px + env(safe-area-inset-bottom));
    bottom: calc(0px + constant(safe-area-inset-bottom));
    padding: 12px 16px;
    border-top: 1px solid #eaeaea;

    .payment-btn {
      margin-top: 0;
    }
  }
}

@include mobile {
  .payment-agree-wrap {
    display: block;

    li {
      margin-left: 0;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
