@charset "utf-8";
@import '../common/mixin';

.reply-wrap {
  textarea:disabled {
    background-color: #ffffff;
  }

  .top-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px 0 16px;

    div {
      display: flex;
      align-items: flex-end;

      img {
        width: 20px;
        margin: 2px 5px;
      }

      h5 {
        font-size: 12px;
        line-height: 18px;
        padding: 0 1px;
        color: #727272;
      }
    }

    p {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #242424;
    }
  }

  @include new-mobile {
    .top-row {
      padding-top: 0;
    }
  }

  .qna + .qna,
  .review + .review {
    border-top: 1px solid #e8e8e8;
    padding-top: 20px !important;
  }

  .writing-area {
    display: flex;
    width: 100%;
    padding: 16px;
  }
  @include new-mobile {
    .writing-area {
      padding: 0;
    }
  }

  .content {
    display: flex;
    width: 100%;

    &.qna {
      padding: 40px 16px 25px;
    }

    &.review {
      padding: 25px 16px 25px;
    }

    @include new-mobile {
      &.qna,
      &.review {
        padding: 20px 0 25px;
      }
    }

    &.reply {
      padding: 16px 0;
    }

    &.reply:last-child {
      padding-bottom: 0;
    }

    .profile {
      width: 32px;
      height: 32px;
      margin-right: 8px;

      &.master {
        position: relative;
        .profile-img {
          border: 1px solid #643aff;
        }

        .master-badge {
          position: absolute;
          width: 30px;
          height: 10px;
          bottom: 0;
          right: -2px;
        }
      }

      &.author {
        position: relative;

        img:first-child {
          border: 1px solid #6043fa;
          border-radius: 50%;
        }

        .author-badge {
          position: absolute;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .profile-img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    .reply-icon {
      height: 24px;
      width: 24px;
      margin-right: 6px;
      margin-left: -4px;
    }

    .main-column-wrap {
      width: 100%;

      > * {
        display: flex;
      }

      p {
        font-size: 14px;
        word-break: break-all;
        line-height: 22px;
        white-space: pre-wrap;

        &.deleted {
          color: #b6b6b6;
        }
      }

      .profile-info-row {
        height: 32px;
        width: 100%;
        margin-bottom: 5px;
        justify-content: space-between;

        &.write-reply {
          margin-bottom: 8px;
          justify-content: normal;

          @media (min-width: 768px) {
            .profile {
              display: none;
            }
          }
        }

        h4 {
          padding: 8px 8px 8px 0;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #242424;
          max-width: 128px;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        > * {
          display: flex;
          align-items: center;
        }
      }

      .review-middle-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;

        ul {
          display: flex;
        }
      }

      .bottom-row {
        > * {
          display: flex;
        }

        span,
        button {
          padding: 0 10px;
          margin-top: 12px;
          font-size: 12px;
          line-height: 14px;
          border-left: 1px solid #c2c2c2;
          cursor: pointer;

          div {
            font-size: 12px;
            color: #2725d2;
          }

          img {
            width: 23px;
            height: 18px;
            margin: -2px -5px 0 0;
            padding: 0;
            object-fit: cover;
          }
        }

        span:first-child,
        button:first-child {
          padding-left: 0;
          border-left: none;
        }

        span:last-child {
          color: #999999;
          cursor: text;
        }

        p {
          font-size: 14px;
          font-weight: normal;
          color: #c2c2c2;
        }
      }
    }
  }

  .textarea-wrap {
    height: auto;
    display: flex;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 6px;

    &.edit-mode {
      margin-top: 10px;

      textarea {
        height: 34px;
        margin: 8px;
        padding: 5px;
      }

      @include new-mobile {
        textarea {
          margin: 0;
          padding: 6px;
        }
      }
    }

    textarea {
      width: 100%;
      height: 19px;
      line-height: 22px;
      font-size: 14px;
      overflow: hidden;

      &.new_reply_textarea {
        height: 21px;
        margin: 6px;
      }
    }
    @include new-mobile {
      textarea {
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        padding: 8px;

        &.new_reply_textarea {
          height: 40px;
          margin: 0;
        }
      }
    }
    .submit-save-btn {
      border-left: 1px solid #e8e8e8;
      width: 78px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .submit-save-btn:disabled {
      opacity: 0.3;
    }
  }

  @include new-mobile {
    .pc-profile {
      display: none;
    }

    .textarea-wrap {
      border: none;

      .submit-save-btn {
        border: none;
        margin-left: 14px;
        width: 50px;
        display: block;
        color: #727272;
      }
    }
  }

  .more-button-wrap {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    button {
      width: 120px;
      height: 38px;
      background: #eeeeee;
      border-radius: 4px;
    }

    @include new-mobile {
      button {
        height: 48px;
      }
    }

    .loading-spinner-wrap {
      padding: 10px;

      .anim-wrap {
        width: 16px;

        .loading-icon {
          width: 20px;
          height: 20px;
          border: 2px solid #eaeaea;
          border-bottom-color: #6236ff;
        }
      }
    }
  }

  .more-reply-button {
    display: flex;

    button {
      font-size: 12px;
      line-height: 14px;
      color: #2725d2;
      padding-top: 6px;
    }

    .loading-spinner-wrap {
      padding: 7px 2px 0px;

      .anim-wrap {
        width: 16px;

        .loading-icon {
          width: 10px;
          height: 10px;
          border: 2px solid #eaeaea;
          border-bottom-color: #6236ff;
        }
      }
    }
  }

  .empty-list {
    display: flex;
    justify-content: center;
    padding: 50px 0 0;

    p {
      font-size: 14px;
      line-height: 22px;
      color: #727272;
    }
  }

  @include new-mobile {
    .empty-list {
      padding: 30px 0 0;
    }
  }

  .loading-spinner-wrap {
    padding: 40px 0 80px;
  }

  .star-rating-wrap {
    img {
      width: 20px;
      height: 20px;
      padding-right: 4px;

      &.editable {
        cursor: pointer;
      }
    }
  }

  .modify-wrap {
    .close-btn {
      padding: 0;
      margin: -5px;
    }

    button {
      font-size: 12px;
      padding: 0 8px;
      margin: 9px 0;
    }

    button:nth-child(2) {
      border-left: 1px solid #c2c2c2;
      padding-right: 0;
    }
  }

  .reply-date-info {
    font-size: 12px;
    line-height: 14px;
    color: #999999;
  }
}

.close-btn-img {
  width: 24px;
  height: 24px;
  margin: 0;
}

.question-time-wrap {
  &.pc {
    padding-bottom: 8px;
  }

  &.clickable {
    width: fit-content;
    cursor: pointer;
  }

  img {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    cursor: pointer;
  }

  .question-time-info {
    display: flex;
    align-items: center;
    border-radius: 19px;
    border: 1px solid #999999;
    padding: 2px 10px;
    font-size: 12px;
    line-height: 18px;

    button {
      justify-items: center;
      display: flex;

      .close-btn-img {
        width: 18px;
        height: 18px;
        margin: 0;
      }
    }

    h5 {
      padding: 0 4px;
    }

    &.completed {
      background-color: #f2f2ff;
      border: 1px solid #f2f2ff;
      width: fit-content;
      min-width: 85px;

      > * {
        color: #4745ff;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        padding: 0 4px;
      }
    }

    input {
      font-size: 12px;
      width: 24px;
      padding: 0 4px;
      text-align: center;

      &.no-input {
        color: #999999;
      }

      //input 화살표 없애기 start
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
      //input 화살표 없애기 end
    }
  }
  @include new-mobile {
    .question-time-info {
      padding: 6px 10px;
    }
  }
}
