@import '../common/mixin';

.ebook-container {
  padding-top: 64px;
}

.ebook-content {
  padding-bottom: 120px;
  line-height: 1.5;

  .filter-area {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
    align-items: center;
    width: 1110px;
    max-width: 100%;
    margin: 16px auto 0;
    background-color: #fff;

    .category-nav {
      margin-top: 0;
    }
  }

  .order-box {
    width: 120px;
  }
  .align-end {
    align-items: end;
  }
  .book-area {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 1110px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 70px;
  }

  .review-title {
    display: block;
    margin: 80px auto 0;
    width: 1110px;
    max-width: 100%;
    color: #222;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
  }

  .review-area {
    overflow: hidden;
    position: relative;
    padding-top: 60px;
  }

  .empty-title {
    display: block;
    margin-bottom: 130px;
    color: #5c5c5c;
    font-size: 20px;
    font-style: normal;
    text-align: left;
  }

  .empty-img {
    width: 1110px;
    max-width: 100%;
    margin: 60px auto 100px;
    align-items: center;
    text-align: center;
    font-size: 0;

    img {
      width: 140px;
    }
  }
}

.search-bar {
  position: relative;
  max-width: 540px;
  width: 100%;
  margin-right: 14px;

  button {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: top;
    cursor: pointer;
    @include background(
      '#{$img-root-url}/static/v2/svg/common/ic_search_b.svg',
      center,
      center,
      cover
    );
  }

  input {
    display: block;
    width: 100%;
    height: 37px;
    @include font(14px, 400) {
      line-height: 14px;
    }
    font-size: 16px;
    border: 1px solid #999999;
    border-radius: 9999px;
    padding: 32px 50px 32px 25px;
  }
}

@media screen and (max-width: 767px) {
  .ebook-content {
    padding-top: 0px;

    .search-bar {
      max-width: 90%;
      input {
        padding: 24px 48px 24px 24px;
      }
    }

    .book-area {
      margin-top: 40px;
    }

    .filter-area {
      flex-direction: column;
      width: 100%;
      padding: 10px 0;
    }

    .book-category-wrap {
      overflow-x: auto;
      width: 100%;

      .m-category-nav {
        padding: 0 15px;
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .order-box {
      width: calc(100% - 30px);
      margin-top: 20px;
    }

    .review-title {
      padding: 0 15px;
    }

    .empty-img {
      padding: 0 20px;
    }
  }
}
