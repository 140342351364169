@charset "utf-8";
@import '../../scss/common/mixin';

.event-container {
  padding: 64px 0 100px 0;
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;

  @include desktop {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include new-mobile {
    padding-top: 0;
  }

  .event-top-content {
    max-width: 1128px;
    width: 100%;
    @include flex(space-between, flex-start);

    @include new-mobile {
      max-width: 525px;
      margin: 0 auto;
    }

    .title {
      margin-top: 20px;
      @include font(28px, 700) {
        line-height: 30px;
        color: #242424;
      }

      @include new-mobile {
        margin-top: 18px;
        @include font(18px, 700) {
          line-height: 27px;
        }
      }
    }

    .event-btn-container {
      display: flex;
      margin-top: 20px;

      @include new-mobile {
        margin-top: 18px;
      }

      .btn-desc {
        margin-right: 6px;

        @include font(14px, 400) {
          line-height: 27px;
          color: #595959;
        }
      }

      .event-handle-btn {
        width: 50px;
        height: 26px;
        border: 1px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 13px;
        @include font(12px, 400) {
          color: #595959;
        }
        cursor: pointer;

        .btn-off {
          margin: 6px auto;
        }

        &.on {
          background-color: #4745ff;
          border: 1px solid #4745ff;
          color: #ffffff;
        }
      }
    }
  }
}

.event-list {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, minmax(154px, 252px));
  column-gap: calc(3.5%);
  row-gap: 50px;

  @include new-mobile {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(154px, 252px));
    column-gap: 20px;
    row-gap: 20px;
  }

  .event-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .event-content {
      height: 100%;

      .event-img {
        position: relative;
        min-height: 154px;
        max-height: 252px;
        height: 83%;
        margin-bottom: 10px;

        @include new-mobile {
          height: 80%;
        }

        .dimmed {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background-color: rgba(0, 0, 0, 0.2);
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;

          &.default {
            width: 100%;
            height: 100%;
          }
        }
      }

      .event-date-wrap {
        position: absolute;
        left: 8px;
        bottom: 8px;
        height: 28px;
        border-radius: 2px;
        background: #3a3a3a;
        text-align: center;
        @include font(14px, 400) {
          color: #ffffff;
          line-height: 28px;
        }

        p {
          margin: 0 12px;
        }

        @include new-mobile {
          height: 22px;
          @include font(11px, 400) {
            color: #ffffff;
            line-height: 22px;
          }
        }
      }

      .event-title {
        @include font(14px, 700) {
          color: #242424;
          line-height: 21px;
        }
        @include textEllipsis(39px, 2);
        word-break: keep-all;
        white-space: break-spaces;

        @include new-mobile {
          margin-top: 8px;
          font-size: 13px;
          line-height: 19px;
          @include textEllipsis(40px, 2);
        }
      }
    }
  }
}

.event-pagination {
  position: relative;
  width: 100%;
  margin-top: 80px;
  @include flex(center, flex-start);

  @include new-mobile {
    margin-top: 40px;
  }

  .pagination-wrap {
    position: relative;
    display: flex;
  }

  .arrow {
    position: absolute;
    cursor: pointer;

    &.left {
      right: 160%;
    }

    &.right {
      left: 160%;
    }

    &.disabled {
      display: none;
    }
  }

  .page {
    position: absolute;
    @include font(14px, 400) {
      line-height: 24px;
      color: #595959;
    }

    @include new-mobile {
      @include font(13px, 400) {
        line-height: 46px;
      }
    }
  }
}
