@charset "utf-8";
@import '../common/mixin';

.overview-container {
  overflow-x: hidden;

  .overview-nav-wrap {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
    text-align: center;
    z-index: 10000002;

    @include new-desktop {
      display: none;
    }

    ul {
      @include flex(center, center);
    }

    li {
      @include font(16px, 300) {
        line-height: 44px;
        color: #888888;
      }
      margin-right: 40px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        font-weight: 700;
        color: #333333;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: 0;
          background-color: #4745ff;
        }
      }
    }
  }

  .category-item {
    text-align: center;
  }

  .overview-slider-wrap {
    width: 100%;
    height: auto;

    @include new-mobile {
      margin-top: 46px;
    }

    h2 {
      white-space: pre-line;

      @include font(24px, 700) {
        line-height: 1.38;
        color: #ffffff;
      }
    }

    h3 {
      @include font(14px, 400) {
        line-height: 38px;
        color: #ffffff;
      }
    }
  }

  .course-content {
    margin-top: 10px;
    margin-bottom: 50px;

    @include new-tablet {
      padding-right: 16px;
      padding-left: 16px;
      margin-top: 5px;
    }

    @include new-mobile {
      margin-top: 0;
      padding-right: 16px;
      padding-left: 16px;
    }

    .empty-content {
      .title {
        @include font(20px, 400) {
          color: #595959;
        }
        text-align: center;

        @include new-mobile {
          display: block;
          margin: 0 auto;
          max-width: 488px;
          font-size: 16px;
        }
      }

      .empty-img {
        margin-top: 200px;
        margin-bottom: 30px;

        @include new-mobile {
          margin-top: 50%;
        }
      }
    }

    .overview-course-wrap {
      margin-top: 25px;

      @include new-mobile {
        margin-top: 0;
      }

      .class-filter {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 18px;

        @include font(14px, 400) {
          line-height: 18px;
          color: #242424;
        }

        .css-g1d714-ValueContainer {
          justify-content: center;
          padding: 0;
        }

        .css-tlfecz-indicatorContainer {
          padding: 6px 0;
        }

        .css-1gtu0rj-indicatorContainer {
          padding: 6px 0;
        }

        .css-18fprs2-control {
          cursor: pointer;
        }

        @include new-mobile {
          display: block;
          margin: 0 auto 20px auto;
          max-width: 500px;
        }

        .css-2b097c-container {
          width: 70px;
          right: 0;
        }

        .css-tj5bde-Svg {
          color: #9a9a9a;
        }
      }
    }
  }
}

@include new-mobile {
  .overview-container {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .overview-nav-wrap {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 100;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mediaQuery(390px) {
      display: flex;
      padding: 0 16px;
    }

    ul {
      @include flex(center, center);

      @include mediaQuery(390px) {
        justify-content: flex-start;
      }
    }

    li {
      @include font(15px, 400) {
        line-height: 44px;
        color: #888888;
      }
      margin-right: 20px;
    }
  }

  .overview-slider-wrap {
    .dimmed-layer {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 120px;
      left: 0;
      background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 1;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      padding-top: 9px;
      z-index: 2;
      width: 36px;
      height: 48px;
      border-radius: 4px;
      line-height: 38px;
      text-align: center;
      align-items: center;
      cursor: pointer;
      bottom: 70px;
    }

    .image {
      position: absolute;
      width: 70%;
      left: 30%;
      height: 196px;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  padding-top: 9px;
  z-index: 2;
  width: 36px;
  height: 48px;
  border-radius: 4px;
  line-height: 38px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  bottom: 70px;
}

.swiper-button-prev {
  left: calc((100% - 1128px) / 2);

  &::before {
    content: url('#{$img-root-url}/static/v2/svg/common/arrow_l.svg');
    display: block;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  @include new-tablet {
    left: 0;
  }
}

.swiper-button-next {
  right: calc((100% - 1128px) / 2);

  &::before {
    content: url('#{$img-root-url}/static/v2/svg/common/arrow_r.svg');
    display: block;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  @include new-tablet {
    right: 0;
  }
}

.block-width-full-height-one {
  display: block;
  width: 100%;
  height: 1px;
}
