@import 'mixin';

.ftr {
  // 기본타입 (light-type)
  width: 100%;
  color: #595959;
  border-top: 1px solid #e8e8e8;
  padding: 21px 16px 23px;

  @include new-mobile {
    padding-bottom: 120px;
  }

  &.read-only {
    .ftr-menu {
      .ignore {
        pointer-events: none;
      }

      .invisible {
        display: none !important;
      }
    }
  }

  .ftr-menu {
    @include flex(flex-start, center) {
    }
    position: relative;
    max-width: 1128px;
    padding-bottom: 21px;
    border-bottom: 1px solid #e8e8e8;

    @include new-mobile {
      @include flex(flex-start, flex-start) {
      }
      flex-direction: column;
      padding-bottom: 21px;
      border-bottom: 1px solid #e8e8e8;
    }

    .ftr-logo {
      display: block;
      text-indent: -9999px;
      overflow: hidden;
      width: 70px;
      height: 16px;
      margin-right: 72px;
      @include background('#{$img-root-url}/static/svg/common/bearu-logo-black.svg', center, center, cover);

      &.read-only {
        pointer-events: none !important;
      }
    }

    .ftr-menu-list {
      display: flex;
      @include font(13px, 400) {
        line-height: 14.4px;
      }

      @include new-mobile {
        display: grid;
        justify-items: flex-start;
        justify-content: center;
        align-content: center;
        vertical-align: center;
        margin-top: 40px;
        grid-template-columns: repeat(3, minmax(auto, 100px));
        column-gap: calc(4.8%);
        @include font(13px, 400) {
          line-height: 15.6px;
          color: #595959;
        }
      }

      .accent {
        font-weight: bold;
        color: #242424;
      }

      > li {
        margin-right: 30px;
        word-break: keep-all;

        &:last-child {
          margin-right: 0;
        }

        @include new-mobile {
          width: 100%;
          height: 40px;
        }
      }
    }

    .ftr-sns {
      display: flex;
      position: absolute;
      right: 0;

      @include new-mobile {
        position: relative;
      }

      > li {
        margin-right: 16px;

        .facebook {
          width: 38px;
          height: 38px;
          @include background(
                          '#{$img-root-url}/static/v2/svg/common/logo_facebook.svg',
                          center,
                          center,
                          cover
          );

          @include new-mobile {
            width: 42px;
            height: 42px;
            @include background(
                            '#{$img-root-url}/static/v2/svg/common/logo_facebook_m.svg',
                            center,
                            center,
                            cover
            );
          }
        }

        .youtube {
          width: 38px;
          height: 38px;
          @include background(
                          '#{$img-root-url}/static/v2/svg/common/logo_youtube.svg',
                          center,
                          center,
                          cover
          );

          @include new-mobile {
            width: 42px;
            height: 42px;
            @include background(
                            '#{$img-root-url}/static/v2/svg/common/logo_youtube_m.svg',
                            center,
                            center,
                            cover
            );
          }
        }

        .instagram {
          width: 38px;
          height: 38px;
          @include background(
                          '#{$img-root-url}/static/v2/svg/common/logo_instagram.svg',
                          center,
                          center,
                          cover
          );

          @include new-mobile {
            width: 42px;
            height: 42px;
            @include background(
                            '#{$img-root-url}/static/v2/svg/common/logo_instagram_m.svg',
                            center,
                            center,
                            cover
            );
          }
        }

        .naver {
          width: 38px;
          height: 38px;
          @include background('#{$img-root-url}/static/v2/svg/common/logo_naver.svg', center, center, cover);

          @include new-mobile {
            width: 42px;
            height: 42px;
            @include background(
                            '#{$img-root-url}/static/v2/svg/common/logo_naver_m.svg',
                            center,
                            center,
                            cover
            );
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .ftr-info {
    @include flex(space-between, flex-start) {
      flex-direction: column;
    }
    position: relative;
    max-width: 1128px;
    padding-top: 20px;
    color: #595959;
    @include font(12px, 400) {
      line-height: 14.4px;
    }

    @include new-mobile {
      @include flex(space-between, flex-start) {
        flex-direction: column;
      }
      max-width: 1128px;
      padding-top: 20px;
      color: #595959;
      @include font(12px, 400) {
        line-height: 14.4px;
      }
    }

    .contact-info-wrapper {
      display: flex;
      @include font(13px, 400) {
        color: #595959;
        line-height: 15.6px;
      }

      @include new-mobile {
        display: flex;
        flex-direction: column;
        @include font(13px, 400) {
          color: #595959;
          line-height: 15.6px;
        }
      }
    }

    .contact-info {
      display: flex;
      margin-left: 10px;
      margin-right: 30px;

      @include new-mobile {
        display: flex;
        flex-direction: column;
        margin: 6px 0 25px 0;
      }

      > li {
        margin-right: 10px;

        @include new-mobile {
          margin-left: 0;
          margin-bottom: 5px;
        }

        &:last-child {
          margin-right: 0;

          @include new-mobile {
            margin-bottom: 0;
          }
        }

        span {
          margin-left: 8px;
          font-size: 11px;
          color: #999999;
        }
      }
    }

    .ftr-terms {
      display: flex;
      @include font(12px, 400) {
        color: #999999;
        line-height: 14.4px;
      }

      > li {
        border-bottom: 1px solid #999999;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .ftr-info-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      @include new-mobile {
        margin-top: 30px;
      }

      .company-info {
        display: flex;
        @include font(12px, 400) {
          color: #999999;
          line-height: 18px;
        }

        @include mediaQuery(1045px) {
          flex-direction: column;
        }
      }

      p {
        width: 85px;
        margin-right: 16px;

        @include mediaQuery(1045px) {
          margin-bottom: 8px;
        }
      }
    }

    .ftr-terms-wrapper {
      display: flex;
      margin-top: 16px;
      @include font(12px, 400) {
        color: #999999;
        line-height: 14.4px;
      }
    }
  }

  //다크모드 (dark-type)
  &.dark-type {
    border-top: none;
    background: #33333c;

    .ftr-menu {
      border-bottom: 1px solid #595959;
    }

    .ftr-logo {
      @include background('#{$img-root-url}/static/svg/common/bearu-logo-white.svg', center, center, cover);
    }

    .ftr-menu-list {
      color: #e8e8e8;
    }

    .ftr-sns {
      .facebook {
        background-image: url('#{$img-root-url}/static/v2/svg/common/logo_facebook_dm.svg') !important;

        @include new-mobile {
          background-image: url('#{$img-root-url}/static/v2/svg/common/logo_facebook_dm_m.svg') !important;
        }
      }

      .youtube {
        background-image: url('#{$img-root-url}/static/v2/svg/common/logo_youtube_dm.svg') !important;

        @include new-mobile {
          background-image: url('#{$img-root-url}/static/v2/svg/common/logo_youtube_dm_m.svg') !important;
        }
      }

      .instagram {
        background-image: url('#{$img-root-url}/static/v2/svg/common/logo_instagram_dm.svg') !important;

        @include new-mobile {
          background-image: url('#{$img-root-url}/static/v2/svg/common/logo_instagram_dm_m.svg');
        }
      }

      .naver {
        background-image: url('#{$img-root-url}/static/v2/svg/common/logo_naver_dm.svg') !important;

        @include new-mobile {
          background-image: url('#{$img-root-url}/static/v2/svg/common/logo_naver_dm_m.svg') !important;
        }
      }
    }

    .ftr-info {
      color: #e8e8e8;

      .contact-info-wrapper {
        color: #e8e8e8;
      }
    }
  }
}
