@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }
  @-moz-keyframes #{$animation_name} {
    @content;
  }
  @-o-keyframes #{$animation_name} {
    @content;
  }
  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation($animation, $duration, $delay, $time_function, $fill_mode) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -webkit-animation-timing-function: $time_function;
  -webkit-animation-fill-mode: $fill_mode;

  -o-animation-name: $animation;
  -o-animation-duration: $duration;
  -o-animation-delay: $delay;
  -o-animation-timing-function: $time_function;
  -o-animation-fill-mode: $fill_mode;

  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-delay: $delay;
  -moz-animation-timing-function: $time_function;
  -moz-animation-fill-mode: $fill_mode;

  animation-name: $animation;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: $time_function;
  animation-fill-mode: $fill_mode;

  @content;
}