@import 'variables';

/* common wrapper */
@mixin wrapper($max-width) {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  @content;
}

/* placeholder */
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }
  :-moz-placeholder {
    @content
  }
  ::-moz-placeholder {
    @content
  }
  :-ms-input-placeholder {
    @content
  }
}

@include placeholder {
  color: $input-placeholder-color;
}

/* bg img */
@mixin background($img_url, $pos_x, $pos_y, $size) {
  background: url($img_url) no-repeat $pos_x $pos_y/$size;
  @content;
}

/* flex */
@mixin flex($justify_content, $align_item) {
  display: flex;
  justify-content: $justify_content;
  align-items: $align_item;
  @content;
}

/* media query */
@mixin mobile {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $breakpoint-desktop) {
    @content;
  }
}

/* media query v2 */
@mixin new-desktop {
  @media (max-width: $breakpoint-new-desktop) {
    @content;
  }
}

@mixin new-tablet {
  @media (max-width: $breakpoint-new-tablet) {
    @content;
  }
}

@mixin new-mobile {
  @media (max-width: $breakpoint-new-mobile){
    @content;
  }
}

@mixin mediaQuery($width) {
  @media (max-width: $width) {
    @content;
  }
}

/* font style */
@mixin font($size, $weight) {
  font: {
    size: $size;
    weight: $weight;
  };
  @content;
}

/* text ... 처리 */
@mixin textEllipsis($height, $line) {
  height: $height;
  display: -webkit-box;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @content;
}

/* font face */
@mixin fontFace($name, $url, $weight: normal, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/#{$url}') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

/* size */
@mixin size($width, $height) {
  width: $width;
  height: $height;
  @content;
}
