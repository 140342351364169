@charset "utf-8";
@import '../../scss/common/mixin';

.notice-container {
  padding: 64px 0 100px 0;
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;

  @include desktop {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include new-mobile {
    padding-top: 0;
  }

  .title {
    margin-top: 20px;
    @include font(28px, 700) {
      line-height: 30px;
      color: #242424;
    }

    @include new-mobile {
      margin-top: 18px;
      @include font(18px, 700) {
        line-height: 27px;
      }
    }
  }

  .notice-list {
    margin-top: 30px;

    @include new-mobile {
      margin-top: 39px;
    }

    a {
      @include flex(space-between, flex-start);
      padding: 16px 0;
      border-bottom: 1px solid #eeeeee;

      @include new-mobile {
        padding: 16px 0;
      }
    }

    .notice-content {
      width: 100%;
      @include flex(space-between, flex-start);

      @include new-mobile {
        @include flex(flex-start, flex-start);
        flex-direction: column;
      }

      .notice-title {
        padding-right: 10px;

        @include font(16px, 400) {
          line-height: 26px;
          color: #242424;
        }
        @include textEllipsis(22px, 1) {
        }
        word-break: break-all;

        @include new-mobile {
          padding-right: 0;
          @include font(14px, 700) {
            line-height: 16.8px;
          }
          @include textEllipsis(17px, 1) {
          }
        }
      }

      .date-wrap {
        @include new-mobile {
          @include flex(flex-end, flex-end) {
          }
          width: 100%;
          margin-top: 8px;
        }
      }

      .notice-date {
        min-width: 75px;
        @include flex(flex-start, flex-start);
        @include font(13px, 400) {
          line-height: 24px;
          color: #999999;
        }

        @include new-mobile {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .notice-img {
      text-align: center;
      max-width: 160px;
      width: 25%;
      height: 96px;
      margin-top: auto;
      margin-bottom: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.default {
          width: 96px;
          height: 96px;
        }
      }
    }
  }

  .notice-pagination {
    position: relative;
    width: 100%;
    margin-top: 80px;
    @include flex(center, flex-start);

    @include new-mobile {
      margin-top: 40px;
    }

    .pagination-wrap {
      position: relative;
      display: flex;
    }

    .arrow {
      position: absolute;
      cursor: pointer;

      &.left {
        right: 160%;
      }

      &.right {
        left: 160%;
      }

      &.disabled {
        display: none;
      }
    }

    .page {
      position: absolute;
      @include font(14px, 400) {
        line-height: 24px;
        color: #595959;
      }

      @include new-mobile {
        @include font(13px, 400) {
          line-height: 46px;
        }
      }
    }
  }
}

.notice-nav-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding-left: 16px;
  background: #ffffff;
  z-index: 100;

  ul {
    @include flex(flex-start, center);
    @include wrapper(1024px);
  }

  li {
    @include font(16px, 400) {
      line-height: 44px;
      color: #888888;
    }
    margin-right: 40px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.selected {
      font-weight: 700;
      color: $main-color;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: $main-color;
      }
    }
  }
}
