@charset "utf-8";
@import '../common/mixin';

.user-container {
  min-height: 100vh;
  padding: {
    top: 64px;
    bottom: 100px;
  }
  position: relative;

  &.init-login {
    .td-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 368px;
    }

    .content {
      border: 1px solid #eaeaea;
      padding: 32px;
      border-radius: 4px;
      -webkit-box-shadow: 0 44px 24px -12px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 44px 24px -12px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 44px 24px -12px rgba(0, 0, 0, 0.15);
      box-shadow: 0 44px 24px -12px rgba(0, 0, 0, 0.15);

      > img {
        display: block;
        margin: 30px auto 0;
        width: 100%;
        height: auto;
      }
    }

    h3 {
      margin-top: 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #888888;

      span {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: #333333;
        margin-top: 8px;
      }
    }
  }

  h3 {
    @include font(24px, 700) {
      line-height: 24px;
    }
    margin-top: 120px;

    span {
      display: block;
      margin-top: 30px;
      @include font(18px, 300) {
        line-height: 24px;
      }
    }
  }

  .td-wrapper {
    @include wrapper(368px);
  }

  .info-find-wrap {
    margin-top: 30px;
    text-align: right;

    a {
      @include font(13px, 300) {
        color: #aaaaaa;
        line-height: 18px;
      }
      display: inline-block;
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }

      &:first-child::after {
        content: '|';
        display: inline-block;
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }

  .login-btn {
    width: 100%;
    margin-top: 16px;

    input {
      display: block;
      cursor: pointer;
    }

    a {
      display: block;

      img {
        vertical-align: -4px;
        margin-right: 10px;
      }
    }

    &.sns {
      margin-top: 20px;
    }

    &.step-1 {
      margin-top: 50px;
      padding-top: 30px;
      border-top: 1px solid #eaeaea;
    }

    button {
      margin-top: 16px;
      background-color: #dddddd;
    }
  }

  .kko-login-btn {
    background-color: #fee500;
    color: rgba(0, 0, 0, 0.85);

    img {
      width: 20px;
      height: 18px;
    }

    &:hover {
      background-color: #ecd831;
    }
  }

  .fb-login-btn {
    background-color: #1155cc;
    margin-top: 16px;

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #144daf;
    }
  }

  .other-login-way-btn {
    &:hover {
      background-color: #c6c6c6;
    }
  }

  .content {
    > p {
      @include font(16px, 300) {
        color: #666666;
        line-height: 20px;
      }
      text-align: center;
      margin-top: 20px;
    }
  }

  .sign-up-induce-wrap {
    margin-top: 20px;
    padding-top: 16px;
    border-top: 1px solid #eaeaea;
    @include font(14px, 300) {
      line-height: 18px;
    }
    @include flex(center, center);

    a {
      text-decoration: underline;
      color: #3f3de0;
      margin-left: 25px;
    }
  }

  .template-input-wrap {
    .input-wrap {
      input {
        width: 100%;
      }
    }
  }
}

@include new-mobile {
  .user-container {
    padding-top: 50px;
    padding-bottom: 110px;
    min-height: inherit;

    &.init-login {
      .td-wrapper {
        position: initial;
        transform: none;
      }
    }

    .content {
      padding: 16px;
      box-shadow: none;
      border-radius: 0;
      border: 0;
    }
  }
}
