@charset "utf-8";
@import '../../scss/common/mixin';

.td-wrapper {
  position: relative;
}

.notice-detail-container {
  padding: {
    top: 80px;
    bottom: 100px;
  }
  max-width: 768px;
  margin: auto;

  .notice-title-wrapper {
    margin-top: 64px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dddddd;

    @include flex(space-between, flex-start);

    .notice-info {
      .category {
        font-size: 12px;
        color: $main-color;

        &.event {
          color: $point-color;
        }
      }

      .notice-title {
        font-size: 25px;
        font-weight: 600;
        margin-top: 12px;
        line-height: 1.25;
        padding-right: 20px;
      }

      .notice-date {
        margin-top: 18px;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
      }
    }

    .notice-btn-wrapper {
      flex-shrink: 0;
      width: 80px;
      margin-top: 24px;

      button {
        padding: 10px 12px;
        color: #888888;
        font-size: 14px;
        border: 1px solid #eaeaea;
      }
    }
  }

  .notice-detail-wrapper {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dddddd;

    .notice-detail {
      img {
        width: 100%;
        display: block;
      }

      p,
      div {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .notice-list-btn {
      margin-top: 48px;
      padding: 10px 12px;
      color: #888888;
      font-size: 14px;
      border: 1px solid #eaeaea;
    }
  }

  .notice-next-wrapper {
    .next-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 24px 0;

      .next {
        color: #888888;
        font-size: 16px;
        font-weight: 700;
        flex-shrink: 0;
        width: 64px;
      }

      .next-title {
        width: calc(100% - 64px - 85px);
        flex-shrink: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 10px;
      }

      .next-date {
        color: #888888;
        font-size: 16px;
        font-weight: 400;
        flex-shrink: 0;
        text-align: right;
        width: 85px;
      }
    }
  }
}

@include tablet {
  .notice-detail-container {
    padding-top: 0;

    .td-wrapper {
      margin-top: 16px;
    }

    .notice-detail-wrapper {
      margin-top: 16px;
      padding-bottom: 16px;
    }

    .notice-next-wrapper {
      .next-wrapper {
        .next-title {
          width: calc(100% - 64px);
          padding-right: 0;
        }

        .next-date {
          display: none;
        }
      }
    }

    .notice-title-wrapper {
      margin-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      border-bottom: none;

      .notice-info {
        .notice-title {
          font-size: 20px;
          line-height: 29px;
          margin-top: 8px;
        }

        .notice-dat {
          margin-top: 16px;
        }
      }
    }
  }
}

@include mobile {
  .notice-detail-container {
    .notice-detail-wrapper {
      .notice-list-btn {
        margin-top: 24px;
      }

      .notice-detail {
        p,
        div {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .notice-next-wrapper {
      .next-wrapper {
        padding-top: 16px;

        .next {
          width: 50px;
          font-size: 14px;
          line-height: 32px;
        }

        .next-title {
          width: calc(100% - 50px);
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
  }
}
