@charset "utf-8";
@import '../common/mixin';

.purchase-result-container {
  text-align: center;

  .purchase-result-section {
    text-align: center;

    img {
      width: 108px;
      height: auto;
      margin-top: 100px;
    }

    p {
      @include font(24px, 700) {
        line-height: 32px;
      }
      margin-top: 12px;
    }
  }

  .purchase-detail-section {
    .purchase-table {
      margin-top: 20px;
      padding: 20px 16px;
      border: 1px solid #eaeaea;
      border-radius: 2px;

      li {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .bank-info {
      margin-top: 20px;
    }

    h6 {
      @include font(12px, 300) {
        line-height: 16px;
        color: #666666;
      }
      text-align: left;
    }

    h4 {
      @include font(16px, 500) {
        line-height: 22px;
      }
      text-align: left;
      margin-top: 4px;

      input {
        display: inline-block;
        font-size: 0;
        @include background(
          '#{$img-root-url}/static/svg/common/clipboard.svg',
          center,
          center,
          contain
        );
        width: 20px;
        height: 20px;
        vertical-align: 6px;
        opacity: 0.4;
        margin-left: 2px;
      }

      b {
        font-weight: 400;
        color: #aaaaaa;
        word-break: keep-all;
      }
    }

    p {
      margin-top: 36px;
      background-color: #f9f9f9;
      border-radius: 4px;
      padding: 16px;
      word-break: keep-all;
      @include font(14px, 400) {
        line-height: 20px;
        color: #767676;
      }

      img {
        vertical-align: -1px;
        opacity: 0.5;
        height: 13px;
        width: auto;
      }
    }
  }

  .purchase-button-section {
    margin-top: 128px;

    a {
      display: block;
    }
  }
}

@include tablet {
  .purchase-result-container {
    .purchase-result-section {
      img {
        margin-top: 0;
        width: 78px;
      }
    }

    .purchase-button-section {
      margin-top: 88px;
    }
  }
}
