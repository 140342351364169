@import '../common/mixin';
@import '../common/mixin_animation';

.loading-spinner-wrap {
  padding: 280px 0;

  .anim-wrap {
    width: 150px;
    margin: 0 auto;
    text-align: center;
  }

  .loading-icon {
    width: 48px;
    height: 48px;
    border: 5px solid #eaeaea;
    border-bottom-color: #6236ff;
    border-radius: 50%;
    display: inline-block;
    @include animation(spinner-anim, 1s, 0s, linear, none) {
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
    @include keyframe(spinner-anim) {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
