@charset "utf-8";
@import '../common/mixin';

/* 단과강의, 패키지, 키트 상세페이지 공통 스타일 */
.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-count {
  color: yellow;
  font-size: 14px;
  margin-left: 8px;
}

.content-wrap {
  margin-top: 40px;
}

.option-select-wrap {
  > .select-box {
    .css-1wa3eu0-placeholder {
      color: #333333 !important;
    }
  }

  .kit-option-select-wrap {
    .css-1wa3eu0-placeholder {
      color: #cccccc !important;
    }
  }
}

.detail-container {
  padding-bottom: 0 !important;

  .sub-banner-wrap {
    margin-top: 12px;
  }

  .td-wrapper {
    @include wrapper(1024px);
    @include flex(space-between, flex-start);
    position: relative;
  }

  h3 {
    @include font(24px, 700) {
      line-height: 24px;
    }
    padding-top: 29px;
  }

  h4 {
    @include font(18px, 700) {
      line-height: 22px;
    }
  }

  .review-text {
    .star-rating {
      display: flex;
      justify-content: flex-start;
    }

    h5 {
      display: inline-block;
    }

    .hidden-text {
      @include textEllipsis(80px, 4);
    }
  }
}

.detail-content {
  width: 59%;
  max-width: 600px;
  padding-bottom: 130px;
}

.detail-top-preview {
  width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
  background-color: #eaeaea;
  border-radius: 10px;

  @include new-tablet {
    border-radius: 10px;
  }

  @include new-mobile {
    border-radius: 0;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;

    @include new-tablet {
      border-radius: 10px;
    }

    @include new-mobile {
      border-radius: 0;
    }
  }
}

.detail-sticky-nav {
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 997;
  padding-top: 12px;
  padding-left: 12px;
  height: 46px;

  &.sticky {
    display: block;
  }

  .td-wrapper {
    justify-content: flex-start;
  }

  li {
    @include font(20px, 200) {
      line-height: 20px;
      color: #888888;
    }
    padding-bottom: 14px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.selected,
    &:hover {
      font-weight: 700;
      color: #333333;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: #333333;
      }
    }
  }
}

.detail-nav {
  @include flex(flex-start, center);
  padding-top: 14px;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  li {
    @include font(20px, 200) {
      line-height: 20px;
      color: #888888;
    }
    padding-bottom: 14px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;

    &.selected,
    &:hover {
      font-weight: 700;
      color: #333333;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 0;
        background-color: #333333;
      }
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.course-info-wrap {
  min-height: 300px;
  margin-top: 38px;

  img {
    width: 100%;
    display: block;
  }

  p {
    margin-bottom: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font(24px, 700) {
      line-height: 1.42;
      color: #666666;
    }
  }
}

.course-info-content-wrap {
  display: flex;
  height: 54px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #a7a3cc;

  li {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include font(15px, 700) {
      color: #595959;
      line-height: 24px;

      @include new-mobile {
        font-size: 13px;
        line-height: 20px;
      }
    }

    @include new-mobile {
      flex-direction: column;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 30px;
      background-color: #a7a3cc;
    }

    > img {
      width: 24px;
      height: 24px;
      margin-right: 10px;

      @include new-mobile {
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}

.course-curriculum-wrap {
  min-height: 300px;
  margin-top: 88px;

  .curriculum-detail {
    margin-top: 30px;
  }

  h4 {
    @include font(24px, 400) {
      line-height: 1.56;
    }
    word-break: keep-all;
  }

  ul {
    margin-top: 20px;
  }

  li {
    margin-top: 12px;
    @include flex(flex-start, flex-start);

    &:first-of-type {
      margin-top: 0;
    }
  }

  .curriculum-item {
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 32px;
    margin-top: 32px;
  }

  .preview-box {
    position: relative;
    padding: 28.1% 0;

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .chapter-content-number {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #f6f6f6;
    @include font(20px, 700) {
      line-height: 40px;
      color: #666666;
      font-family: 'Roboto', sans-serif;
    }
    text-align: center;
    margin-right: 16px;
    flex-shrink: 0;
  }

  h5 {
    @include font(20px, 400) {
      line-height: 1.29;
      color: #666666;
    }
    word-break: keep-all;
    padding-top: 7px;
  }

  .youtube-player {
    width: 100% !important;
    background-color: #cccccc;
    height: 0 !important;
    padding-bottom: 56.25%;
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.course-review-wrap {
  min-height: 300px;
  margin-top: 88px;

  .title-wrap {
    @include flex(space-between, center);
  }

  .review-grade-wrap {
    padding-top: 29px;

    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: -4px;
      margin-right: 4px;
    }

    span {
      @include font(24px, bold) {
        line-height: 24px;
        font-family: 'GmarketSansBold';
      }
    }
  }
}

.course-qna-wrap {
  min-height: 300px;
  margin-top: 88px;
}

.comment-list {
  margin-top: 16px;

  .more-view-btn {
    width: 100%;
    background: #f6f6f6;
    border-radius: 4px;
    @include font(14px, 400) {
      line-height: 39px;
      color: #666666;
    }

    &::after {
      content: url('#{$img-root-url}/static/svg/common/right-arrow.svg');
      display: inline-block;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      margin-left: 6px;
      vertical-align: -1px;
    }
  }
}

.detail-popular-slide-wrap {
  margin-top: 88px;

  .popular-course-swiper-prev-unique,
  .popular-course-swiper-next-unique {
    position: absolute;
    z-index: 2;
    top: -38px;
    width: 11px;
    height: 20px;
    cursor: pointer;
    @include background(
      '#{$img-root-url}/static/png/product_detail/icon-arrow.png',
      center,
      center,
      cover
    );
  }

  .popular-course-swiper-prev-unique {
    right: 64px;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .popular-course-swiper-next-unique {
    right: 13px;
  }

  .swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.fixed-content {
  width: 39%;
  max-width: 392px;
  margin-bottom: 30px;
  overscroll-behavior: contain;
  position: sticky;
  top: 18px;
  z-index: 998;
}

.event-msg-wrap {
  margin: 12px 0;
  background-color: #f6f6f6;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;

  p {
    @include font(24px, bold) {
      font-family: 'GmarketSansBold';
      line-height: 55px;
    }
    letter-spacing: 4px;
    padding-top: 4px;

    img {
      width: 29px;
      height: 29px;
      margin-right: 6px;
      vertical-align: -6px;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    &.event-finish-text {
      font-size: 18px;
      letter-spacing: 1px;
      color: #888888;
    }
  }
}
.info-text {
  padding-top: 15px;
  color: #fff;
}
.popular-keyword-wrap {
  margin: 12px 0;
  border: 1px solid #eaeaea;
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;

  h4 {
    @include font(16px, 700) {
      line-height: 40px;
    }

    background-color: #f6f6f6;
    border-bottom: 1px solid #eaeaea;
    padding-left: 16px;
  }

  ul {
    padding: 16px 16px 8px;
    @include flex(flex-start, flex-start) {
      flex-wrap: wrap;
    }
    li {
      @include font(12px, 700) {
        line-height: 12px;
        color: #888888;
      }
      border-radius: 16px;
      background-color: #f6f6f6;
      cursor: pointer;
      margin: {
        bottom: 8px;
        right: 8px;
      }
      padding: 10px 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.kit-banner {
  width: 100%;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: #3a3a58;
  background: -moz-linear-gradient(left, #3a3a58 0%, #6236ff 100%);
  background: -webkit-linear-gradient(left, #3a3a58 0%, #6236ff 100%);
  background: linear-gradient(to right, #3a3a58 0%, #6236ff 100%);
  @include flex(flex-start, center);
  cursor: pointer;
  position: relative;

  > img {
    position: absolute;
    width: 10px;
    height: 16px;
    right: 13px;
  }

  .icon-wrap {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #ffffff;
    margin-left: 16px;
    margin-right: 4%;
    -webkit-box-shadow: 0 12px 12px rgba(43, 0, 0, 0.25);
    -moz-box-shadow: 0 12px 12px rgba(43, 0, 0, 0.25);
    -o-box-shadow: 0 12px 12px rgba(43, 0, 0, 0.25);
    box-shadow: 0 12px 12px rgba(43, 0, 0, 0.25);

    img {
      display: block;
      width: 32px;
      height: 28px;
      margin: 12px auto 0;
    }
  }

  .banner-title {
    h4 {
      padding-top: 3px;
      @include font(16px, 700) {
        line-height: 22px;
        color: #ffffff;
        font-family: 'GmarketSansBold';
      }

      span {
        font-family: 'GmarketSansLight';
        display: block;
      }
    }
  }
}

.mo-detail-bottom-nav {
  @include flex(flex-start, center);
  width: 100%;
  height: 80px;
  padding: {
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: calc(12px + env(safe-area-inset-bottom));
    bottom: calc(12px + constant(safe-area-inset-bottom));
  }
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  border-top: 1px solid #eaeaea;

  img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }

  button {
    width: calc(100% - 48px - 8px);
    height: 48px;
    background-color: $main-color;
  }
}

@include tablet {
  .course-info-content-wrap {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;
    height: 68px;
  }

  .detail-container {
    .td-wrapper {
      padding: 0;
    }
  }

  .detail-nav {
    margin-top: 48px;
    padding-top: 0 !important;
  }

  .detail-sticky-nav {
    z-index: 1001;
  }

  .course-curriculum-wrap {
    h3 {
      margin-left: 16px;
    }

    .curriculum-detail {
      padding: 0 16px;
    }
  }

  .course-review-wrap,
  .course-qna-wrap,
  .detail-popular-slide-wrap {
    padding: 0 16px;
  }

  .content-wrap {
    @include tablet {
      margin-top: 0;
    }

    @include new-mobile {
      margin-top: 0;
    }
  }

  .detail-content {
    width: 100%;
    max-width: inherit;

    .detail-nav {
      padding: 0 16px;
    }
  }

  .mo-detail-header-wrap {
    position: relative;
    padding: 0 16px;

    h2 {
      color: #333333;
      font-size: 20px;
      line-height: 1.4;
      margin-top: 23px;
      margin-bottom: 0;
      text-align: left;
    }

    > p {
      @include font(16px, 400) {
        line-height: 1.5;
        color: #666666;
      }
      margin-top: 12px;
      word-break: break-all;
    }

    > img {
      position: absolute;
      left: 16px;
      top: -35px;
      width: auto;
      height: 22px;
    }

    .img-group {
      margin-top: 24px;
      @include flex(flex-start, center);

      img {
        width: auto;
        height: 32px;
        margin-right: 4px;
      }
    }

    .course-price-promotion-wrap {
      margin-top: 40px;

      p {
        @include font(11px, 300) {
          line-height: 1;
          color: #aaaaaa;
        }
        margin-top: 8px;
      }
    }

    .promotion-text-wrap {
      .mo-promotion-text {
        @include flex(flex-start, center);
      }

      h4 {
        @include font(12px, 700) {
          line-height: 1;
          color: #888888;
        }
      }

      h5 {
        @include font(12px, 700) {
          line-height: 1;
          color: $point-color;
        }
        margin-left: 10px;
      }

      h6 {
        @include font(12px, 200) {
          line-height: 1;
          color: #888888;
        }
        margin-left: 6px;
      }
    }
  }

  .month-price-box {
    width: 100%;
    height: 40px;
    background-color: #f6f6f6;
    border-radius: 4px;
    margin-top: 8px;
    padding: 0 12px;

    h4 {
      @include font(20px, 700) {
        line-height: 40px;
      }
    }
  }

  .detail-sticky-nav {
    @include new-mobile {
      padding: 0 16px;
      top: 48px;
      height: 34px;
    }
  }

  .detail-popular-slide-wrap {
    .course-list {
      .list-item {
        width: 100% !important;
      }
    }

    .popular-course-swiper-next-unique {
      right: 3px;
    }

    .popular-course-swiper-prev-unique {
      right: 48px;
    }
  }

  .mo-invoice-title-wrap {
    position: relative;

    h3 {
      padding-top: 0;
      text-align: center;
      @include font(18px, 700) {
        color: $main-color;
        line-height: 24px;
      }

      &:before {
        content: '';
        display: inline-block;
        @include background(
          '#{$img-root-url}/static/png/product_detail/icon-wallet%402x.png',
          center,
          center,
          contain
        );
        width: 22px;
        height: 22px;
        margin-right: 6px;
        vertical-align: -4px;
      }
    }

    button {
      position: absolute;
      right: 16px;
      top: 0;
      @include background(
        '#{$img-root-url}/static/png/common/icon-close-gray.png',
        center,
        center,
        contain
      );
      font-size: 0;
      text-indent: -9999px;
      width: 16px;
      height: 16px;
    }
  }

  .fixed-content {
    display: none;

    &.is-purchase-active {
      width: 100%;
      max-width: inherit;
      margin-bottom: 0;
      position: fixed;
      z-index: 1000;
      left: 0;
      bottom: 0;
      height: 100% !important;
      display: block;
    }
  }

  .mo-time-event-box {
    margin-top: 12px;
    width: 100%;
    height: 24px;
    padding: 0 12px;
    border-radius: 4px;
    background-color: #222222;
    @include flex(space-between, center);

    span {
      display: block;
      @include font(12px, 700) {
        line-height: 24px;
      }

      &:first-child {
        color: #ffffff;
        width: 80%;
        padding-right: 8px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 16px;
          margin-right: 4px;
          vertical-align: -2px;
          @include background(
            '#{$img-root-url}/static/png/common/icon-thunder%402x.png',
            center,
            center,
            contain
          );
        }
      }

      &:last-child {
        color: $point-color;
      }
    }
  }

  .mo-purchase-btn-wrap {
    @include flex(flex-start, center);
    padding: 12px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    border-top: 1px solid #eaeaea;
    z-index: 999;

    .icon-wrap {
      width: 48px;
      min-width: 48px;
      min-height: 48px;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      margin-right: 8px;

      img {
        width: 100%;
        height: 46px;
        display: block;
        padding: 9px;
      }
    }

    button {
      width: calc(100% - 48px - 8px);
      height: 48px;
      background-color: $main-color;

      &.coming-soon-btn {
        background-color: #03c4c5;
        cursor: default;
        pointer-events: none;
      }

      img {
        width: 13px;
        height: 18px;
        vertical-align: -3px;
        margin-right: 2px;
        -webkit-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
      }

      span {
        display: inline-block;
      }

      &.pay-btn {
        width: 86%;
        margin-left: 8px;
        color: $black-color;
        border: 1px solid #d8d8d8;
        background-color: #ffffff;
        transition: border-color 0.2s ease-in-out;

        &:hover {
          border-color: #999999;
        }
      }

      @include mediaQuery(360px) {
        font-size: 14px;
      }
    }
  }

  .fixed-content {
    .dimmed-layer {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

@include mobile {
  .detail-container {
    h3 {
      font-size: 20px;
    }
  }

  .detail-sticky-nav {
    height: 28px;
  }

  .course-curriculum-wrap {
    .curriculum-item {
      border-bottom: none;
      padding-bottom: 16px;
      margin-top: 16px;
    }

    .curriculum-detail {
      margin-top: 16px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
      padding-top: 5px;
      line-height: 19px;
    }

    .chapter-content-number {
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .review-comment-card {
    .review-text {
      padding-left: 44px !important;
    }

    .user-profile-img {
      left: 8px !important;
    }

    .comment-card-wrap {
      padding: 16px 8px !important;
    }
  }

  .comment-write-wrap {
    padding: 16px 8px !important;

    .user-profile-img {
      left: 8px !important;
    }
  }

  .write-wrap {
    //padding-left: 44px !important;
  }

  .review-re-comment-card {
    padding-left: 27px !important;

    .comment-card-wrap {
      padding: 0 0 0 8px !important;
    }

    .re-comment-icon {
      left: 10px !important;
    }
  }

  .detail-sticky-nav {
    li {
      font-size: 16px;
      margin-right: 20px;
      padding-bottom: 8px;
    }
  }
  .detail-nav {
    li {
      font-size: 16px;
      margin-right: 20px;
      padding-bottom: 8px;
    }
  }
}

.refund-policy-item-wrap {
  padding: 0 16px;
}
