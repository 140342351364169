.empty-list-wrap {
  margin-top: 150px;

  @media (max-width: 767px) {
    margin-top: 72px;
  }

  img {
    display: block;
    width: 80px;
    margin: 0 auto;

    @media (max-width: 767px) {
      width: 70px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #999999;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
