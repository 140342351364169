@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';

.master-title-text {
  @include font(40px, normal) {
    font-family: 'GmarketSansLight';
    line-height: 1.2;
    color: #444444;
  }
  letter-spacing: -2px;
  word-break: keep-all;
}

.master-sub-title-text {
  @include font(18px, 200) {
    line-height: 1.44;
    color: #888888;
  }
  margin-top: 24px;
}

.master-top-banner {
  position: relative;
  padding: 230px 0 168px;
}

.master-background {
  position: fixed;
  z-index: -1;
  @include background('#{$img-root-url}/static/png/master/top-banner-img.jpg', top, center, cover);
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
}

.master-top-wrapper {
  text-align: center;

  .title {
    @include font(64px, normal) {
      color: #ffffff;
      line-height: 80px;
      font-family: 'GmarketSansLight';
    }
    letter-spacing: -4px;
    word-break: keep-all;
  }

  .sub-title {
    @include font(64px, normal) {
      color: #0091ff;
      line-height: 80px;
      font-family: 'GmarketSansMedium';
    }
    letter-spacing: -4px;
    word-break: keep-all;
  }

  .master-btn {
    @include font(24px, 700) {
      line-height: 62px;
      color: #ffffff;
    }
    width: 248px;
    height: 64px;
    border-radius: 32px;
    margin-top: 81px;

    &::before,
    &::after {
      border-radius: 32px;
    }
  }
}

.master-btn {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  background-color: transparent;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    bottom: -1px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  &.activity-btn {
    &::before {
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.15);
      -webkit-transform: scale(1, 0.1);
      -o-transform: scale(1, 0.1);
      -moz-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
    }

    &:hover::before {
      opacity: 1;
      -webkit-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      transform: scale(1, 1);
    }

    &::after {
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      border: 1.2px solid rgba(234, 234, 234, 0.5);
    }

    &:hover::after {
      -webkit-transform: scale(1, 0.1);
      -o-transform: scale(1, 0.1);
      -moz-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
      opacity: 0;
    }
  }
}

.master-swipe-wrapper {
  background-color: #ffffff;
  padding: 140px 0;

  .mySwiper {
    overflow: hidden;
    max-width: 1024px;
    -webkit-mask-image:
      -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(0, 0, 0, 0)),
        color-stop(0, #000),
        color-stop(90%, #000),
        color-stop(100%, rgba(0, 0, 0, 0))
      ),
      -webkit-gradient(linear, left top, right top, color-stop(0, #000), color-stop(100%, rgba(0, 0, 0, 0)), color-stop(10%, rgba(0, 0, 0, 0)), color-stop(100%, #000));
    -webkit-mask-composite: source-out;
  }

  .swiper-slide {
    width: 400px;

    .master-slide-item {
      position: relative;
      text-align: left;
      height: 400px;

      .master-img {
        position: absolute;
        height: 400px;
        border-radius: 4px;
      }

      .master-text-wrapper {
        position: absolute;
        z-index: 1;
        top: 32px;
        left: 32px;
        text-shadow: 0 0 7px rgba(0, 0, 0, 0.15);

        .master-desc {
          @include font(32px, 700) {
            line-height: 1.25;
            color: #ffffff;
          }
        }

        .master-info {
          @include font(18px, 400) {
            color: #ffffff;
          }
          margin-top: 16px;
        }
      }
    }
  }
}

.master-support-wrapper {
  background-color: #f6f6f6;
  padding: 92px 0;
  margin: -1px 0;

  .support-wrapper {
    position: relative;
    margin: auto;
    max-width: 1024px;
    overflow: hidden;
    @include flex(space-between, flex-start);
  }

  .support-desc-wrapper {
    width: 50%;
    padding-left: 2%;

    .support-1-text {
      margin-top: 20px;
    }

    .support-2-text {
      font-family: 'GmarketSansBold';
    }

    .support-4-text {
      cursor: pointer;
      text-decoration-line: underline;
      margin-top: 40px;
      display: inline-block;
      @include font(18px, 400) {
        line-height: 18px;
        color: $main-color;
      }
    }

    .support-5-text {
      @include font(90px, normal) {
        font-family: 'GmarketSansLight';
        color: #ffffff;
      }
      margin-top: 120px;
    }
  }

  .support-card-wrapper {
    width: 50%;
    @include flex(flex-start, flex-start) {
      flex-wrap: wrap;
    }

    .support-card {
      width: 32%;
      max-width: 160px;
      height: 100%;
      max-height: 160px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 2px;
      padding: 0 0 19px;
      margin: 4px 0 0 4px;

      img {
        margin-top: 31px;
        height: 58px;
      }

      .title {
        @include font(16px, normal) {
          line-height: 18px;
          font-family: 'GmarketSansLight';
        }
        margin-top: 8px;
      }

      .desc {
        @include font(16px, normal) {
          line-height: 18px;
          font-family: 'GmarketSansMedium';
        }
      }
    }
  }
}

.master-increase-wrapper {
  background-color: #ffffff;
  text-align: center;
  padding: 128px;

  .increase-2-text {
    font-family: 'GmarketSansBold';
  }

  .increase-img {
    margin-top: 50px;
    max-width: 850px;
    width: 100%;
  }

  .graph-label {
    margin-top: 40px;

    p {
      @include font(12px, 400) {
        color: #888888;
      }
    }

    p:first-child {
      display: inline-block;
      text-align: right;
      margin-right: 12px;
    }

    p:last-child {
      display: inline-block;
      text-align: left;
      margin-left: 12px;
    }

    .graph-label-1 {
      display: inline-block;
      width: 32px;
      height: 2px;
      background-color: #ff5050;
      vertical-align: 3px;
      position: relative;
    }

    .graph-label-1:before {
      content: '';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border: 4px solid #4a4a4a;
      border-radius: 50%;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: 0;
      right: 0;
      margin: auto;
    }

    .graph-label-2 {
      vertical-align: middle;
      display: inline-block;
      width: 24px;
      height: 15px;
      background-color: $main-color;
    }
  }
}

.master-with-wrapper {
  text-align: center;
  padding: 64px 0 94px;
  color: white;

  .with-1-text {
    color: #ffffff;
  }

  .with-2-text {
    font-family: 'GmarketSansBold';
    color: #ffffff;
  }

  .with-3-text {
    color: #ffffff;
  }

  .with-card-wrapper {
    max-width: 1024px;
    margin-top: 72px;

    .swiper-container {
      overflow: visible;
    }

    .swiper-wrapper {
      .swiper-slide {
        width: 250px !important;
        margin-left: 8px;

        .with-card {
          width: 100%;
          background-color: rgba(255, 255, 255, 0.15);
          -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
          -o-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
          padding: 46px 0;

          .with-card-title {
            @include font(40px, 400) {
              font-family: 'Nanum Myeongjo', serif;
            }
            position: relative;

            &:before {
              content: '';
              display: block;
              width: 50px;
              height: 50px;
              border-radius: 19px;
              position: absolute;
              z-index: -2;
              bottom: -2px;
              left: 24px;
            }

            &:after {
              content: '';
              display: block;
              width: 50px;
              height: 25px;
              border-radius: 19px;
              position: absolute;
              -webkit-filter: blur(10px);
              filter: blur(10px);
              z-index: -1;
              top: 17px;
              left: 24px;
            }

            &.aa:before,
            &.aa:after {
              background-color: #7752be;
            }

            &.bb:before,
            &.bb:after {
              background-color: #fab005;
            }

            &.cc:before,
            &.cc:after {
              background-color: #f03e3d;
            }

            &.dd:before,
            &.dd:after {
              background-color: #4dadf7;
            }
          }

          .with-card-line {
            width: 40px;
            margin: 30px auto;
            border: 1px solid rgba(#ffffff, 0.4);
          }

          .with-card-desc {
            @include font(20px, 200) {
              line-height: 1.6;
              color: #ffffff;
            }
            letter-spacing: -1px;
          }
        }
      }
    }
  }
}

.master-making-wrapper {
  background-color: #ffffff;
  text-align: center;
  padding: 124px 0;

  .making-2-text {
    font-family: 'GmarketSansBold';
  }

  .making-card-wrapper {
    max-width: 1024px;
    margin: 112px auto 0;
    @include flex(space-between, flex-start);
  }

  .making-card {
    position: relative;
    width: 23%;
    max-width: 225px;
    margin-right: 1%;

    &:first-child > .making-line {
      &::after {
        content: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:last-child > .making-line {
      &::before {
        content: none;
      }
    }

    .making-img-wrapper {
      height: 107px;
      position: relative;
      width: 100%;

      .making-img {
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;

        &.aa {
          width: 87px;
          height: 94px;
        }

        &.bb {
          width: 144px;
          height: 87px;
        }

        &.cc {
          width: 94px;
          height: 107px;
        }

        &.dd {
          width: 128px;
          height: 75px;
        }
      }
    }

    .making-line {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
      @include font(21px, 400) {
        line-height: 42px;
        color: #666666;
      }
      margin: 40px auto 0;
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        background-color: #eaeaea;
        height: 1px;
        width: 108px;
        left: 55px;
        top: 20px;
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        background-color: #eaeaea;
        height: 1px;
        width: 108px;
        right: 55px;
        top: 20px;
      }
    }

    .making-title {
      margin-top: 16px;
      @include font(20px, 700) {
        line-height: 20px;
      }
    }

    .making-desc {
      margin-top: 16px;
      @include font(16px, 400) {
        line-height: 1.5;
        color: #444444;
      }
      letter-spacing: -1px;
    }
  }
}

.master-confirm-wrapper {
  background-color: $main-color;
  text-align: center;
  padding: 64px 0 198px;
  margin: -1px 0;

  .confirm-main-title {
    color: #ffffff;

    span {
      font-family: 'GmarketSansBold';
    }
  }

  .confirm {
    max-width: 1024px;
    margin: 122px auto 0;
    text-align: left;

    .title {
      @include font(20px, 700) {
        line-height: 20px;
        color: #ffffff;
      }
      padding: 0 16px 13px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      margin-top: 66px;

      &:first-child {
        margin-top: 0;
      }
    }

    .desc {
      @include font(16px, 200) {
        line-height: 1.75;
        color: #ffffff;
      }
      margin-top: 11px;
      padding: 0 16px;
    }
  }

  .master-btn {
    margin-top: 125px;
    @include font(32px, 700) {
      line-height: 78px;
      color: #ffffff;
    }
    width: 400px;
    height: 80px;
    border-radius: 44px;

    &::before,
    &::after {
      border-radius: 44px;
    }
  }
}

.master-nav {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $main-color;
  width: 100%;
  z-index: 1000;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

  .td-wrapper {
    @include flex(space-between, center);
    height: 48px;
  }

  img {
    width: 23px;
    height: 22px;
  }

  .master-btn {
    width: 96px;
    height: 24px;
    @include font(12px, 400) {
      line-height: 22px;
      color: #ffffff;
    }
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 12px;
    cursor: pointer;

    &::before,
    &::after {
      content: none;
    }
  }

  &.sticky {
    @include animation(slide-down-anim, 0.15s, 0s, ease-in-out, forwards);
    @include keyframe(slide-down-anim) {
      from {
        top: -48px;
      }
      to {
        top: 0;
      }
    }
  }
}

@include mediaQuery(1054px) {
  .master-making-wrapper {
    .making-card-wrapper {
      max-width: 560px;
      flex-wrap: wrap;
      margin-top: 80px;
      padding: {
        left: 16px;
        right: 16px;
      }

      .making-card {
        width: 49%;
        margin-top: 60px;
        margin-right: 0;

        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
      }

      .making-line:before,
      .making-line:after {
        content: none !important;
      }
    }
  }
}

@include mediaQuery(1024px) {
  .master-support-wrapper,
  .master-with-wrapper,
  .master-increase-wrapper,
  .master-confirm-wrapper {
    padding: {
      left: 16px;
      right: 16px;
    }
  }
}

@include mediaQuery(998px) {
  .master-support-wrapper {
    .support-5-text {
      display: none;
    }
  }
}

@include tablet {
  .master-nav {
    display: block;
  }

  .master-top-banner {
    padding: 270px 0 200px;
  }

  .master-top-wrapper {
    .title {
      font-size: 54px;
      line-height: 70px;
    }

    .sub-title {
      font-size: 54px;
      line-height: 70px;
    }

    .master-btn {
      font-size: 20px;
      line-height: 56px;
      width: 230px;
      height: 58px;
      margin-top: 61px;
    }
  }

  .master-swipe-wrapper {
    position: relative;
    padding: 124px 0px 124px 16px;

    .mySwiper {
      -webkit-mask-image:
        -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, rgba(0, 0, 0, 0)),
          color-stop(0, #000),
          color-stop(90%, #000),
          color-stop(100%, rgba(0, 0, 0, 0))
        ),
        -webkit-gradient(linear, left top, right top, color-stop(0, #000), color-stop(100%, rgba(0, 0, 0, 0)), color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #000));
      -webkit-mask-composite: source-out;
    }
  }

  .master-support-wrapper {
    padding: {
      top: 92px;
      bottom: 92px;
    }

    .support-wrapper {
      text-align: center;
      flex-direction: column-reverse;

      > div {
        width: 100%;
      }

      .support-desc-wrapper {
        margin-left: 0;
      }

      .support-4-text {
        margin-top: 40px;
      }

      .support-card-wrapper {
        max-width: 488px;
        margin: 24px auto 0;
        justify-content: center;
      }
    }
  }

  .master-with-wrapper {
    padding: {
      left: 0;
      right: 0;
    }
  }
}

@include mobile {
  .master-top-banner {
    padding: 128px 0 100px;
  }
  .master-top-wrapper {
    .title {
      font-size: 32px;
      line-height: 40px;
    }

    .sub-title {
      font-size: 32px;
      line-height: 40px;
    }

    .master-btn {
      width: 160px;
      height: 40px;
      font-size: 16px;
      line-height: 38px;
      margin-top: 40px;
    }
  }

  .master-with-wrapper {
    .with-card-wrapper {
      .swiper-wrapper {
        .swiper-slide {
          opacity: 0.5;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          &.swiper-slide-active {
            opacity: 1;
          }
        }
      }
    }
  }

  .master-swipe-wrapper {
    padding: 100px 0 100px 16px;

    .swiper-slide {
      width: 266px;

      .master-slide-item {
        height: 266px;

        .master-img {
          height: 266px;
        }
      }
    }

    .master-text-wrapper {
      top: 19px !important;
      left: 19px !important;
    }

    .master-desc {
      font-size: 18px !important;
      line-height: 22px !important;
    }

    .master-info {
      font-size: 10px !important;
      line-height: 1;
      margin-top: 10px !important;
    }
  }

  .master-support-wrapper {
    padding: {
      top: 40px;
      bottom: 40px;
    }

    .support-wrapper {
      .support-4-text {
        margin-top: 35px;
        font-size: 16px;
      }
    }

    .support-card {
      padding-bottom: 11px !important;

      .title,
      .desc {
        font-size: 12px !important;
        line-height: 14px !important;
      }

      img {
        height: 34px !important;
        margin-top: 15px !important;
      }
    }
  }

  .master-increase-wrapper {
    padding: {
      top: 56px;
      bottom: 40px;
    }

    .increase-img {
      margin-top: 24px;
    }

    .graph-label {
      margin-top: 32px !important;
    }
  }

  .master-with-wrapper {
    padding: {
      top: 40px;
      bottom: 43px;
    }

    .with-card-wrapper {
      margin-top: 32px;
    }
  }

  .master-making-wrapper {
    padding: {
      top: 56px;
      bottom: 56px;
    }

    .making-card-wrapper {
      margin-top: 56px !important;
      display: block;

      .making-img-wrapper {
        display: none !important;
      }

      .making-card {
        max-width: 266px;
        margin: 73px auto 0;
        position: relative;

        &:nth-child(2) {
          margin-top: 73px;
        }

        &:last-child::after {
          content: none;
        }

        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 32px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -53px;
          background-color: #eaeaea;
        }

        &:first-child {
          .making-line {
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  .master-confirm-wrapper {
    padding: {
      top: 40px;
      bottom: 48px;
    }

    .confirm {
      margin-top: 80px;

      .title {
        padding: {
          left: 0;
          right: 0;
        }
        margin-top: 48px !important;
      }

      .desc {
        padding: 0;
      }
    }

    .master-btn {
      font-size: 24px;
      line-height: 62px;
      width: 288px;
      height: 64px;
      margin-top: 88px;
    }
  }

  .master-title-text {
    font-size: 24px;
    line-height: 32px;
  }

  .master-sub-title-text {
    font-size: 16px;
    line-height: 1.38;
    margin-top: 17px;
  }

  .making-card-wrapper {
    justify-content: center !important;
  }

  .making-card {
    width: 100% !important;
  }
}
