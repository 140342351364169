@import '../common/mixin';

.favorite-course-card {
  position: relative;
  min-width: 149px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;

  @media (min-width: 767px) {
    min-width: 180px;
  }

  @include new-mobile {
    gap: 8px;
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #e8e8e8;

    @media (min-width: 767px) {
      margin-bottom: 18px;
    }
  }

  .desc-wrap {
    word-wrap: break-word;

    p {
      @include font(16px, 400) {
        color: $black-color;
        line-height: 130%;
      }

      overflow: hidden;
      display: block;
      word-break: keep-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;

      @include new-mobile {
        margin-top: 0;
        font-size: 14px;
        line-height: 130%;
      }
    }
  }

  .course-main-info-wrap {
    text-align: center;

    .thumbnail-wrap {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 62%;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-4px);
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      .bookmark-wrap {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;

        .bookmark-icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_bookmark_new_0.svg',
            center,
            center,
            cover
          );
        }
        .active {
          @include background(
            '#{$img-root-url}/static/v2/svg/common/ic_bookmark_new_1.svg',
            center,
            center,
            cover
          );
        }
      }
    }
  }

  .course-price-info-wrap {
    padding-left: 2px;

    div {
      height: 25px;
    }

    span {
      display: flex;
      padding-bottom: 4px;

      h4 {
        font-weight: bold;
        font-size: 12px;
        line-height: 130%;
        color: #2725d2;
      }
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #242424;
    }
  }
}
