@import '../common/mixin';

.product-invoice {
  .pay-dark-btn {
    margin-top: 12px;
    background: none;
    border: 2px solid $main-color;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #240e73;
    }
  }
}
