@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';

.count-banner-wrap {
  @include flex(flex-start, center);
  @include size(100%, 80px) {
    max-width: 1128px;
  }
  margin: auto;
  padding: 18px 20px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  color: $white-color;
  background-color: $black-color;
  border-radius: 4px;
  z-index: 99;

  @include animation(show-up-anim, 0.2s, 0s, linear, forwards);
  @include keyframe(show-up-anim) {
    0% {
      opacity: 0;
      bottom: 0;
    }
    100% {
      opacity: 1;
      bottom: 20px;
    }
  }

  @include new-mobile {
    bottom: 62px;
    padding: 8px 0 10px 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include keyframe(show-up-anim) {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.expire {
    background-color: #727272;

    .count-time-wrap {
      color: #999999;
    }

    .day-text {
      color: #d8d8d8 !important;
    }

    .day-number,
    .time-wrap {
      background-color: $black-color !important;
    }
  }

  .count-time-wrap {
    @include flex(center, center);

    .day-number,
    .time-wrap {
      @include font(24px, 700) {
        line-height: 29px;
      }
      text-align: center;
      background-color: #336dff;
      border-radius: 6px;

      @include new-mobile {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .day-number {
      padding: 8px 10px;

      @include new-mobile {
        padding: 4px 8px;
      }
    }

    .day-text {
      margin-left: 4px;
      margin-right: 10px;
      @include font(15px, 700) {
        line-height: 17px;
        color: #d6e5ff;
      }

      @include new-mobile {
        font-size: 13px;
        line-height: 16px;
      }
    }

    .time-wrap {
      @include flex(space-between, center);
      width: 199px;
      padding: 8px 12px;

      @include new-mobile {
        width: 118px;
        padding: 4px 8px;
      }

      > span {
        &.colon {
          height: 24px;
          line-height: 20px;

          @include new-mobile {
            height: 12px;
            line-height: 10px;
          }
        }

        &:not(.colon) {
          width: 32px;

          @include new-mobile {
            width: 18px;
          }
        }

        &:last-child {
          width: 42px;

          @include new-mobile {
            width: 26px;
          }
        }
      }
    }
  }

  .count-content-wrap {
    margin-left: 20px;

    @include new-mobile {
      margin-left: 0;
    }

    .title {
      margin-bottom: 2px;
      @include font(14px, 700) {
        line-height: 17px;
      }

      @include new-mobile {
        font-size: 12px;
        line-height: 14px;
      }
    }

    .sub-title {
      @include font(11px, 400) {
        color: #e8e8e8;
        line-height: 13px;
      }

      @include new-mobile {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}
