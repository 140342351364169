@charset "utf-8";
@import '../../scss/common/mixin';

.faq-container {
  padding: 100px;
  text-align: center;
  max-width: 1400px;
  margin: auto;

  .faq-title {
    margin-top: 48px;
    font-size: 24px;
    font-weight: 600;
  }

  .faq-desc {
    margin-top: 32px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    word-break: keep-all;
  }
}

.faq-tab-wrapper {
  margin-top: 48px;
  border-bottom: 1px solid #e3e3e3;

  .faq-tab {
    cursor: pointer;
    display: inline-block;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;

    &.selected {
      color: $main-color;
      border-bottom: 3px solid $main-color;
    }
  }
}

.faq-content-wrapper {
  text-align: left;
  margin-top: 24px;

  .faq-content {
    margin-top: 8px;

    .faq-content-title {
      cursor: pointer;
      padding: 17px 42px 17px 24px;
      line-height: 24px;
      background-color: #f3f3f3;
      border-radius: 5px;
      font-size: 14px;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 24px;
        top: 25px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        width: 8px;
        height: 12px;
        @include background(
          '#{$img-root-url}/static/svg/common/right-arrow.svg',
          center,
          center,
          contain
        );
      }
    }

    .faq-content-desc {
      display: none;
      white-space: pre-wrap;
      padding: 17px 24px;
      font-weight: 300;
      background-color: #fafafa;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      line-height: 24px;
      font-size: 14px;
    }

    &.selected {
      .faq-content-title {
        color: $main-color;
        font-weight: 500;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:after {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }

      .faq-content-desc {
        display: block;
      }
    }
  }
}

@include tablet {
  .faq-container {
    padding: 0 16px 100px;
  }

  .faq-title {
    display: none;
  }

  .faq-tab-wrapper {
    margin-top: 48px;

    .faq-tab {
      cursor: pointer;
      display: inline-block;
      padding: 12px 10px;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .faq-content-wrapper {
    .faq-content {
      .faq-content-desc {
        padding: 12px 24px;
      }
    }
  }
}
