@charset "utf-8";
@import '../common/mixin';
@import '../common/mixin_animation';

.all-package-wrap {
  margin: 80px 0 0;
}

.all-course-wrap {
  margin: 0px;
  margin-top: 52px;
}

.course-list {
  &.overview-list {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
